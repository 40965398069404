import React from 'react';
import Dropdown from '../../../generic/dropdown';

interface DropdownOption {
  text: string;
  value: string;
}

interface Headline1Props {
  text: string;
  selected: DropdownOption | undefined;
  onChange: (value: string) => void;
  options: DropdownOption[];
}

const Headline1: React.FC<Headline1Props> = ({ text, selected, onChange, options }) => {
  return (
    <header className="bg-teal-700 text-white py-6 shadow-md">
      <div className="container mx-auto px-4 md:px-8">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <h1 className="text-3xl font-bold mb-4 md:mb-0">{text}</h1>
          <div className="w-full md:w-auto">
            <Dropdown
              selected={selected}
              onChange={({ value }) => onChange(value!)}
              options={options}
              className="bg-white text-teal-700 hover:bg-blue-100 transition-colors duration-200 border border-teal-300 rounded-md shadow-sm"
            />
          </div>
        </div>
      </div>
    </header>
  );
};

interface Headline2Props {
  text: string;
}

const Headline2: React.FC<Headline2Props> = ({ text }) => {
  return (
    <h2 className="bg-gray-100 py-4 px-4 text-center text-2xl font-semibold text-gray-800 border-b border-gray-300">
      {text}
    </h2>
  );
};

export { Headline1, Headline2 };