import type { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface Props {
  isOpen: boolean
  onClose: () => void
  courseName: string
  alreadyEnrolled?: boolean
}

export const SuccessModal: FC<Props> = ({ isOpen, onClose, courseName, alreadyEnrolled }) => {
  const { t } = useTranslation()

  if (!isOpen) return null

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Escape') {
      onClose()
    }
  }

  return (
    <div 
      className="fixed inset-0 z-50 overflow-y-auto"
      role="dialog"
      aria-modal="true"
      aria-labelledby="modal-title"
    >
      <div className="flex min-h-screen items-center justify-center px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        <div 
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" 
          onClick={onClose}
          onKeyDown={handleKeyDown}
          role="button"
          tabIndex={0}
          aria-label={t('common.close')}
        />

        <div className="inline-block transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left align-bottom shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6 sm:align-middle">
          <div>
            <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-green-100">
              <svg
                className="h-6 w-6 text-green-600"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                aria-hidden="true"
              >
                <title>Success checkmark icon</title>
                <path strokeLinecap="round" strokeLinejoin="round" d="M4.5 12.75l6 6 9-13.5" />
              </svg>
            </div>
            <div className="mt-3 text-center sm:mt-5">
              <h3 
                className="text-lg font-medium leading-6 text-gray-900"
                id="modal-title"
              >
                {alreadyEnrolled 
                  ? t('courses.enrollment.alreadyEnrolled')
                  : t('courses.enrollment.success')}
              </h3>
              <div className="mt-2">
                <p className="text-sm text-gray-500">
                  {alreadyEnrolled
                    ? t('courses.enrollment.alreadyEnrolledMessage', { courseName })
                    : t('courses.enrollment.successMessage', { courseName })}
                </p>
                <p className="mt-2 text-sm text-gray-500">
                  {t('courses.enrollment.accessCourse')}
                </p>
              </div>
            </div>
          </div>
          <div className="mt-5 sm:mt-6">
            <button
              type="button"
              className="inline-flex w-full justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-primary-dark focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 sm:text-sm"
              onClick={onClose}
              onKeyDown={handleKeyDown}
            >
              {t('common.close')}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
