import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'
import { useAppSelector } from '@data'

const BillingHistory = () => {
  const loginToken = useAppSelector((s) => s.authentication.token)
  const [charges, setCharges] = useState<{
    id: string
    amount: number
    captured: boolean
    receipt_url: string
    failure_message: string
    invoice: string
  }[]>([])

  const loadData = useCallback(async () => {
    const order = await axios.get('/api/v1/billing/stripe/charges', {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${loginToken}`
      }
    })
    setCharges(order.data.charges?.data || [])
  }, [loginToken])

  const retryInvoice = useCallback(
    async (invoiceId: string) => {
      axios
        .post(
          '/api/v1/billing/stripe/invoice',
          {
            invoiceId
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${loginToken}`
            }
          }
        )
        .then(() => {
          loadData()
        })
        .catch(() => {
          loadData()
        })
    },
    [loginToken, loadData]
  )

  useEffect(() => {
    loadData()
  }, [loadData])

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 dark:border-gray-600 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-600">
              <thead className="bg-gray-50 dark:bg-gray-700">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
                  >
                    Id
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
                  >
                    Amount
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-300 uppercase tracking-wider"
                  >
                    Date
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-600 dark:bg-gray-800">
                {charges.map((it, index) => (
                  <tr key={it.id} className={index % 2 === 0 ? 'bg-white dark:bg-gray-800' : 'bg-gray-50 dark:bg-gray-700'}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-100">
                      {it.id}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
                      {it.amount / 100}$
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 dark:text-gray-300">
                      {' '}
                      {it.captured ? (
                        <a href={it.receipt_url} target="_blank" rel="noopener noreferrer" className="text-teal-600 hover:text-teal-500 dark:text-teal-400 dark:hover:text-teal-300">
                          View
                        </a>
                      ) : (
                        <div className="flex items-center">
                          <span className="text-gray-500 dark:text-gray-300">{it?.failure_message}</span>{' '}
                          {index === 0 && (
                            <button
                              className="ml-4 px-4 py-2 text-white font-medium bg-teal-600 hover:bg-teal-700 rounded transition-colors duration-200"
                              type="button"
                              onClick={async () => {
                                await retryInvoice(it.invoice)
                              }}
                            >
                              Retry
                            </button>
                          )}
                        </div>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BillingHistory
