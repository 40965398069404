import React, { useEffect } from 'react'
import CookieConsent from 'react-cookie-consent'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '@data'
import Header from './landings/default/sections/header'
import Footer from './landings/default/sections/footer'
import LoginForm from './login-form'
import { getLanguage } from '../config/i18n'
import { WavyBackground } from './ui/wavy-background'

const isCookieConsent =
  typeof localStorage !== 'undefined' && Boolean(localStorage.getItem('cookie-accepted'))

const LoginPage = function () {
  const navigate = useNavigate()
  const user = useAppSelector((s) => s.authentication.user)
  const token = useAppSelector((s) => s.authentication.token)

  // useEffect(() => {
  //   if (token) {
  //     navigate(`/${getLanguage()}/dashboard`)
  //   }
  // }, [navigate, token])

  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      <WavyBackground className="flex-grow">
        <div className="relative z-10 flex justify-center items-center min-h-[calc(100vh-64px-80px)]  sm:max-w-lg">
          <div className="w-full max-w-md px-4 py-8">
            {!isCookieConsent && (
              <CookieConsent
                onAccept={() => {
                  localStorage.setItem('cookie-accepted', 'true')
                }}
              >
                This website uses cookies to enhance the user experience.
              </CookieConsent>
            )}
            <LoginForm />
          </div>
        </div>
      </WavyBackground>
      <Footer />
    </div>
  )
}

export default LoginPage
