import React from 'react'
import type { resource } from '../../interfaces/mongoose.gen'

const getResourceIcon = (link: string) => {
  if (link.includes('youtube.com') || link.includes('youtu.be')) {
    return (
      <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" role="img" aria-labelledby="youtube-icon">
        <title id="youtube-icon">YouTube Video</title>
        <path d="M19.615 3.184c-3.604-.246-11.631-.245-15.23 0-3.897.266-4.356 2.62-4.385 8.816.029 6.185.484 8.549 4.385 8.816 3.6.245 11.626.246 15.23 0 3.897-.266 4.356-2.62 4.385-8.816-.029-6.185-.484-8.549-4.385-8.816zm-10.615 12.816v-8l8 3.993-8 4.007z" />
      </svg>
    )
  }
  if (link.includes('github.com')) {
    return (
      <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" role="img" aria-labelledby="github-icon">
        <title id="github-icon">GitHub Repository</title>
        <path d="M12 0c-6.626 0-12 5.373-12 12 0 5.302 3.438 9.8 8.207 11.387.599.111.793-.261.793-.577v-2.234c-3.338.726-4.033-1.416-4.033-1.416-.546-1.387-1.333-1.756-1.333-1.756-1.089-.745.083-.729.083-.729 1.205.084 1.839 1.237 1.839 1.237 1.07 1.834 2.807 1.304 3.492.997.107-.775.418-1.305.762-1.604-2.665-.305-5.467-1.334-5.467-5.931 0-1.311.469-2.381 1.236-3.221-.124-.303-.535-1.524.117-3.176 0 0 1.008-.322 3.301 1.23.957-.266 1.983-.399 3.003-.404 1.02.005 2.047.138 3.006.404 2.291-1.552 3.297-1.23 3.297-1.23.653 1.653.242 2.874.118 3.176.77.84 1.235 1.911 1.235 3.221 0 4.609-2.807 5.624-5.479 5.921.43.372.823 1.102.823 2.222v3.293c0 .319.192.694.801.576 4.765-1.589 8.199-6.086 8.199-11.386 0-6.627-5.373-12-12-12z" />
      </svg>
    )
  }
  if (link.includes('.pdf')) {
    return (
      <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" role="img" aria-labelledby="pdf-icon">
        <title id="pdf-icon">PDF Document</title>
        <path d="M11.363 2c4.155 0 2.637 6 2.637 6s6-1.65 6 2.457v11.543h-16v-20h7.363zm.826-2h-10.189v24h20v-14.386c0-2.391-6.648-9.614-9.811-9.614zm4.811 13h-2.628v3.686h.907v-1.472h1.49v-.732h-1.49v-.698h1.721v-.784zm-4.9 0h-1.599v3.686h1.599c.537 0 .961-.181 1.262-.535.555-.658.587-2.034-.062-2.692-.298-.3-.712-.459-1.2-.459zm-.692.783h.496c.473 0 .802.173.915.644.064.267.077.679-.021.948-.128.351-.381.528-.754.528h-.637v-2.12zm-2.74-.783h-1.668v3.686h.907v-1.277h.761c.619 0 1.064-.277 1.224-.763.095-.291.095-.597 0-.885-.16-.484-.606-.761-1.224-.761zm-.761.732h.546c.235 0 .467.028.576.228.067.123.067.366 0 .489-.109.199-.341.227-.576.227h-.546v-.944z" />
      </svg>
    )
  }
  return (
    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" role="img" aria-labelledby="link-icon">
      <title id="link-icon">External Resource</title>
      <path d="M6.188 8.719c.439-.439.926-.801 1.444-1.087 2.887-1.591 6.589-.745 8.445 2.069l-2.246 2.245c-.644-1.469-2.243-2.305-3.834-1.949-.599.134-1.168.433-1.633.898l-4.304 4.306c-1.307 1.307-1.307 3.433 0 4.74 1.307 1.307 3.433 1.307 4.74 0l1.327-1.327c1.207.479 2.501.67 3.779.575l-2.929 2.929c-2.511 2.511-6.582 2.511-9.093 0s-2.511-6.582 0-9.093l4.304-4.306zm6.836-6.836l-2.929 2.929c1.277-.096 2.572.096 3.779.574l1.326-1.326c1.307-1.307 3.433-1.307 4.74 0 1.307 1.307 1.307 3.433 0 4.74l-4.305 4.305c-1.311 1.311-3.44 1.3-4.74 0-.303-.303-.564-.68-.727-1.051l-2.246 2.245c.236.358.481.667.796.982.812.812 1.846 1.417 3.036 1.704 1.542.371 3.194.166 4.613-.617.518-.286 1.005-.648 1.444-1.087l4.304-4.305c2.512-2.511 2.512-6.582.001-9.093-2.511-2.51-6.581-2.51-9.092 0z" />
    </svg>
  )
}

const ResourcesList = ({ resources }: { resources: resource[] }) => (
  <div className="mt-8 border dark:border-gray-700 rounded-lg shadow-lg bg-white dark:bg-[#222] transition-colors overflow-hidden">
    <div className="border-b dark:border-gray-700 px-6 py-4">
      <h3 className="text-xl font-semibold text-gray-900 dark:text-gray-100 flex items-center gap-2">
        <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 24 24" role="img" aria-labelledby="resources-icon">
          <title id="resources-icon">Resources Section</title>
          <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm1 17v-4h-8v-2h8v-4l6 5-6 5z" />
        </svg>
        Resources
      </h3>
    </div>
    
    <div className="p-6">
      <ul className="space-y-3">
        {resources.map(({ text, link, id }) => (
          <li 
            key={id}
            className="group flex items-center gap-3 p-2 rounded-lg hover:bg-gray-50 dark:hover:bg-gray-800 transition-colors"
          >
            <span className="text-gray-500 dark:text-gray-400 group-hover:text-teal-500 dark:group-hover:text-teal-400 transition-colors">
              {link && getResourceIcon(link)}
            </span>
            <a
              href={link}
              className="flex-1 text-gray-700 dark:text-gray-300 group-hover:text-teal-600 dark:group-hover:text-teal-400 font-medium transition-colors"
              target="_blank"
              rel="noopener noreferrer"
              aria-label={`Open ${text} in new tab`}
            >
              {text}
            </a>
            <span className="opacity-0 group-hover:opacity-100 text-gray-400 dark:text-gray-500 transition-opacity">
              <svg className="w-4 h-4" fill="currentColor" viewBox="0 0 24 24" role="img" aria-labelledby="external-icon">
                <title id="external-icon">Open in new tab</title>
                <path d="M21 13v10h-21v-19h12v2h-10v15h17v-8h2zm3-12h-10.988l4.035 4-6.977 7.07 2.828 2.828 6.977-7.07 4.125 4.172v-11z" />
              </svg>
            </span>
          </li>
        ))}
      </ul>
    </div>
  </div>
)

export default ResourcesList
