import React from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Mail, Linkedin, MessageSquare } from 'lucide-react'

import { changeLanguage } from '../../../../config/root'

const Footer = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const languages = ['en', 'ru', 'uk']
  const social = [
    {
      name: 'linkedin',
      url: 'https://linkedin.com/company/skillcrucial-com',
      icon: <Linkedin className="h-6 w-6" />
    }
    // { name: 'medium', url: 'https://medium.com/@skillcrucial', icon: <MessageSquare className="h-6 w-6" /> },
  ]

  return (
    <footer className="bg-gray-900 text-gray-300 py-8">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          <div>
            <h3 className="text-lg font-semibold mb-4">{t('footer.title')}</h3>
            <p className="text-sm">{t('footer.message1')}</p>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">{t('footer.current')}</h3>
            <p className="text-sm">{t('footer.message2')}</p>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">{t('footer.languages.title')}</h3>
            <div className="flex flex-wrap gap-2">
              {languages.map((lang) => (
                <button
                  key={lang}
                  onClick={() => changeLanguage(lang, navigate)}
                  className="bg-gray-800 hover:bg-gray-700 text-sm px-3 py-1 rounded"
                >
                  {t(`footer.languages.${lang}`)}
                </button>
              ))}
            </div>
          </div>
          <div>
            <h3 className="text-lg font-semibold mb-4">{t('footer.connect')}</h3>
            <div className="flex space-x-4">
              <a
                href="mailto:info@skillcrucial.com"
                className="hover:text-white text-teal-700 transition-colors"
              >
                <Mail className="h-6 w-6" />
              </a>
              {social.map((site) => (
                <a
                  key={site.name}
                  href={site.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={site.name}
                  className="hover:text-white text-teal-700 transition-colors cursor-pointer"
                >
                  {site.icon}
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="border-t border-gray-800 mt-8 pt-8 text-center text-sm">
          &copy; 2019 - {new Date().getFullYear()} {t('footer.rights')}
        </div>
      </div>
    </footer>
  )
}

export default Footer
