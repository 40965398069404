import type { ReactNode } from 'react'
import { useEffect } from 'react'
import { tryLogin } from '@data/reducers/authentication'
import { useAppDispatch, useAppSelector } from '@data'
import Cookies from 'universal-cookie'

const cookies = new Cookies()

const Startup = ({ children }: {children: ReactNode}) => {
  const dispatch = useAppDispatch()
  const isInitialRequestDone = useAppSelector((s) => s.authentication.isInitialRequestDone)
  const token = cookies.get('Access')

  useEffect(() => {
    if (!isInitialRequestDone && token) {
      dispatch(tryLogin())
    }
  }, [isInitialRequestDone, dispatch, token])
  
  return <div className="font-sans">{children}</div>
}

export default Startup
