import { useState, useEffect, type ReactElement } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@data';
import { loadLessons, getQuestionsListForLesson, getHomeworkForLesson } from '@data/reducers/courses';
import { updateLesson } from '@data/reducers/admin-courses';
import type { Lesson as LessonType, LessonRating } from '../../interfaces/mongoose.gen';

import Breadcrumb from './breadcrumb';
import VideoPlayer from './video-player';
import Timecodes from './timecodes';
import Rating from './rating';
import AdminControls from './admin-controls';
import NavigationButtons from './navigation-buttons';
import LessonContent from './lesson-content';
import ResourcesList from './resources-list';
import Homework from './homework';
import LessonQuestionSection from './lesson-question-section';
import Head from '../head';
import Loader from '../common/loader';

const Lesson = (): ReactElement => {
  const dispatch = useAppDispatch();
  const { id: courseId = '', weekId = '', lessonId = '' } = useParams();
  const [isLoaded, setLoaded] = useState(false);
  const [adminEditMode, setAdminEditMode] = useState(false);
  const [isDescriptionDirty, setDescriptionDirty] = useState(false);

  const user = useAppSelector((s) => s.authentication.user);
  const week = useAppSelector((s) => 
    s.courses.list?.find((it) => it.courseId === courseId)?.weeks?.find((it) => it.weekId === weekId) 
  );
  const lesson = week?.lessons.find((it) => it.id === lessonId) || {} as LessonType;
  const [description, setDescriptionValue] = useState(lesson?.description || '');

  const courseName = useAppSelector((s) => s.courses.list.find((it) => it.courseId === courseId)?.name || 'unknown');
  const role = user?.role || 'user';
  const questions = useAppSelector((s) => s.courses.questions[lessonId] || []);
  const homework = useAppSelector((s) => s.courses.homeworks[lesson.homeworkId || '']);
  const homeworkStatuses = useAppSelector((s) => s.courses.homeworkStatuses[lesson.homeworkId || '']);

  useEffect(() => {
    if (week && !week.isLoaded && week.lessonIds && week.lessonIds.length > 0) {
      dispatch(loadLessons(week));
    }
  }, [week, dispatch]);

  useEffect(() => {
    if (lessonId) {
      dispatch(getQuestionsListForLesson(lessonId));
      if (lesson.homeworkId) {
        dispatch(getHomeworkForLesson(lesson.homeworkId));
      }
    }
  }, [lessonId, lesson.homeworkId, dispatch]);

  useEffect(() => {
    setDescriptionValue(lesson.description || '');
  }, [lesson.description]);

  useEffect(() => {
    const element = document.getElementById('main-container');
    if (!isLoaded && element) {
      element.scrollTo({ top: 0, behavior: 'smooth' });
      setLoaded(true);
    }
  }, [isLoaded]);

  const handleToggleAdminEditMode = (): void => {
    setAdminEditMode(!adminEditMode);
  };

  const handleSaveDescription = (): void => {
    setDescriptionDirty(false);
    setAdminEditMode(false);
    dispatch(updateLesson({ ...lesson, description }));
  };

  const handleSetDescription = (val: string): void => {
    setDescriptionDirty(true);
    setDescriptionValue(val);
  };

  if (!lesson?.name) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <Loader />
      </div>
    );
  }

  const lessonRating = lesson.rating || {} as LessonRating;

  return (
    <div className="relative min-h-screen bg-white dark:bg-gray-900">
      <Head title={`Skillcrucial - ${lesson.name}`} />
      <img src={`/api/v1/courses/lesson/${lesson._id}/visit.gif`} alt="" className="hidden" />
      
      {/* Main Content */}
      <div className="max-w-4xl mx-auto px-4 md:px-8 py-12 md:py-16">
        <div className="space-y-12">
          <header>
            <Breadcrumb 
              courseId={courseId} 
              weekId={weekId} 
              courseName={courseName} 
              weekName={week?.name || ''} 
              lessonName={lesson.name} 
            />
            
            <h1 className="mt-6 text-2xl font-medium text-gray-900 dark:text-white">
              {lesson.name}
            </h1>

            {lesson.tags?.length > 0 && (
              <div className="mt-2 text-sm text-gray-500 dark:text-gray-400">
                {lesson.tags.map((tag: string) => `#${tag}`).join(' ')}
              </div>
            )}
          </header>

          {lesson.link && lesson.type && (
            <div>
              <VideoPlayer lesson={{ type: lesson.type, link: lesson.link }} />
              {lesson.type === 'vimeo' && (
                <div className="mt-4">
                  <Rating rating={lessonRating} userId={user?.id || ''} lessonId={lesson._id || ''} />
                </div>
              )}
            </div>
          )}

          {['admin', 'moderator'].includes(role) && (
            <AdminControls
              questionsLength={questions.filter(q => !q.toSkip && !q.answer).length}
              adminEditMode={adminEditMode}
              isDescriptionDirty={isDescriptionDirty}
              onToggleEditMode={handleToggleAdminEditMode}
              onSaveDescription={handleSaveDescription}
            />
          )}

          {/* Navigation positioned relative to content */}
          <div className="relative md:mx-[-4rem]">
            <NavigationButtons
              courseId={courseId}
              weekId={weekId}
              lessons={week?.lessons || []}
              currentLessonId={lessonId}
              onNavigate={() => setLoaded(false)}
            />
          </div>

          <LessonContent
            adminEditMode={adminEditMode}
            description={description}
            onSetDescription={handleSetDescription}
          />

          {lesson.resources?.length > 0 && (
            <ResourcesList resources={lesson.resources} />
          )}

          {lesson.homeworkId && (
            <Homework
              homeworkId={lesson.homeworkId}
              homework={homework}
              homeworkStatuses={homeworkStatuses}
            />
          )}

          {lessonId && weekId && courseId && (
            <LessonQuestionSection 
              role={role}
              lessonId={lessonId}
              questions={questions}
              weekId={weekId}
              courseId={courseId}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Lesson;
