import React, { useState } from 'react'
import { toastr } from 'react-redux-toastr'
import { passwordStrength } from 'check-password-strength'

import axios from 'axios'

import '../../assets/css/bootstrap-social.css'

const ChangePassword = () => {
  const [newPassword, setPassword] = useState('')
  const [newRepeatPassword, setRepeatPassword] = useState('')
  const [error, setError] = useState('')

  const changePasswordHandler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
    e.preventDefault()
    if (newPassword !== newRepeatPassword) {
      setError('Passwords are not identical')
      return
    }
    console.log(passwordStrength(newPassword))
    if (!['Strong', 'Medium'].includes(passwordStrength(newPassword).value)) {
      setError('Password is not strong enough')
      return
    }
    setError('')

    axios
      .post('/api/v1/user/change-password', {
        password: newPassword
      })
      .then(() => {
        setPassword('')
        setRepeatPassword('')
        toastr.success('Congratulations','Password was successfully changed')
      })
  }

  return (
    <form className="space-y-8 divide-y divide-gray-200 dark:divide-gray-700">
      <div className="divide-y divide-gray-200 dark:divide-gray-700 pt-2 space-y-6 sm:space-y-5">
        <div className="space-y-6 sm:space-y-5 divide-y divide-gray-200 dark:divide-gray-700 max-w-[400px]">
          <div>
            <label htmlFor="password" className="block text-md font-bold text-gray-700 dark:text-gray-200">
              Password
            </label>
            <div className="mt-1">
              <input
                id="password"
                name="password"
                type="password"
                autoComplete="none"
                required
                value={newPassword}
                onChange={(e) => setPassword(e.target.value)}
                className="appearance-none block w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm placeholder-gray-400 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-teal-500 focus:border-teal-500 sm:text-sm"
              />
            </div>
          </div>
          <div>
            <label htmlFor="repeat-password" className="block text-md font-bold text-gray-700 dark:text-gray-200">
              Repeat Password
            </label>
            <div className="mt-1">
              <input
                id="repeat-password"
                name="repeat-password"
                type="password"
                autoComplete="none"
                required
                value={newRepeatPassword}
                onChange={(e) => setRepeatPassword(e.target.value)}
                className="appearance-none block w-full px-3 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded-md shadow-sm placeholder-gray-400 text-gray-900 dark:text-gray-100 focus:outline-none focus:ring-teal-500 focus:border-teal-500 sm:text-sm"
              />
            </div>
          </div>
          <button
            onClick={changePasswordHandler}
            type="submit"
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500"
          >
            Change Password
          </button>{' '}
          {error.length !== 0 && (
            <div
              className="bg-red-100 dark:bg-red-900/50 border border-red-400 dark:border-red-500 text-red-700 dark:text-red-200 px-4 py-3 rounded relative"
              role="alert"
            >
              <strong className="font-bold">Holy smokes!</strong>{' '}
              <span className="block sm:inline">{error}</span>
              <span className="absolute top-0 bottom-0 right-0 px-4 py-3">
                <svg
                  className="fill-current h-6 w-6 text-red-500 dark:text-red-300"
                  role="button"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                >
                  <title>Close</title>
                  <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                </svg>
              </span>
            </div>
          )}
        </div>
      </div>
    </form>
  )
}

export default ChangePassword
