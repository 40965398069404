type Theme = 'light' | 'dark' | 'system'

const THEME_KEY = 'theme-preference'

export const getSystemTheme = (): 'light' | 'dark' => {
  return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light'
}

export const getStoredTheme = (): Theme => {
  return (localStorage.getItem(THEME_KEY) as Theme) || 'system'
}

export const setStoredTheme = (theme: Theme) => {
  localStorage.setItem(THEME_KEY, theme)
}

export const applyTheme = (theme: Theme) => {
  const effectiveTheme = theme === 'system' ? getSystemTheme() : theme
  
  // Remove both classes first to ensure clean state
  document.documentElement.classList.remove('light', 'dark')
  
  // Apply the appropriate class
  document.documentElement.classList.add(effectiveTheme)
  
  // Update meta theme-color for mobile browsers
  const metaThemeColor = document.querySelector('meta[name="theme-color"]')
  if (metaThemeColor) {
    metaThemeColor.setAttribute(
      'content',
      effectiveTheme === 'dark' ? '#1f2937' : '#ffffff'
    )
  }
}

// Initialize theme system
export const initializeTheme = () => {
  // Apply initial theme
  const storedTheme = getStoredTheme()
  applyTheme(storedTheme)

  // Listen for system theme changes
  const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)')
  const handleChange = () => {
    if (getStoredTheme() === 'system') {
      applyTheme('system')
    }
  }

  // Add listener for system theme changes
  mediaQuery.addEventListener('change', handleChange)
}
