import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { getLanguage, formatDate } from '../../config/i18n';
import { SuccessModal } from './enroll/success-modal';

interface CourseType {
  id: string;
  name: string;
  registrationAllowed?: boolean;
  isDisabled?: boolean;
  hasDetails?: boolean;
  image: string;
  lngNs: string;
  date: Date;
  startDate: string;
  endDate: string;
  price: {
    default: number;
    uk: number;
    ru: number;
    en: number;
  };
  isEnrolled?: boolean;
}

interface Props {
  course: CourseType;
  toggleModal: (courseId: string, registrationAllowed: boolean) => () => void;
  userRegistrationDate?: Date;
  isEnrolled?: boolean;
}

export function CourseCard({ course, toggleModal, userRegistrationDate, isEnrolled }: Props) {
  const { t: courseT } = useTranslation(`courses/${course.lngNs}`);
  const { t } = useTranslation(undefined, { useSuspense: false });
  const [imageLoaded, setImageLoaded] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [isEnrolling, setIsEnrolling] = useState(false);

  const courseData = {
    id: course.id,
    name: courseT('name'),
    startDate: new Date(course.startDate),
    endDate: new Date(course.endDate),
    lang: courseT('language'),
    description: courseT('description', ''),
    duration: courseT('duration', '') || '1 year',
    subject: courseT('emailSubject', ''),
    message: courseT('callToAction.button', 'Enroll Now'),
    close: courseT('close'),
    image: course.image,
    alt: courseT('name'),
    details: courseT('details', 'Learn More'),
    isDisabled: course.isDisabled,
    registrationAllowed: course.registrationAllowed ?? false,
    hasDetails: course.hasDetails,
    price: course.price[getLanguage() as keyof typeof course.price] || course.price.default
  };

  const isPassed = courseData.endDate < new Date();
  const isFree = courseData.price === 0;

  const isAutoEnrollEligible = userRegistrationDate && 
    new Date(userRegistrationDate) < new Date('2024-12-01');

  const buttonDisabled = courseData.isDisabled || isPassed || isEnrolling || isEnrolled;
  const buttonText = isEnrolled
    ? t('courses.status.enrolled')
    : isEnrolling 
      ? t('courses.common.enrolling')
      : isPassed
        ? t('courses.status.passed')
        : courseData.message;

  const buttonStyles = buttonDisabled
    ? 'bg-gray-100 text-gray-500 cursor-not-allowed'
    : isEnrolled
      ? 'bg-yellow-500 text-white cursor-default shadow-yellow-200/50'
      : 'bg-teal-600 hover:bg-teal-600 text-white shadow-lg shadow-teal-600/20 hover:shadow-teal-600/30 active:transform active:scale-[0.98] transition-all duration-300';

  const handleEnrollClick = async () => {
    if (isEnrolled) return;
    
    if (isAutoEnrollEligible && !isPassed) {
      try {
        setIsEnrolling(true);
        const response = await axios.post(`/api/v1/courses/${courseData.id}/enroll`);
        if (response.status === 201) {
          setShowSuccessModal(true);
        } else {
          toggleModal(courseData.id, courseData.registrationAllowed)();
        }
      } catch (error) {
        console.error('Failed to enroll:', error);
        toggleModal(courseData.id, courseData.registrationAllowed)();
      } finally {
        setIsEnrolling(false);
      }
    } else {
      toggleModal(courseData.id, courseData.registrationAllowed)();
    }
  };

  return (
    <div className={`group h-full ${isEnrolled ? 'ring-2 ring-yellow-500/50' : ''} rounded-2xl transition-all duration-300 hover:-translate-y-1 hover:shadow-2xl`}>
      <div className="relative h-full bg-white rounded-2xl shadow-lg overflow-hidden border border-gray-100/50 flex flex-col transition-all duration-300">
        <div className="relative overflow-hidden aspect-[16/9]">
          {courseData.hasDetails && !isEnrolled && (
            <Link
              to={`/${getLanguage()}/courses/${courseData.id}`}
              className="absolute inset-0 z-10 bg-gradient-to-t from-black/50 via-black/30 to-transparent opacity-0 group-hover:opacity-100 transition-all duration-300 flex items-center justify-center backdrop-blur-sm group-hover:backdrop-blur-0"
            >
              <span className="transform translate-y-4 group-hover:translate-y-0 transition-transform duration-300 text-white font-medium flex items-center gap-2 px-6 py-2 rounded-full bg-white/20 backdrop-blur-sm">
                {courseData.details}
                <i className="miu-icon-arrow_forward text-white text-xl" />
              </span>
            </Link>
          )}
          {!imageLoaded && (
            <div className="absolute inset-0 flex items-center justify-center bg-gray-50">
              <div className="animate-pulse w-full h-full bg-gradient-to-r from-gray-100 to-gray-200" />
            </div>
          )}
          <img
            loading="lazy"
            src={courseData.image}
            alt={courseData.alt}
            onLoad={() => setImageLoaded(true)}
            className={`object-cover w-full h-full transition-all duration-500 transform ${!isEnrolled && 'group-hover:scale-105'} ${
              imageLoaded ? 'opacity-100' : 'opacity-0'
            }`}
          />
          <div className="absolute top-4 right-4 bg-white/95 backdrop-blur text-primary font-medium px-4 py-2 rounded-full uppercase tracking-wide shadow-lg text-xs border border-gray-100/20">
            {courseData.lang.split(':')[1]?.trim() || courseData.lang}
          </div>
          <div className="absolute bottom-4 left-4 flex gap-2">
            <div className={`px-4 py-2 rounded-full text-xs font-semibold shadow-lg backdrop-blur transition-colors duration-300 ${
              isEnrolled
                ? 'bg-yellow-500/95 text-white shadow-yellow-500/30'
                : isPassed 
                  ? 'bg-gray-900/95 text-white shadow-black/30' 
                  : courseData.registrationAllowed 
                    ? 'bg-green-500/95 text-white shadow-green-500/30' 
                    : 'bg-yellow-500/95 text-white shadow-yellow-500/30'
            }`}>
              {isEnrolled
                ? t('courses.status.comingSoon')
                : isPassed 
                  ? t('courses.status.passed')
                  : courseData.registrationAllowed 
                    ? t('courses.status.registrationOpen')
                    : t('courses.status.comingSoon')}
            </div>
          </div>
        </div>

        <div className="relative">
          <div className="absolute inset-x-0 -top-1 h-8 bg-gradient-to-b from-black/10 to-transparent" />
        </div>

        <div className="p-6 flex-grow flex flex-col">
          <div className="mb-5">
            <h2 className="text-2xl font-bold text-gray-900 leading-tight mb-3 group-hover:text-primary transition-colors duration-300">
              {courseData.name}
            </h2>
            {courseData.description && (
              <p className="text-gray-600 text-sm leading-relaxed line-clamp-2">
                {courseData.description}
              </p>
            )}
          </div>

          {!isEnrolled && (
            <div className="space-y-4 mb-6">
              <div className="grid grid-cols-2 gap-3">
                <div className="flex items-center text-gray-700 bg-gray-50/80 hover:bg-gray-50 rounded-xl p-3.5 transition-colors duration-200 group/item">
                  <i className="miu-icon-calendar_today mr-3 text-primary/70 group-hover/item:text-primary transition-colors duration-200" />
                  <span className="text-sm font-medium">{formatDate(courseData.startDate)}</span>
                </div>
                
                <div className="flex items-center text-gray-700 bg-gray-50/80 hover:bg-gray-50 rounded-xl p-3.5 transition-colors duration-200 group/item">
                  <i className="miu-icon-timer mr-3 text-primary/70 group-hover/item:text-primary transition-colors duration-200" />
                  <span className="text-sm font-medium">{courseData.duration}</span>
                </div>
              </div>

              <div className="flex items-center justify-between bg-gray-50/80 hover:bg-gray-50 rounded-xl p-4 transition-colors duration-200">
                <div className="flex items-center">
                  <i className="miu-icon-attach_money mr-3 text-primary text-xl" />
                  {isFree ? (
                    <span className="text-lg font-bold text-green-600">{t('courses.common.free')}</span>
                  ) : (
                    <div className="flex items-baseline gap-2">
                      <span className="text-3xl font-bold text-teal-600">
                        ${courseData.price}
                      </span>
                      <span className="text-gray-500 text-xs font-medium">
                        {t('courses.common.perMonth')}
                      </span>
                    </div>
                  )}
                </div>
                {isAutoEnrollEligible && (
                  <div className="flex items-center gap-2 bg-blue-50 px-3 py-1.5 rounded-full">
                    <i className="miu-icon-info text-blue-500 text-sm" />
                    <span className="text-blue-600 text-xs font-medium whitespace-nowrap">
                      {t('courses.common.availableForStudentsBefore', {
                        date: formatDate(new Date('2024-12-01'))
                      })}
                    </span>
                  </div>
                )}
              </div>
            </div>
          )}

          {isEnrolled && (
            <div className="mb-6">
              <div className="flex items-start gap-3 bg-yellow-50 rounded-xl p-4 border border-yellow-200/50">
                <i className="miu-icon-info text-yellow-500 mt-0.5" />
                <p className="text-yellow-700 text-sm font-medium">
                  {t('courses.status.comingSoonMessage')}
                </p>
              </div>
            </div>
          )}

          <div className="mt-auto">
            <button
              type="button"
              className={`w-full px-6 py-3.5 text-sm font-semibold rounded-xl transition-all duration-300 ${buttonStyles}`}
              disabled={buttonDisabled}
              onClick={handleEnrollClick}
              title={
                isEnrolled
                  ? t('courses.status.enrolled')
                  : isPassed 
                    ? t('courses.status.passed')
                    : undefined
              }
            >
              {buttonText}
            </button>
          </div>
        </div>
      </div>

      <SuccessModal
        isOpen={showSuccessModal}
        onClose={() => setShowSuccessModal(false)}
        courseName={courseData.name}
      />
    </div>
  );
}
