import { toastr } from 'react-redux-toastr'
import socketActions from './sockets'

import store from '.'

export default function proccessSocket(message: {type: keyof typeof socketActions, result: any, text: string, task: any}) {
  if (message.type === 'message') {
    store.dispatch(message)
  } else if (socketActions[message.type]) {
    if (
      ['JS_TASK_IS_DONE', 'WEB_TASK_IS_DONE'].includes(message.type) &&
      message.result.status === 'ok'
    ) {
      toastr.success('Congratulations','Task is done!')
    }

    if (message.type === 'CODE_REVIEW_MESSAGE') {
      toastr.info('!',message.text)
    }

    if (message.type === 'CODE_UPDATE') {
      sessionStorage.setItem(
        `${message.task.homeworkId} - ${message.task.taskId}`,
        message.task.code
      )
      toastr.info('!','Code has been updated')
    }

    store.dispatch(socketActions[message.type](message))
  }
}
