import { combineReducers } from 'redux'
import { reducer as toastrReducer } from 'react-redux-toastr'

import authentication from './authentication'
import courses from './courses'
import adminCourses from './admin-courses'
import adminQuestions from './admin-questions'
import adminHomeworks from './admin-homeworks'
import adminSupport from './admin-support'
import adminLessons from './admin-lessons'

import leaderboard from './leaderboard'

const rootReducer = combineReducers({
  authentication,
  courses,
  adminCourses,
  adminQuestions,
  adminHomeworks,
  adminSupport,
  adminLessons,
  leaderboard,
  toastr: toastrReducer
})

export default rootReducer
