import React, { useState, useEffect } from 'react'
import Playground from 'javascript-playgrounds'

interface Props {
  code: string
  defaultCode: string
  homeworkCode: string
  onChange: (code: string) => void
  onSubmit?: (code: string) => void
  onReset?: () => void
  className?: string
  id: string
}
const CodeEditor = (props: Props) => {
  const [code, setCode] = useState(props.code)

  useEffect(() => {
    if (typeof props.code !== 'undefined' && props.code.length > 0) {
      setCode(props.code)
    }
    if (props.code !== code) {
      setCode(props.code)
    }
  }, [props.code])

  useEffect(() => {
    const onMessage = (event: MessageEvent<any>) => {
      if (event.origin !== 'https://unpkg.com') return
      const { code: codeFromMessage, taskId } = JSON.parse(event.data)
      if (props.id !== taskId) return

      if (codeFromMessage !== props.defaultCode) {
        props.onChange(codeFromMessage)
        setCode(codeFromMessage)
      }
    }
    window.addEventListener('message', onMessage, false)
    return () => {
      window.removeEventListener('message', onMessage, false)
    }
  }, [])

  return (
    <div
      className={`editor-wrapper text-xs mt-2 lg:text-lg shadow-xl border border-gray-300 ${props.className}`}
    >
      <Playground
        code={code}
        targetOrigin="*"
        fullscreen
        preset="javascript"
        className="w-full h-full"
        style={{ height: '400px' }}
      />

      {props.code !== props.defaultCode && typeof props.onReset !== 'undefined' && (
        <button
          type="button"
          className={`${
            props.code === props.homeworkCode ? 'text-white' : 'text-orange'
          } reset-icon font-bold py-2 px-2`}
          onClick={() => {
            setCode(props.defaultCode)
            if ( typeof props.onReset !== 'undefined')
            props.onReset()
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
            <path
              className="heroicon-ui fill-current"
              d="M6 18.7V21a1 1 0 0 1-2 0v-5a1 1 0 0 1 1-1h5a1 1 0 1 1 0 2H7.1A7 7 0 0 0 19 12a1 1 0 1 1 2 0 9 9 0 0 1-15 6.7zM18 5.3V3a1 1 0 0 1 2 0v5a1 1 0 0 1-1 1h-5a1 1 0 0 1 0-2h2.9A7 7 0 0 0 5 12a1 1 0 1 1-2 0 9 9 0 0 1 15-6.7z"
            />
          </svg>
        </button>
      )}
      {typeof props.onSubmit !== 'undefined' && (
        <button
          type="button"
          className="bg-teal-700 hover:bg-teal-600 text-white font-bold py-2 px-6 float-right"
          onClick={(e) => {
            e.preventDefault()
            if ( typeof props.onSubmit !== 'undefined')
            props.onSubmit(code)
          }}
        >
          Submit
        </button>
      )}
    </div>
  )
}

CodeEditor.defaultProps = {
  code: '',
  onChange: () => {},
  onSubmit: undefined,
  onReset: undefined,
  className: ''
}

export default React.memo(CodeEditor)
