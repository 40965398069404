import type { Reducer } from 'redux'
import type { HomeWorkQuestions } from '../../interfaces/mongoose.gen'
import type { AppDispatch } from '../index'

interface CourseTreeLesson {
  id: string
  name: string
}

interface CourseTreeWeek {
  id: string
  name: string
  lessons: CourseTreeLesson[]
}

interface CourseTree {
  id: string
  name: string
  weeks: CourseTreeWeek[]
}

export interface QuestionsResponse {
  questions: HomeWorkQuestions[]
  coursesTree: CourseTree[]
}

interface AdminQuestionsState {
  list: HomeWorkQuestions[]
  coursesTree: CourseTree[]
}

const SEND_TEST_QUESTION = 'skillcrucial/SEND_TEST_QUESTION'
const SEND_TEST_QUESTION_FAILED = 'skillcrucial/SEND_TEST_QUESTION_FAILED'
const GET_QUESTIONS = 'skillcrucial/GET_QUESTIONS'
const GET_QUESTIONS_FAILED = 'skillcrucial/GET_QUESTIONS_FAILED'

const UPDATE_ONE_QUESTION = 'skillcrucial/UPDATE_ONE_QUESTION'
const UPDATE_ONE_QUESTION_FAILED = 'skillcrucial/UPDATE_ONE_QUESTION_FAILED'

interface GetQuestionsAction {
  type: typeof GET_QUESTIONS
  data: HomeWorkQuestions[]
  coursesTree: CourseTree[]
}

interface UpdateOneQuestionAction {
  type: typeof UPDATE_ONE_QUESTION
  data: HomeWorkQuestions
  oldId: string
}

interface GetQuestionsFailedAction {
  type: typeof GET_QUESTIONS_FAILED
  error: Error
}

interface UpdateOneQuestionFailedAction {
  type: typeof UPDATE_ONE_QUESTION_FAILED
  error: Error
}

type AdminQuestionsAction =
  | GetQuestionsAction
  | UpdateOneQuestionAction
  | GetQuestionsFailedAction
  | UpdateOneQuestionFailedAction

const initialState: AdminQuestionsState = {
  list: [],
  coursesTree: []
}

const adminQuestionsReducer: Reducer<AdminQuestionsState, AdminQuestionsAction> = (state, action) => {
  if (!state) {
    return initialState
  }

  switch (action.type) {
    case GET_QUESTIONS:
      return {
        ...state,
        list: action.data,
        coursesTree: action.coursesTree,
      }
    case UPDATE_ONE_QUESTION: {
      const keys = Object.keys(state.list)
      if (state.list.findIndex((question) => question.id === action.oldId) >= -1) {
        return {
          ...state,
          list: Object.values(keys.reduce<Record<string, HomeWorkQuestions>>((acc, rec) => {
            const newId = action.data.id || rec
            if (rec === action.oldId) {
              return Object.assign({}, acc, { [newId]: action.data })
            }
            return Object.assign({}, acc, { [rec]: state.list[+rec] })
          }, {})),
        }
      }
      return {
        ...state,
        list: [ ...state.list, action.data],
      }
    }
    default:
      return state
  }
}

export default adminQuestionsReducer

export const getQuestions = (questions: HomeWorkQuestions[], coursesTree: CourseTree[]): GetQuestionsAction => ({
  type: GET_QUESTIONS,
  data: questions,
  coursesTree,
})

export const updateOneQuestion = (question: HomeWorkQuestions, oldId: string): UpdateOneQuestionAction => ({
  type: UPDATE_ONE_QUESTION,
  data: question,
  oldId,
})

export const getQuestionsFailed = (error: Error): GetQuestionsFailedAction => ({
  type: GET_QUESTIONS_FAILED,
  error,
})

export const updateOneQuestionFailed = (error: Error): UpdateOneQuestionFailedAction => ({
  type: UPDATE_ONE_QUESTION_FAILED,
  error,
})

export function all() {
  return (dispatch: AppDispatch) => {
    return fetch('/api/v1/admin/interview/questions', {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrer: 'no-referrer'
    })
      .then((res) => res.json())
      .then((data) => data as QuestionsResponse)
      .then(({ questions, coursesTree }) => {
        const questionMap = questions.reduce<Record<string, HomeWorkQuestions>>((acc, rec) => {
          if (rec.id) {
            return Object.assign({}, acc, { [rec.id]: rec })
          }
          return acc
        }, {})
        dispatch({
          type: GET_QUESTIONS,
          data: questionMap,
          coursesTree
        })
      })
      .catch((error) => dispatch({ type: GET_QUESTIONS_FAILED, error }))
  }
}

export function sendTestRequest(id: string) {
  return (dispatch: AppDispatch) => {
    return fetch('/api/v1/admin/interview/questions/try', {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: JSON.stringify({ id })
    })
      .then((res) => res.json())
      .then((data) => data as QuestionsResponse)
      .then(({ questions, coursesTree }) => {
        const questionMap = questions.reduce<Record<string, HomeWorkQuestions>>((acc, rec) => {
          if (rec.id) {
            return Object.assign({}, acc, { [rec.id]: rec })
          }
          return acc
        }, {})
        dispatch({
          type: SEND_TEST_QUESTION,
          data: questionMap,
          coursesTree
        })
      })
      .catch((error) => dispatch({ type: SEND_TEST_QUESTION_FAILED, error }))
  }
}

export function saveOneQuestion(question: HomeWorkQuestions) {
  return (dispatch: AppDispatch) => {
    if (!question.id) {
      return Promise.reject(new Error('Question ID is required'))
    }

    return fetch('/api/v1/admin/interview/questions', {
      method: 'PUT',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/json'
      },
      redirect: 'follow',
      referrer: 'no-referrer',
      body: JSON.stringify({ question })
    })
      .then((res) => res.json())
      .then((json) => json as HomeWorkQuestions)
      .then((data) => {
        dispatch({
          type: UPDATE_ONE_QUESTION,
          data,
          oldId: question.id
        })
      })
      .catch((error) => dispatch({ type: UPDATE_ONE_QUESTION_FAILED, error }))
  }
}
