import React from 'react'

import Head from '../head'

const Dummy = () => {
  return (
    <div>
      <Head title="Submissions" />
      <div className="flex h-full justify-center items-center">
        <p className="error-text">Not Active Yet</p>
      </div>
    </div>
  )
}

Dummy.propTypes = {}

export default Dummy
