import React, { useMemo, useState } from 'react'
import cs from 'classnames'
import axios from 'axios'
import type { LessonRating } from '../../interfaces/mongoose.gen'

interface Props {
  rating: LessonRating
  lessonId: string
  userId: string
}

export const Rating = ({ rating: ratingInitial, lessonId, userId }: Props) => {
  const [rating, setRating] = useState<LessonRating>(ratingInitial)
  const [hoveredRating, setHoveredRating] = useState(0)

  const rate = (ratingArg: number) => () => {
    axios
      .post(`/api/v1/courses/lesson/${lessonId}/rating`, {
        rating: ratingArg
      })
      .then(({ data }) => {
        setRating(data.rating)
      })
  }

  const myRating = useMemo(() => {
    if ((rating?.[1] ?? []).some((it) => it === userId)) return 1
    if ((rating?.[2] ?? []).some((it) => it === userId)) return 2
    if ((rating?.[3] ?? []).some((it) => it === userId)) return 3
    if ((rating?.[4] ?? []).some((it) => it === userId)) return 4
    if ((rating?.[5] ?? []).some((it) => it === userId)) return 5
    return 0
  }, [rating, userId])

  const average = useMemo(() => {
    const avg1 = (rating?.[1] ?? []).length
    const avg2 = (rating?.[2] ?? []).length
    const avg3 = (rating?.[3] ?? []).length
    const avg4 = (rating?.[4] ?? []).length
    const avg5 = (rating?.[5] ?? []).length

    const total = avg1 + avg2 + avg3 + avg4 + avg5
    const totalAmount = avg1 + 2 * avg2 + 3 * avg3 + 4 * avg4 + 5 * avg5

    return total ? Math.floor(totalAmount / total) : 0
  }, [rating])

  return (
    <div className="flex flex-col items-center gap-2">
      <div className="flex items-center gap-1">
        {[1, 2, 3, 4, 5].map((value) => (
          <button
            key={value}
            type="button"
            onClick={rate(value)}
            onMouseEnter={() => setHoveredRating(value)}
            onMouseLeave={() => setHoveredRating(0)}
            className="p-1 transition-all hover:scale-110 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 rounded-full"
            aria-label={`Rate ${value} stars`}
          >
            <svg
              className={cs('w-6 h-6 fill-current transition-colors', {
                'text-amber-400': hoveredRating ? hoveredRating >= value : average >= value,
                'text-gray-300 dark:text-gray-600': hoveredRating ? hoveredRating < value : average < value,
                'drop-shadow-sm': myRating === value
              })}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              role="img"
              aria-hidden="true"
            >
              <path
                className={cs('transition-colors', {
                  'stroke-teal-500 dark:stroke-teal-400': myRating === value
                })}
                strokeWidth={myRating === value ? '1.5' : '0'}
                d="M10 15l-5.878 3.09 1.123-6.545L.489 6.91l6.572-.955L10 0l2.939 5.955 6.572.955-4.756 4.635 1.123 6.545z"
              />
            </svg>
          </button>
        ))}
      </div>
      {myRating > 0 && (
        <p className="text-sm text-gray-500 dark:text-gray-400">
          Your rating: {myRating} {myRating === 1 ? 'star' : 'stars'}
        </p>
      )}
    </div>
  )
}

export default Rating
