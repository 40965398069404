import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { Lesson } from '../../interfaces/mongoose.gen';
import type { AppDispatch } from '..';
import api from '../../services/api';

interface AdminLessonsState {
  list: Lesson[];
  loading: boolean;
  error: string | null;
}

const initialState: AdminLessonsState = {
  list: [],
  loading: false,
  error: null
};

const adminLessonsSlice = createSlice({
  name: 'adminLessons',
  initialState,
  reducers: {
    setLessons: (state, action: PayloadAction<Lesson[]>) => {
      state.list = action.payload;
      state.loading = false;
      state.error = null;
    },
    setLoading: (state) => {
      state.loading = true;
      state.error = null;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export const { setLessons, setLoading, setError } = adminLessonsSlice.actions;

interface StandardResponse<T> {
  status: string;
  data: T;
}

export const getAll = () => async (dispatch: AppDispatch) => {
  try {
    dispatch(setLoading());
    const response = await api.get<StandardResponse<Lesson[]>>('/admin/lessons/all');
    if (response.data.status === 'ok' && Array.isArray(response.data.data)) {
      dispatch(setLessons(response.data.data));
    }
  } catch (error) {
    dispatch(setError(error instanceof Error ? error.message : 'Failed to fetch lessons'));
    dispatch(setLessons([]));
  }
};

export const get = (id: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setLoading());
    const response = await api.get<StandardResponse<Lesson>>(`/admin/lessons/${id}`);
    if (response.data.status === 'ok' && response.data.data) {
      dispatch(setLessons([response.data.data]));
    }
  } catch (error) {
    dispatch(setError(error instanceof Error ? error.message : 'Failed to fetch lesson'));
    dispatch(setLessons([]));
  }
};

export const getLessonsByCourse = (courseId: string) => async (dispatch: AppDispatch) => {
  try {
    dispatch(setLoading());
    // Log the request
    console.log('Fetching lessons for course:', courseId);
    
    const response = await api.get<StandardResponse<Lesson[]>>(`/admin/courses/${courseId}/lessons`);
    
    // Log the response
    console.log('API Response:', response.data);
    
    if (response.data.status === 'ok' && Array.isArray(response.data.data)) {
      dispatch(setLessons(response.data.data));
    } else {
      console.warn('Unexpected response format:', response.data);
      dispatch(setLessons([]));
    }
  } catch (error) {
    console.error('Error fetching course lessons:', error);
    dispatch(setError(error instanceof Error ? error.message : 'Failed to fetch course lessons'));
    dispatch(setLessons([]));
  }
};

export default adminLessonsSlice.reducer;
