import React from 'react'

const Vimeo = (props: {url: string}) => {
  return (
    <>
      <div style={{ padding: '56.25% 0 0 0', position: 'relative' }}>
        <iframe
          title="Welcome"
          src={props.url}
          style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
          allow="autoplay; fullscreen"
          allowFullScreen
        />
      </div>
      <script src="https://player.vimeo.com/api/player.js" />
    </>
  )
}

Vimeo.defaultProps = {
  url: 'https://player.vimeo.com/video/407694202'
}

export default Vimeo
