"use client";

import React, { useEffect, useRef } from "react";
import { cn } from "../../utils/cn";

export const WavyBackground = ({
  children,
  className,
  containerClassName,
  backgroundFill = "rgba(240, 255, 250, 0.97)",
  streamColor = "rgba(0, 255, 170, 0.2)",
  speed = "slow",
  ...props
}: {
  children?: React.ReactNode;
  className?: string;
  containerClassName?: string;
  backgroundFill?: string;
  streamColor?: string;
  speed?: "slow" | "normal" | "fast";
  [key: string]: any;
}) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    let animationFrameId: number;
    let yOffset = 0;

    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    const getSpeed = () => {
      switch (speed) {
        case "slow":
          return 0.5;
        case "normal":
          return 1;
        case "fast":
          return 2;
        default:
          return 0.5;
      }
    };

    const drawStream = (x: number, y: number) => {
      ctx.beginPath();
      ctx.moveTo(x, y);
      ctx.lineTo(x, y + 50);
      ctx.strokeStyle = streamColor;
      ctx.lineWidth = 1;
      ctx.stroke();
    };

    const animate = () => {
      ctx.fillStyle = backgroundFill;
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      for (let x = 0; x < canvas.width; x += 30) {
        for (let y = -50; y < canvas.height; y += 100) {
          drawStream(x, y + yOffset);
        }
      }

      yOffset += getSpeed();
      if (yOffset >= 100) yOffset = 0;

      animationFrameId = requestAnimationFrame(animate);
    };

    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);
    animate();

    return () => {
      window.removeEventListener('resize', resizeCanvas);
      cancelAnimationFrame(animationFrameId);
    };
  }, [backgroundFill, streamColor, speed]);

  return (
    <div className={cn("h-screen flex flex-col items-center justify-center", containerClassName)}>
      <canvas
        ref={canvasRef}
        className="absolute inset-0 z-0"
      />
      <div className={cn("relative z-10", className)} {...props}>
        {children}
      </div>
    </div>
  );
};