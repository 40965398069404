import React, { useState, useMemo, CSSProperties } from 'react';
import { useTranslation } from 'react-i18next';
import Playground from 'javascript-playgrounds';
import { ArrowUpCircle } from 'lucide-react';

import Head from './head';
import Header from './landings/default/sections/header';
import Footer from './landings/default/sections/footer';
import {Headline1} from './landings/default/sections/headline-with-dropdown';

const PLAYGROUND_OPTIONS = [
  { value: 'javascript', text: 'Javascript' },
  { value: 'react', text: 'React' },
  { value: 'react-native', text: 'React Native' },
  { value: 'html', text: 'HTML' },
  { value: 'python', text: 'Python' }
];

interface CustomCSSProperties extends CSSProperties {
  '--bg-color'?: string;
  '--fg-color'?: string;
  '--editor-bg-color'?: string;
  '--editor-fg-color'?: string;
  '--syntax-keyword-color'?: string;
  '--syntax-string-color'?: string;
  '--syntax-comment-color'?: string;
}


const Landing = () => {
  const { t } = useTranslation();
  const [mode, setMode] = useState('javascript');
  const currentOption = useMemo(() => PLAYGROUND_OPTIONS.find(opt => opt.value === mode), [mode]);

  return (
    <div className="min-h-screen flex flex-col bg-gray-100">
      <Head
        title={t('courses.playground.seo.title')}
        keywords={t('courses.playground.seo.keywords')}
        description={t('courses.playground.seo.description')}
        subject={t('courses.playground.seo.subject')}
      />

      <Header />

      <main className="flex-grow container mx-auto px-4 py-6">
        <Headline1
          text={`${currentOption?.text} ${t('header.playground')}`}
          selected={currentOption}
          onChange={setMode}
          options={PLAYGROUND_OPTIONS}
        />

        <div className="bg-white rounded-lg shadow-lg overflow-hidden mt-4">
          <Playground
            fullscreen
            key={mode}
            preset={mode}
            className="w-full h-[calc(100vh-250px)] min-h-[400px] max-h-[600px]"
            style={{
              '--bg-color': '#f8f9fa',
              '--fg-color': '#212529',
              '--editor-bg-color': '#ffffff',
              '--editor-fg-color': '#333333',
              '--syntax-keyword-color': '#007bff',
              '--syntax-string-color': '#28a745',
              '--syntax-comment-color': '#6c757d',
            } as CustomCSSProperties}
          />
        </div>
      </main>

      <Footer />

      <a
        href="#top"
        className="fixed bottom-8 right-8 bg-blue-600 text-white p-3 rounded-full shadow-lg hover:bg-blue-700 transition-colors duration-300"
        aria-label="Go to top"
      >
        <ArrowUpCircle size={24} />
      </a>
    </div>
  );
};

export default Landing;