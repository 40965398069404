import React, { useEffect, useRef } from 'react'
import Player from '@vimeo/player'

const PATH = 'https://d3xjwnhy5jwte.cloudfront.net'

const VideoPlayer = ({ lesson }: {
    lesson: {
        type: string
        link: string
    }
}) => {
  const vimeoContainerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    let player: Player | null = null;
    if (lesson.type === 'vimeo' && lesson.link && vimeoContainerRef.current) {
      player = new Player(vimeoContainerRef.current, {
        url: lesson.link,
        responsive: true
      })
    }

    return () => {
      if (player) {
        player.destroy().then(() => {
          const container = vimeoContainerRef.current;
          if (container instanceof HTMLElement) {
            while (container.firstChild) {
              container.removeChild(container.firstChild);
            }
          }
        })
      }
    }
  }, [lesson.link])

  if (!lesson.link) return null

  if (lesson.type === 'vimeo') {
    return (
      <div className="aspect-w-16 aspect-h-9 bg-black dark:bg-[#111] transition-colors rounded-lg overflow-hidden">
        <div ref={vimeoContainerRef} className="w-full h-full" />
      </div>
    )
  }

  return (
    <div className="aspect-w-16 aspect-h-9 bg-black dark:bg-[#111] transition-colors rounded-lg overflow-hidden">
      <video controls playsInline className="w-full h-full" preload="auto">
        <source src={`${PATH}/${lesson.link}`} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  )
}

export default VideoPlayer
