import React from 'react'
import SingleJsTask from './single-js-task'
import { HomeworkJsTasks } from '../../interfaces/mongoose.gen'

interface Props {
  tasks: HomeworkJsTasks[]
  results?: any
  testDataId: string
  homeworkId: string
}

const JSTasks = ({ tasks, results={}, testDataId, homeworkId }: Props) => {
  let lastIndex = 0
  let maxOpened = 0
  return <>{(tasks || [])
    .filter((it, index) => {
      if (results[it.id!] && results[it.id!].result.status === 'ok') {
        lastIndex = index + 2
      }
      if (!results[it.id!]) {
        maxOpened += 1
      }
      return (
        (results[it.id!] && results[it.id!].result.status === 'ok') ||
        ((index <= lastIndex || index <= 1) && maxOpened <= 2)
      )
    })
    .map((task, index) => {
      return (
        <SingleJsTask
          task={task}
          testDataId={testDataId}
          key={task.id!}
          index={index}
          homeworkId={homeworkId}
          result={results[task.id!]?.result}
          messages={results[task.id!]?.messages}
          code={results[task.id!]?.code}
        />
      )
    })}</>
}

export default React.memo(JSTasks)
