import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'

import { Switch } from '@headlessui/react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

import { useAppSelector } from '@data'
import CheckoutForm from './checkout-form'

const stripePromise = loadStripe(process.env.STRIPE_PUBLIC_KEY!)
function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

interface MethodType {
  billing_details?: {
    name: string
    address: {
      country: string
      city: string
      line1: string
      line2: string
      postal_code: string
    }
  }
  card: {
    brand: string
    last4: string
  }
}

const PaymentMethod = () => {
  const loginToken = useAppSelector((s) => s.authentication.token)
  const [method, setPaymentMethods] = useState<MethodType | null>(null)
  const [enabled, setEnabled] = useState(true)

  const loadData = useCallback(async () => {
    const { data: pm } = await axios.get('/api/v1/billing/stripe/payment-methods')
    setPaymentMethods(pm as MethodType  || null)
  }, [loginToken, enabled])

  useEffect(() => {
    loadData()
  }, [loadData])

  if (!method?.billing_details) return <div className="text-gray-500 dark:text-gray-300">Loading...</div>

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 dark:border-gray-700 sm:rounded-lg">
            <div className="bg-white dark:bg-gray-800 shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                <div className="flex justify-between">
                  <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">Payment Details</h3>{' '}
                  <Switch
                    checked={enabled}
                    onChange={setEnabled}
                    className={classNames(
                      enabled ? 'bg-teal-600' : 'bg-gray-200 dark:bg-gray-600',
                      'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500'
                    )}
                  >
                    <span className="sr-only">Change payment method</span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        enabled ? 'translate-x-5' : 'translate-x-0',
                        'pointer-events-none inline-block h-5 w-5 rounded-full bg-white dark:bg-gray-100 shadow transform ring-0 transition ease-in-out duration-200'
                      )}
                    />
                  </Switch>
                </div>
                <p className="mt-1 max-w-2xl text-sm text-gray-500 dark:text-gray-400">
                  Your current billing payment details
                </p>
              </div>
              {enabled && (
                <div className="border-t border-gray-200 dark:border-gray-700 px-4 py-5 sm:p-0">
                  <dl className="sm:divide-y sm:divide-gray-200 dark:sm:divide-gray-700">
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Full name</dt>
                      <dd className="mt-1 text-sm text-gray-900 dark:text-gray-200 sm:mt-0 sm:col-span-2">
                        {method.billing_details.name}
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Country / City</dt>
                      <dd className="mt-1 text-sm text-gray-900 dark:text-gray-200 sm:mt-0 sm:col-span-2">
                        {method.billing_details.address.country} /{' '}
                        {method.billing_details.address.city}
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Address</dt>
                      <dd className="mt-1 text-sm text-gray-900 dark:text-gray-200 sm:mt-0 sm:col-span-2">
                        {method.billing_details.address.line1} /{' '}
                        {method.billing_details.address.line2}
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Postal code</dt>
                      <dd className="mt-1 text-sm text-gray-900 dark:text-gray-200 sm:mt-0 sm:col-span-2">
                        {' '}
                        {method.billing_details.address.postal_code}{' '}
                      </dd>
                    </div>
                    <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                      <dt className="text-sm font-medium text-gray-500 dark:text-gray-400">Card</dt>
                      <dd className="mt-1 text-sm text-gray-900 dark:text-gray-200 sm:mt-0 sm:col-span-2">
                        {method.card.brand} **** **** **** {method.card.last4}
                      </dd>
                    </div>
                  </dl>
                </div>
              )}
              {!enabled && (
                <div className="border-t border-gray-200 dark:border-gray-700 px-4 py-5 sm:p-0">
                  {' '}
                  <dl className="sm:divide-y sm:divide-gray-200 dark:sm:divide-gray-700">
                    <div className="py-4 sm:py-5 sm:px-6">
                      {' '}
                      <Elements stripe={stripePromise}>
                        <CheckoutForm courseId="update-payment-method" />
                      </Elements>
                    </div>{' '}
                  </dl>{' '}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentMethod
