import React, { useState, useEffect, useCallback } from 'react'
import { Link, Outlet, useNavigate, useParams, useLocation } from 'react-router-dom'
import cx from 'classnames'
import {
  ChevronRightIcon,
  HomeIcon,
  CheckCircleIcon,
  LockClosedIcon,
  Bars3Icon,
  XMarkIcon
} from '@heroicons/react/20/solid'

import { useAppSelector } from '@data'
import Loader from '../common/loader'
import Head from '../head'
import { getLanguage } from '../../config/i18n'
import type { Course, CourseWeek } from '../../interfaces/mongoose.gen'
import EnrolledCourses from './enrolled-courses'

const LAST_WEEK_KEY_PREFIX = 'lastOpenedWeek_'

const TelegramIcon = ({ className }: {
  className: string
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={className}
    role="img"
    aria-labelledby="telegramIconTitle"
  >
    <title id="telegramIconTitle">Telegram Icon</title>
    <path d="M21.198 2.433a2.242 2.242 0 0 0-1.022.215l-17.15 6.498a2.268 2.268 0 0 0-.214 4.233l3.179 1.35a1.89 1.89 0 0 0 2.204-.574l6.272-7.048c.218-.254.635-.162.762.156.126.318-.035.648-.246.821l-6.271 7.048a1.89 1.89 0 0 0-.259 2.077l1.707 3.526a2.247 2.247 0 0 0 3.184 1.075l17.15-6.499c1.269-.481 1.35-2.059.13-2.654l-9.426-3.997z" />
  </svg>
)

const Breadcrumb = ({ course, weekName, onCourseClick }: {
  course: Partial<Course>
  weekName?: string
  onCourseClick: (e: React.MouseEvent) => void
}) => (
  <nav className="flex mb-4" aria-label="Breadcrumb">
    <ol className="flex items-center space-x-4">
      <li>
        <Link to="/dashboard" className="text-teal-700 hover:text-teal-800 dark:text-teal-400 dark:hover:text-teal-300">
          <HomeIcon className="h-5 w-5" aria-hidden="true" />
          <span className="sr-only">Home</span>
        </Link>
      </li>
      <li className="flex items-center">
        <ChevronRightIcon className="h-5 w-5 text-teal-700 dark:text-teal-400" aria-hidden="true" />
        <button
          type="button"
          onClick={onCourseClick}
          className="ml-4 text-sm font-medium text-teal-700 hover:text-teal-800 dark:text-teal-400 dark:hover:text-teal-300"
        >
          {course.name}
        </button>
      </li>
      {weekName && (
        <li className="flex items-center">
          <ChevronRightIcon className="h-5 w-5 text-teal-700 dark:text-teal-400" aria-hidden="true" />
          <span className="ml-4 text-sm font-medium text-teal-700 dark:text-teal-400">{weekName}</span>
        </li>
      )}
    </ol>
  </nav>
)

const WeekItem = ({ week, index, totalWeeks, isSelected, isLocked, onSelect, isSystem }: {
  week: Partial<CourseWeek> & { hasInterviewDependant?: boolean; isInterviewDone?: boolean };
  index: number;
  totalWeeks: number;
  isSelected: boolean;
  isLocked: boolean;
  onSelect: (weekId: string) => void;
  isSystem: boolean;
}) => {
  const label = isSystem ? '' : `W${totalWeeks - index}`
  const weekId = week.weekId || ''

  const handleSelect = () => {
    if (!isLocked && weekId) {
      onSelect(weekId)
    }
  }

  return (
    <button
      type="button"
      className={cx(
        'group relative w-full text-left flex items-center border-l-4 px-3 py-2 mb-0.5',
        'transition-all duration-200 hover:bg-gray-50 dark:hover:bg-gray-800/50',
        {
          'border-l-teal-500 bg-teal-50 dark:bg-teal-900/30': isSelected && !isSystem,
          'border-l-yellow-500 bg-yellow-50 dark:bg-yellow-900/30': isSystem,
          'border-l-transparent': !isSelected && !isSystem,
          'opacity-50 cursor-not-allowed': isLocked
        }
      )}
      onClick={handleSelect}
      onKeyPress={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          handleSelect()
        }
      }}
      role="button"
      tabIndex={0}
      disabled={isLocked}
    >
      <div className="flex items-center min-w-0 flex-grow">
        {label && (
          <span className={cx(
            'flex-shrink-0 w-12 font-medium text-sm',
            {
              'text-teal-700 dark:text-teal-300': isSelected && !isSystem,
              'text-gray-600 dark:text-gray-400': !isSelected && !isSystem
            }
          )}>{label}</span>
        )}
        <span className={cx(
          'truncate text-sm',
          {
            'text-teal-700 dark:text-teal-300': isSelected && !isSystem,
            'text-yellow-700 dark:text-yellow-300': isSystem,
            'text-gray-900 dark:text-gray-100': !isSelected && !isSystem && !isLocked,
            'text-gray-500 dark:text-gray-500': isLocked
          }
        )}>{week.name}</span>
      </div>
      <div className="flex items-center space-x-1.5 flex-shrink-0 ml-2">
        {!isLocked && !week.isSystem && (
          <CheckCircleIcon className={cx(
            'w-4 h-4 transition-opacity',
            {
              'opacity-100': isSelected,
              'opacity-0 group-hover:opacity-50': !isSelected
            },
            'text-teal-500 dark:text-teal-400'
          )} />
        )}
        {week.hasInterviewDependant && (
          <TelegramIcon
            className={cx('h-4 w-4', {
              'text-green-500 dark:text-green-400': week.isInterviewDone,
              'text-red-500 dark:text-red-400': !week.isInterviewDone
            })}
          />
        )}
        {isLocked && <LockClosedIcon className="h-4 w-4 text-gray-400 dark:text-gray-600" />}
      </div>
    </button>
  )
}

const CourseOverview = () => {
  const navigate = useNavigate()
  const params = useParams()
  const location = useLocation()
  const [isSidebarOpen, setIsSidebarOpen] = useState(false)
  const course = useAppSelector((state) =>
    state.courses.list?.find((it) => it.courseId === params.id)
  )
  const user = useAppSelector((state) => state.authentication.user)

  const getLastAvailableWeek = useCallback(() => {
    if (!course?.weeks) return null

    // Try to get last opened week from localStorage
    const lastWeekId = localStorage.getItem(`${LAST_WEEK_KEY_PREFIX}${course.courseId}`)
    if (lastWeekId && course.weeks.some(w => w.weekId === lastWeekId)) {
      return lastWeekId
    }

    // Otherwise, find the last available (unlocked) week
    const regularWeeks = course.weeks.filter((week) => !week.isSystem)
    const availableWeeks = regularWeeks.filter(week => {
      const hasAllAchievenments = week.dependantAchievements?.filter(it => it?.length > 0)
        .every((it) => (user.achievements ?? []).includes(it))
      return user.role === 'admin' || hasAllAchievenments
    })

    return availableWeeks.length > 0 ? availableWeeks[availableWeeks.length - 1].weekId : null
  }, [course?.weeks, course?.courseId, user.role, user.achievements])

  useEffect(() => {
    // Only navigate to a week if we're on the course page and no week is selected
    if (course?.courseId && params.id && !params.weekId) {
      const weekId = getLastAvailableWeek()
      if (weekId) {
        navigate(`/${getLanguage()}/course/${course.courseId}/${weekId}`)
      }
    }
  }, [course?.courseId, params.id, params.weekId, navigate, getLastAvailableWeek])

  // Show enrolled courses on the main course page
  if (!params.id || location.pathname === `/${getLanguage()}/course`) {
    return (
      <div className="container mx-auto px-4 py-8">
        <Head title="My Courses" />
        <EnrolledCourses />
      </div>
    )
  }

  if (!course?.name) {
    return <Loader />
  }

  const weekName = course.weeks?.find((it) => params.weekId === it.weekId)?.name

  const handleWeekSelect = (weekId: string) => {
    localStorage.setItem(`${LAST_WEEK_KEY_PREFIX}${course.courseId}`, weekId)
    navigate(`/${getLanguage()}/course/${course.courseId}/${weekId}`)
    setIsSidebarOpen(false)
  }

  const handleCourseClick = (e: React.MouseEvent) => {
    e.preventDefault()
    const weekId = params.weekId || getLastAvailableWeek()
    if (weekId) {
      navigate(`/${getLanguage()}/course/${course.courseId}/${weekId}`)
    }
    setIsSidebarOpen(true)
  }

  const systemWeeks = course.weeks?.filter((week) => week.isSystem) || []
  const regularWeeks = course.weeks?.filter((week) => !week.isSystem) || []

  const WeeksList = () => (
    <>
      {systemWeeks.length > 0 && (
        <div className="mb-4">
          {systemWeeks.map((week) => (
            <WeekItem
              key={week.weekId}
              week={week}
              index={0}
              totalWeeks={0}
              isSelected={params.weekId === week.weekId}
              isLocked={false}
              onSelect={handleWeekSelect}
              isSystem
            />
          ))}
        </div>
      )}

      <div>
        <h3 className="text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider px-3 mb-2">Course Weeks</h3>
        {regularWeeks
          .slice()
          .reverse()
          .map((week, index, array) => {
            const hasAllAchievenments = week.dependantAchievements?.filter(it => it?.length > 0)
              .every((it) => (user.achievements ?? []).includes(it))
            const isLocked = user.role !== 'admin' && (!hasAllAchievenments)
            
            return (
              <WeekItem
                key={week.weekId}
                week={week}
                index={index}
                totalWeeks={array.length}
                isSelected={params.weekId === week.weekId}
                isLocked={isLocked}
                onSelect={handleWeekSelect}
                isSystem={false}
              />
            )
          })}
      </div>
    </>
  )

  return (
    <div className="container mx-auto px-4 py-8">
      <Head title="Course Overview" />
      <Breadcrumb 
        course={course} 
        weekName={weekName} 
        onCourseClick={handleCourseClick}
      />

      <div className="flex flex-col md:flex-row gap-8 relative">
        {/* Mobile Toggle Button */}
        <button
          type="button"
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              setIsSidebarOpen(!isSidebarOpen)
            }
          }}
          className="fixed bottom-4 right-4 z-50 md:hidden bg-teal-600 text-white p-3 rounded-full shadow-lg hover:bg-teal-700 transition-colors duration-200"
          aria-label="Toggle weeks menu"
        >
          {isSidebarOpen ? (
            <XMarkIcon className="h-6 w-6" />
          ) : (
            <Bars3Icon className="h-6 w-6" />
          )}
        </button>

        {/* Mobile Sidebar Overlay */}
        <div
          className={cx(
            'fixed inset-0 bg-black/50 z-40 md:hidden transition-opacity duration-300',
            {
              'opacity-100 pointer-events-auto': isSidebarOpen,
              'opacity-0 pointer-events-none': !isSidebarOpen
            }
          )}
          onClick={() => setIsSidebarOpen(false)}
          onKeyPress={(e) => {
            if (e.key === 'Enter' || e.key === ' ') {
              setIsSidebarOpen(false)
            }
          }}
          role="button"
          tabIndex={0}
        />

        {/* Sidebar */}
        <aside
          className={cx(
            'md:w-1/3 lg:w-1/4 bg-white dark:bg-gray-800 md:bg-transparent md:dark:bg-transparent',
            'fixed md:static inset-y-0 left-0 z-40 md:z-0',
            'w-3/4 max-w-sm md:max-w-none',
            'transform transition-transform duration-300 ease-in-out md:transform-none',
            'overflow-y-auto md:overflow-visible',
            'p-4 md:p-0 pt-16 md:pt-0',
            {
              'translate-x-0': isSidebarOpen,
              '-translate-x-full': !isSidebarOpen
            }
          )}
        >
          <WeeksList />
        </aside>

        {/* Main Content */}
        <main className="flex-grow md:ml-0">
          <Outlet />
        </main>
      </div>
    </div>
  )
}

export default React.memo(CourseOverview)
