import React, { useState } from 'react'
import axios from 'axios'
import { toastr } from 'react-redux-toastr'
import Cookies from 'universal-cookie'
import { XCircleIcon } from '@heroicons/react/20/solid'
import { tryLogin } from '@data/reducers/authentication'
import { useAppDispatch, useAppSelector } from '@data'
import Loader from '../common/loader'

const cookies = new Cookies()

const OAUTH_URLS = {
  linkedIn:
    'https://www.linkedin.com/oauth/v2/authorization?response_type=code&scope=r_emailaddress%20r_liteprofile&client_id=816yv63ae42fk6&redirect_uri=https://skillcrucial.com/api/v1/social/callback/linkedin',
  github:
    'https://github.com/login/oauth/authorize?scope=user:email&client_id=bb227ba728f10e580464',
  stackOverflow:
    'https://stackoverflow.com/oauth?client_id=16013&scope=private_info&redirect_uri=https://skillcrucial.com/api/v1/social/callback/stackoverflow',
  twitter:
    'https://twitter.com/oauth?client_id=16013&scope=private_info&redirect_uri=https://skillcrucial.com/api/v1/social/callback/stackoverflow'
}

const SocialConnected = () => {
  const user = useAppSelector((s) => s.authentication.user)
  const dispatch = useAppDispatch()

  const [githubData, setGithubData] = useState(
    user.github.login ? user.github : cookies.get('github_data') || {}
  )
  const [stackOverflowData, setStackoverflowData] = useState(
    user.stackOverflow.display_name ? user.stackOverflow : cookies.get('stackoverflow_data') || {}
  )
  const [linkedInData, setLinkedinData] = useState(
    user.linkedIn.localizedLastName ? user.linkedIn : cookies.get('linkedin_data') || {}
  )
  const [twitterData] = useState(cookies.get('twitter_data') || {})
  const [codewarsApiKey, setCodewarsApiKey] = useState(user.codewarsApiKey)
  const [codewarsUserName, setCodewarsUserName] = useState(user.codewarsUserName)

  interface SocialData {
    [key: string]: any
  }

  interface User {
    github: SocialData
    stackOverflow: SocialData
    linkedIn: SocialData
    codewarsApiKey: string
    codewarsUserName: string
    email: string
  }

  const removeSocialAccount = (platform: string, setterFunction: React.Dispatch<React.SetStateAction<SocialData>>) => {
    axios.delete(`/api/v1/user/social/${platform}`).then(() => {
      dispatch(tryLogin())
      toastr.info('!', `${platform} was removed from account`)
      cookies.remove(`${platform.toLowerCase()}_data`)
      setterFunction({})
    })
  }

  const renderSocialButton = (platform: string, data: any, url: string, icon: string) => {
    const isConnected = data && Object.keys(data).length > 0
    const buttonClass = `btn max-w-64 flex btn-social w-full p-2 !px-10 btn-${platform.toLowerCase()} transition-all duration-200 hover:opacity-90`

    const getUserDisplayName = () => {
      if (platform === 'StackOverflow' && data.display_name) {
        return data.display_name
      }
      if (platform === 'LinkedIn' && data.localizedFirstName && data.localizedLastName) {
        return `${data.localizedFirstName} ${data.localizedLastName}`
      }
      if (platform === 'Github' && data.login) {
        return data.login
      }
      return 'Connected'
    }

    const isNotSaved = () => {
      const lowercasePlatform = platform.toLowerCase()
      // @ts-ignore
      if (user[lowercasePlatform]) {
        const key = Object.keys(data)[0]
        // @ts-ignore
        return !user[lowercasePlatform][key]
      }
      return false
    }

    return (
      <div className="mt-6 first:mt-0">
        <div className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">{platform}</div>
        <div className="flex items-center">
          {isConnected ? (
            <span className={`${buttonClass} dark:border-gray-600`}>
              <i className={`fab fa-${icon}`} />
              &nbsp;
              {getUserDisplayName()}
              &nbsp;
              {isNotSaved() && <span className="text-red-500 dark:text-red-400">[NOT SAVED]</span>}
            </span>
          ) : (
            <a className={`${buttonClass} dark:border-gray-600`} href={url}>
              <i className={`fab fa-${icon}`} />
              Sign in with {platform}
            </a>
          )}
          <button
            className="ml-3 transition-colors duration-200"
            type="button"
            onClick={() => removeSocialAccount(platform, () => {})}
            title={`Remove ${platform} connection`}
          >
            <XCircleIcon className="flex-shrink-0 h-6 w-6 text-red-600 hover:text-red-700 dark:text-red-500 dark:hover:text-red-400" />
          </button>
        </div>
      </div>
    )
  }

  if (!user.email) {
    return <Loader />
  }

  return (
    <div className="space-y-6">
      {renderSocialButton(
        'StackOverflow',
        stackOverflowData,
        OAUTH_URLS.stackOverflow,
        'stack-overflow'
      )}
      {renderSocialButton('Github', githubData, OAUTH_URLS.github, 'github')}
      {renderSocialButton('LinkedIn', linkedInData, OAUTH_URLS.linkedIn, 'linkedin')}

      <div className="hidden">
        {renderSocialButton('Twitter', twitterData, OAUTH_URLS.twitter, 'twitter')}
      </div>

      <fieldset className="mt-8 pl-0 pr-0 max-w-lg">
        <legend className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Codewars</legend>
        <div className="mt-1 bg-white dark:bg-gray-700 rounded-md shadow-sm -space-y-px">
          <div>
            <label htmlFor="cw-api-token" className="sr-only">
              Codewars api access token
            </label>
            <input
              id="cw-api-token"
              className="relative focus:ring-teal-700 focus:border-teal-700 block w-full sm:text-sm border-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white rounded-none rounded-tl-md rounded-tr-md transition-colors duration-200"
              value={codewarsApiKey}
              placeholder="API ACCESS TOKEN"
              onChange={(e) => setCodewarsApiKey(e.target.value)}
            />
          </div>
          <div className="flex -space-x-px mt-5">
            <div className="w-1/2 flex-1 min-w-0">
              <label htmlFor="cw-username" className="sr-only">
                Codewars username
              </label>
              <input
                id="cw-username"
                className="relative rounded-none rounded-bl-md rounded-br-md focus:ring-teal-700 focus:border-teal-700 block w-full sm:text-sm border-gray-300 dark:border-gray-600 dark:bg-gray-700 dark:text-white transition-colors duration-200"
                value={codewarsUserName}
                placeholder="USERNAME"
                onChange={(e) => setCodewarsUserName(e.target.value)}
              />
            </div>
          </div>
        </div>
      </fieldset>

      <div className="pt-6 pb-4">
        <button
          className="bg-teal-700 hover:bg-teal-600 text-white font-bold py-2 px-10 rounded float-right transition-colors duration-200 focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 dark:focus:ring-offset-gray-800"
          type="button"
          onClick={(e) => {
            e.preventDefault()
            e.nativeEvent.preventDefault()
            // Uncomment and implement the updateSocialsNetworks action if needed
            // dispatch(
            //   updateSocialsNetworks({
            //     github: githubData,
            //     linkedIn: linkedInData,
            //     stackOverflow: stackOverflowData,
            //     codewarsApiKey,
            //     codewarsUserName
            //   })
            // )
          }}
        >
          Save
        </button>
      </div>
    </div>
  )
}

export default SocialConnected
