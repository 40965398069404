import React from 'react';
import { Link } from 'react-router-dom';
import { getLanguage } from '../../config/i18n';

const Breadcrumb = ({ weekId, courseId, courseName, weekName, lessonName }: {
    weekId: string;
    courseId: string;
    courseName: string;
    weekName: string;
    lessonName: string;
}) => (
  <nav className="flex mb-6" aria-label="Breadcrumb">
    <ol className="flex flex-wrap items-center gap-2 text-sm">
      <li>
        <Link 
          to="/dashboard" 
          className="text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-200 transition-colors"
          aria-label="Home"
        >
          <svg 
            className="w-4 h-4" 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={1.5} 
              d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6" 
            />
          </svg>
        </Link>
      </li>

      <li className="flex items-center gap-2">
        <svg 
          className="w-3 h-3 text-gray-400 dark:text-gray-600" 
          fill="none" 
          stroke="currentColor" 
          viewBox="0 0 24 24"
          aria-hidden="true"
        >
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth={2} 
            d="M9 5l7 7-7 7" 
          />
        </svg>
        <Link
          to={`/${getLanguage()}/course/${courseId}`}
          className="text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-200 transition-colors"
        >
          {courseName}
        </Link>
      </li>

      <li className="flex items-center gap-2">
        <svg 
          className="w-3 h-3 text-gray-400 dark:text-gray-600" 
          fill="none" 
          stroke="currentColor" 
          viewBox="0 0 24 24"
          aria-hidden="true"
        >
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth={2} 
            d="M9 5l7 7-7 7" 
          />
        </svg>
        <Link
          to={`/${getLanguage()}/course/${courseId}/${weekId}`}
          className="text-gray-500 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-200 transition-colors"
        >
          {weekName}
        </Link>
      </li>

      <li className="flex items-center gap-2">
        <svg 
          className="w-3 h-3 text-gray-400 dark:text-gray-600" 
          fill="none" 
          stroke="currentColor" 
          viewBox="0 0 24 24"
          aria-hidden="true"
        >
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth={2} 
            d="M9 5l7 7-7 7" 
          />
        </svg>
        <span 
          className="text-gray-400 dark:text-gray-500" 
          aria-current="page"
        >
          {lessonName}
        </span>
      </li>
    </ol>
  </nav>
);

export default Breadcrumb;
