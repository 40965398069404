import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Header from './default/sections/header'
import Footer from './default/sections/footer'

import '../../assets/scss/404.scss'

const NotFound = () => {
  const { t } = useTranslation()

  return (
    <div className="landing">
      <Header />
      <section className="error-page flex items-center justify-center text-center min-h-[70vh]">
        <div className="error-content">
          <div className="glitch-wrapper">
            <h1 className="glitch" data-text="404">404</h1>
          </div>
          <p className="lead text-2xl text-gray-800 mb-6 fade-in">{t('404.title')}</p>
          <p className="text-gray-600 mb-8 fade-in-delay">{t('404.message')}</p>
          <Link
            className="home-button bg-teal-600 hover:bg-teal-500 text-white font-bold py-3 px-8 rounded-lg transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg"
            tabIndex={0}
            to="/"
          >
            {t('404.button')}
          </Link>
        </div>
      </section>
      <Footer />
    </div>
  )
}

export default NotFound
