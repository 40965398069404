import React, { useState } from 'react'
import ReactMarkdown from 'react-markdown'

import { sendWebTask } from '@data/reducers/courses'
import { useAppDispatch, useAppSelector } from '@data'

interface Props {
  task: any
  result: any
  index: number
  testDataId: string
}

const SingleWebTasks = ({ task, result, index, testDataId }: Props) => {
  const dispatch = useAppDispatch()
  const homeworkId = useAppSelector((s) => s.authentication.user.homeworkId)
  const [url, setUrl] = useState(
    (sessionStorage && sessionStorage.getItem(`${testDataId} - ${task.id}`)) ||
      (result || task)?.url ||
      ''
  )
  return (
    <div className="mt-5 mb-5" key={task.id}>
      <div>
        <h2 className="flex">
          {result.result.status === 'ok' && (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="46" height="46">
              <path
                className="heroicon-ui fill-current text-teal-500"
                d="M11 20v-2.08a6 6 0 0 1-4.24-3A4.02 4.02 0 0 1 2 11V6c0-1.1.9-2 2-2h2c0-1.1.9-2 2-2h8a2 2 0 0 1 2 2h2a2 2 0 0 1 2 2v5a4 4 0 0 1-4.76 3.93A6 6 0 0 1 13 17.92V20h4a1 1 0 0 1 0 2H7a1 1 0 0 1 0-2h4zm6.92-7H18a2 2 0 0 0 2-2V6h-2v6c0 .34-.03.67-.08 1zM6.08 13A6.04 6.04 0 0 1 6 12V6H4v5a2 2 0 0 0 2.08 2zM8 4v8a4 4 0 1 0 8 0V4H8z"
              />
            </svg>
          )}{' '}
          <span>Task {index + 1}</span>
        </h2>
        <div className="markdown">
          <ReactMarkdown>
            {task.task.replace(/\n/g, '  \n').split('HOMEWORK_ID').join(homeworkId)}
          </ReactMarkdown>
        </div>
        {result &&
          result.result.status === 'error' &&
          result.result.messages.map(({ message }: {message: string}) => {
            const [lines, ...messageBody] = message.split(' ')
            return (
              <div
                key={message}
                className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                role="alert"
              >
                <strong className="font-bold">{lines} </strong>
                <span className="block sm:inline">{messageBody.join(' ')}</span>
              </div>
            )
          })}

        {result.result.status === 'accepted' && (
          <div className="bg-orange-100 border border-orange-400 text-orange-700 px-4 py-3 rounded relative">
            <span className="block sm:inline">Task was accepted. Waiting for the result</span>
          </div>
        )}

        {result &&
          result.result.status === 'error' &&
          typeof result.result.message !== 'undefined' &&
          result.result.message.indexOf('actual') > 0 && (
            <div className="fail-test-case">
              <div className="fail-header">Test Failed</div>
              <div className="error-actual">
                &nbsp;&nbsp;Test Result: &nbsp;
                {typeof result.result.actual !== 'undefined'
                  ? JSON.stringify(result.result.actual)
                  : 'undefined'}
              </div>
              <div className="error-expected">
                Expected Code: &nbsp;
                {typeof result.result.expected !== 'undefined'
                  ? JSON.stringify(result.result.expected).replace(/ /g, '\u00a0')
                  : 'undefined'}
              </div>
              <div className="error-expected">
                Message: &nbsp;
                {typeof result.result.message !== 'undefined'
                  ? JSON.stringify(result.result.message).replace(/ /g, '\u00a0')
                  : 'undefined'}
              </div>
            </div>
          )}
        {result &&
          result.result.status === 'error' &&
          typeof result.result.message !== 'undefined' &&
          result.result.message.indexOf('actual') < 0 && (
            <div className="fail-test-case">
              <div className="fail-header">Test Failed</div>
              <div className="error-actual">{result.result.message.replace(/ /g, '\u00a0')}</div>
            </div>
          )}
      </div>

      <div className="flex">
        <div className="mr-2 w-20 text-md">Enter url: </div>
        <input
          className="appearance-none w-full bg-white border border-grey-light hover:border-grey px-2 py-2 rounded shadow"
          onChange={(e) => {
            setUrl(e.target.value)
          }}
          value={url}
        />
        <button
          type="button"
          className="bg-teal-700 hover:bg-teal-600 text-white font-bold py-2 px-6 float-right"
          onClick={() => {
            dispatch(sendWebTask(testDataId, task.id, url))
            if (sessionStorage) {
              sessionStorage.setItem(`${testDataId} - ${task.id}`, url)
            }
          }}
        >
          {' '}
          Send
        </button>
      </div>
    </div>
  )
}

SingleWebTasks.propTypes = {}

export default React.memo(SingleWebTasks)
