import React from 'react'
import { useAppSelector } from '@data'
import { User } from 'interfaces/mongoose.gen'
import Head from '../head'
import Achievements, { AchievementKey } from './achievements'
import ChangePassword from './change-password'
import BillingHistory from '../billing/billing-history'
import PaymentMethods from '../billing/payment-methods'
import PaymentHistory from '../billing/payment-history'

// Separate components for better organization
const UserHeader = ({ user }: {
  user: Partial<User> & { sid?: string | undefined; }
}) => {
  const placeholder =
    user.sex === 'F'
      ? '/images/profile_placeholder_female.webp'
      : '/images/profile_placeholder_male.webp'

  return (
    <div className="flex items-center justify-between px-4 py-3 bg-white dark:bg-gray-900 border-b border-gray-200 dark:border-gray-600">
      <div className="flex items-center space-x-3">
        <img className="h-12 w-12 rounded-full" src={user.picture || placeholder} alt={user.name} />
        <div>
          <h3 className="text-lg font-semibold text-gray-900 dark:text-white">{user.name}</h3>
          <p className="text-sm text-gray-500 dark:text-gray-300">{user.email}</p>
        </div>
      </div>
      <div className="px-3 py-1.5 bg-gray-100 dark:bg-gray-800 rounded-md text-sm font-medium text-gray-700 dark:text-gray-100">
        {user.homeworkId}
      </div>
    </div>
  )
}

const StatsCard = ({ name, stat }: {
  name: string;
  stat: string | number;
}) => (
  <div className="bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden">
    <div className="px-4 py-5 sm:p-6">
      <dt className="text-sm font-medium text-gray-500 dark:text-gray-400 truncate">{name}</dt>
      <dd className="mt-1 text-3xl font-semibold text-gray-900 dark:text-gray-100">{stat}</dd>
    </div>
  </div>
)

const SectionHeader = ({ title }: {
  title: string;
}) => (
  <h3 className="text-lg font-semibold text-gray-900 dark:text-gray-100 mb-4">{title}</h3>
)

const Section = ({ title, children }: {
  title: string;
  children: React.ReactNode;
}) => (
  <div className="bg-white dark:bg-gray-800 shadow rounded-lg overflow-hidden mb-6">
    <div className="p-6">
      <SectionHeader title={title} />
      {children}
    </div>
  </div>
)

const Profile = () => {
  const user = useAppSelector((state) => state.authentication.user)

  const stats = [
    { name: 'Achievements', stat: (user.achievements ?? []).length },
    { name: 'Next Event', stat: 'N/A' },
    { name: 'Score', stat: 'N/A' }
  ]

  return (
    <div className="bg-gray-100 dark:bg-gray-900 min-h-screen">
      <Head title="User Profile" />
      <UserHeader user={user} />

      <div className="max-w-7xl mx-auto py-6 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-3 mb-6">
          {stats.map((item) => (
            <StatsCard key={item.name} name={item.name} stat={item.stat} />
          ))}
        </div>

        <Section title="Change Password">
          <ChangePassword />
        </Section>

        {(user.achievements ?? [])?.length > 0 && (
          <Section title="My Achievements">
            <Achievements achievements={(user.achievements ?? []) as AchievementKey[]} />
          </Section>
        )}

        <Section title="Payment Methods">
          <PaymentMethods />
        </Section>

        <Section title="Payment History">
          <PaymentHistory />
        </Section>

        <Section title="Billing Information (Old)">
          <BillingHistory />
        </Section>
      </div>
    </div>
  )
}

export default Profile
