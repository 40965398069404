import React, { useState, useRef, useEffect, useCallback } from 'react';

interface TooltipProps {
  content: React.ReactNode;
  children: React.ReactElement<React.HTMLAttributes<HTMLElement>>;
  delay?: number;
}

interface Position {
  top: number;
  left: number;
}

interface TooltipChildProps extends React.HTMLAttributes<HTMLElement> {
  'aria-describedby'?: string;
  role: string;
  tabIndex: number;
}

export const Tooltip: React.FC<TooltipProps> = ({ content, children, delay = 300 }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [position, setPosition] = useState<Position>({ top: 0, left: 0 });
  const triggerRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);
  let timeoutId: NodeJS.Timeout;

  const updatePosition = useCallback(() => {
    if (triggerRef.current && tooltipRef.current) {
      const triggerRect = triggerRef.current.getBoundingClientRect();
      const tooltipRect = tooltipRef.current.getBoundingClientRect();

      setPosition({
        top: triggerRect.top - tooltipRect.height - 10,
        left: triggerRect.left + (triggerRect.width - tooltipRect.width) / 2
      });
    }
  }, []);

  const handleMouseEnter = () => {
    timeoutId = setTimeout(() => {
      setIsVisible(true);
      updatePosition();
    }, delay);
  };

  const handleMouseLeave = () => {
    clearTimeout(timeoutId);
    setIsVisible(false);
  };

  const handleFocus = () => {
    setIsVisible(true);
    updatePosition();
  };

  const handleBlur = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (isVisible) {
        updatePosition();
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', updatePosition);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', updatePosition);
    };
  }, [isVisible, updatePosition]);

  const enhancedProps: TooltipChildProps = {
    ...children.props,
    tabIndex: children.props.tabIndex ?? 0,
    role: 'button',
  };

  if (isVisible) {
    enhancedProps['aria-describedby'] = 'tooltip';
  }

  return (
    <div
      ref={triggerRef}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onFocus={handleFocus}
      onBlur={handleBlur}
      className="inline-block"
    >
      {React.cloneElement(children, enhancedProps)}
      {isVisible && (
        <div
          id="tooltip"
          ref={tooltipRef}
          className="absolute z-10 px-3 py-2 text-sm font-medium text-white bg-gray-900 rounded-lg shadow-sm transition-opacity duration-300"
          style={{
            top: `${position.top}px`,
            left: `${position.left}px`,
          }}
          role="tooltip"
        >
          {content}
          <div className="tooltip-arrow" data-popper-arrow />
        </div>
      )}
    </div>
  );
};
