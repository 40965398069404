import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import { CircleIcon, MessageCircleIcon, EyeIcon, ClipboardListIcon, BookOpenIcon } from 'lucide-react';

import { loadLessons } from '@data/reducers/courses';
import type { RootState } from '@data';
import { useAppDispatch } from '@data';
import Loader from '../common/loader';
import { getLanguage } from '../../config/i18n';
import Head from '../head';
import type { Lesson } from '../../interfaces/mongoose.gen';

const LessonItem = ({ lesson, courseId, weekId }: {
  lesson: Partial<Lesson>,
  courseId: string,
  weekId: string
}) => (
  <Link
    className="group block no-underline hover:bg-gray-50 dark:hover:bg-gray-800/50 transition-colors duration-200"
    to={`/${getLanguage()}/course/${courseId}/${weekId}/${lesson.id}`}
  >
    <div className="relative border-l-4 border-transparent group-hover:border-teal-500 dark:group-hover:border-teal-400">
      <div className="px-4 py-3">
        <div className="flex items-start justify-between">
          <div className="flex min-w-0 flex-grow">
            <CircleIcon className="w-4 h-4 mt-1 mr-3 text-teal-500 flex-shrink-0 opacity-0 group-hover:opacity-100 transition-opacity" />
            <div className="min-w-0 flex-grow">
              <h3 className="font-medium text-base mb-1 text-gray-900 dark:text-gray-100 truncate">{lesson.name}</h3>
              {lesson.promoDescription && (
                <ReactMarkdown className="text-sm text-gray-600 dark:text-gray-400 line-clamp-2 mb-2">
                  {lesson.promoDescription.replace(/\r\n/g, '\n').replace(/\n/g, '  \n')}
                </ReactMarkdown>
              )}
              {lesson.homeworkId && lesson.homeworkId.length > 0 && (
                <div className="flex items-center text-xs text-gray-500 dark:text-gray-400">
                  <ClipboardListIcon className="w-3.5 h-3.5 mr-1" />
                  <span>Homework</span>
                </div>
              )}
            </div>
          </div>
          <div className="flex items-start gap-4 ml-4 text-xs text-gray-500 dark:text-gray-400">
            <div className="flex items-center" title="Discussions">
              <MessageCircleIcon className="w-3.5 h-3.5 mr-1" />
              <span>{lesson.countOfQuestions || 0}</span>
            </div>
            <div className="flex items-center" title="Views">
              <EyeIcon className="w-3.5 h-3.5 mr-1" />
              <span>{lesson.countOfViews || 0}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Link>
);

const LessonsList = () => {
  const myRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();
  const params = useParams();
  const { week, courseName, courseId } = useSelector((state: RootState) => {
    const { weeks = [], name: courseName1 } = state.courses.list?.find((it) => it.courseId === params.id) ?? {
      weeks: [],
      name: 'unknown'
    };
    return {
      week: weeks.find((it) => it.weekId === params.weekId),
      courseName: courseName1,
      courseId: params.id
    };
  });

  useEffect(() => {
    const element = document.getElementById('main-container');
    if (window.innerWidth < 600 && element && myRef.current) {
      const currentRef = myRef.current;
      setTimeout(() => {
        element.scrollTo({
          top: currentRef.offsetTop - 120,
          behavior: 'smooth',
        });
      }, 100);
    }
  }, []);

  useEffect(() => {
    if (week && !week.isLoaded) {
      dispatch(loadLessons(week));
    }
  }, [week, dispatch]);

  if (!week?.name) {
    return <Loader />;
  }

  const weekId = params.weekId || '';
  const safeWeekLessons = week.lessons || [];
  
  // Create a map of lessons by ID for quick lookup
  const lessonsMap = safeWeekLessons.reduce((acc, lesson) => {
    if (lesson._id) {
      acc[lesson._id] = lesson;
    }
    return acc;
  }, {} as Record<string, Partial<Lesson>>);

  // Order lessons according to lessonIds array
  const orderedLessons = (week.lessonIds || [])
    .map(id => id ? lessonsMap[id] : undefined)
    .filter(Boolean) as Partial<Lesson>[];
    
  return (
    <div className="mx-auto">
      <Head title={`${courseName} - ${week.name}`} />
      
      <div className="mb-6">
        <h1 className="text-2xl font-semibold text-gray-900 dark:text-gray-100 mb-2">{week.name}</h1>
        {week.description && (
          <div className="text-sm text-gray-600 dark:text-gray-400">
            <ReactMarkdown className="prose dark:prose-invert max-w-none">
              {week.description.replace(/\r\n/g, '\n').replace(/\n/g, '  \n')}
            </ReactMarkdown>
          </div>
        )}
      </div>

      <div className="space-y-6">
        <div className="bg-white dark:bg-gray-800/50 rounded-lg overflow-hidden" ref={myRef}>
          <div className="border-b border-gray-200 dark:border-gray-700">
            <h2 className="text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider p-4">
              Lessons
            </h2>
          </div>

          <div className="divide-y divide-gray-100 dark:divide-gray-700/50">
            {!week.isLoaded ? (
              week.lessonIds.map((id) => (
                <div key={id} className="animate-pulse p-4">
                  <div className="flex items-center justify-between">
                    <div className="flex-grow">
                      <div className="flex items-center mb-3">
                        <div className="w-4 h-4 bg-teal-200 dark:bg-teal-700 rounded-full mr-3" />
                        <div className="h-5 bg-teal-100 dark:bg-teal-800 rounded w-1/2" />
                      </div>
                      <div className="h-12 bg-gray-100 dark:bg-gray-700 rounded w-full mb-3" />
                    </div>
                    <div className="ml-4 flex gap-4">
                      <div className="h-3 bg-gray-100 dark:bg-gray-700 rounded w-12" />
                      <div className="h-3 bg-gray-100 dark:bg-gray-700 rounded w-12" />
                    </div>
                  </div>
                </div>
              ))
            ) : (
              orderedLessons.map((lesson) => (
                <LessonItem 
                  key={lesson.id} 
                  lesson={lesson} 
                  courseId={courseId || ''} 
                  weekId={weekId} 
                />
              ))
            )}
          </div>
        </div>

        <div className="bg-white dark:bg-gray-800/50 rounded-lg overflow-hidden">
          <div className="border-b border-gray-200 dark:border-gray-700">
            <h2 className="text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider p-4">
              Interview Preparation
            </h2>
          </div>

          <Link
            className="group block no-underline hover:bg-gray-50 dark:hover:bg-gray-800/50 transition-colors duration-200"
            to={`/${getLanguage()}/course/${courseId}/${weekId}/interview`}
          >
            <div className="relative border-l-4 border-transparent group-hover:border-yellow-500 dark:group-hover:border-yellow-400">
              <div className="px-4 py-3">
                <div className="flex items-start">
                  <BookOpenIcon className="w-4 h-4 mt-1 mr-3 text-yellow-500 flex-shrink-0 opacity-0 group-hover:opacity-100 transition-opacity" />
                  <div>
                    <h3 className="font-medium text-base mb-1 text-gray-900 dark:text-gray-100">Interview Questions</h3>
                    <p className="text-sm text-gray-600 dark:text-gray-400">
                      Prepare for your interviews with our curated questions
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LessonsList;
