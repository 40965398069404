import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import store from './data'
import Root from './config/root'
import { initializeTheme } from './utils/theme'
import './index.scss'
import './assets/scss/main.scss'
import './assets/css/font-awesome.css'
import './assets/css/slick.css'
import './assets/css/slick-theme.css'
import { tryLogin } from './data/reducers/authentication'

// Async polyfills
const start = async () => {
  const polyfills: Promise<any>[] = []
  // Commented out polyfills can be uncommented if needed in the future
  // if (!window.fetch) {
  //   polyfills.push(import('whatwg-fetch'))
  // }
  // if (!('scrollBehavior' in document.documentElement.style)) {
  //   polyfills.push(import('smoothscroll-polyfill'))
  // }
  // if (typeof 'str'.padStart === 'undefined') {
  //   polyfills.push(import('core-js/features/string/pad-start'))
  // }

  try {
    await Promise.all(polyfills)
  } catch (err) {
    console.error('Error loading polyfills:', err)
  }

  // Initialize theme system
  initializeTheme()

  // Dispatch tryLogin action to verify token on initial load
  await store.dispatch(tryLogin())

  const rootElement = document.getElementById('root')
  if (!rootElement) throw new Error('No root element found')

  const root = createRoot(rootElement)

  root.render(
    <Provider store={store}>
      <Root />
    </Provider>
  )
}

start()
