import { useState, useCallback } from 'react'
import debounce from 'lodash/debounce'
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { countries } from 'countries-list'
import { useNavigate } from 'react-router-dom'

import axios from 'axios'
import { subscribeToTheCourse } from '@data/reducers/authentication'
import { useAppDispatch, useAppSelector } from '@data'
import { getLanguage } from '../../config/i18n'

const CheckoutForm = ({ courseId }: {courseId: string}) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const stripe = useStripe()
  const elements = useElements()
  const loginToken = useAppSelector((state) => state.authentication.token)
  const [error, setError] = useState('')
  const [receiptUrl] = useState('')
  const [nameOnCard, setNameOnCard] = useState('')
  const [addressLine1, setAddressLine1] = useState('')
  const [addressLine2, setAddressLine2] = useState('')
  const [city, setCity] = useState('')
  const [state, setState] = useState('')
  const [country, setCountry] = useState('')

  const handleSubmit = useCallback(
    debounce(
      async (e) => {
        e.preventDefault()
        if (!stripe || !elements || elements.getElement(CardElement) == null) {
          return 
        }
        const { paymentMethod, error: errorStripe = {type: undefined, message: ''} } = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardElement)!,
          billing_details: {
            name: nameOnCard,
            address: {
              line1: addressLine1,
              line2: addressLine2,
              city,
              state,
              country
            }
          }
        })

        if (typeof errorStripe?.type !== 'undefined') {
          setError(`[${errorStripe.type}] ${errorStripe?.message!}`)
        } else {
          setError('')
        }

        if (typeof paymentMethod !== 'undefined') {
          const { id } = paymentMethod

          axios
            .post(
              '/api/v1/billing/stripe/save',
              {
                source: id,
                courseId
              },
              {
                headers: {
                  'Content-Type': 'application/json'
                }
              }
            )
            .then((response) => {
              if (response.data.status === 'ok') {
                navigate(`/${getLanguage()}/dashboard`)
              }
              dispatch(subscribeToTheCourse(courseId))
            })
            .catch((error2) => {
              console.log(error2)
              setError(error2.response.data.message)
            })
        }
      },
      500,
      { trailing: true }
    ),
    [addressLine1, addressLine2, city, country, courseId, loginToken, nameOnCard, state, stripe]
  )

  return (
    <div className="leading-loose">
      {error && (
        <div
          className="m-8 p-0 md:mx-10 bg-red-100 dark:bg-red-900/50 border border-red-400 dark:border-red-500 text-red-700 dark:text-red-200 px-4 py-3 rounded relative"
          role="alert"
        >
          <strong className="font-bold">Holy guacamolly! </strong>
          <span className="block sm:inline">{error}</span>
        </div>
      )}
      <div className="my-4 p-0 rounded flex flex-col">
        <p className="block uppercase tracking-wide text-gray-700 dark:text-gray-200 text-xs font-bold mb-2">
          Your Card Information
        </p>

        <div className="flex flex-wrap mb-2">
          <div className="w-full md:w-full px-0 mb-6">
            <div className="shadow-sm focus:ring-teal-500 focus:border-teal-500 block w-full sm:text-sm bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-100 rounded-md border px-2 py-3">
              <CardElement options={{
                style: {
                  base: {
                    color: 'currentColor',
                    '::placeholder': {
                      color: '#9ca3af'
                    }
                  }
                }
              }} />
            </div>
          </div>
          <div className="w-full md:w-full px-0 mb-6">
            <label
              className="block uppercase tracking-wide text-gray-700 dark:text-gray-200 text-xs font-bold mb-2"
              htmlFor="grid-city"
            >
              Full Name(Same as on Card)
            </label>
            <input
              className="shadow-sm focus:ring-teal-500 focus:border-teal-500 block w-full sm:text-sm bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-100 rounded-md placeholder-gray-400"
              placeholder="Obi Wan Kenoby"
              onChange={({ target }) => setNameOnCard(target.value)}
            />
          </div>
          <div className="w-full md:w-1/2 px-0 mb-6">
            <label
              className="block uppercase tracking-wide text-gray-700 dark:text-gray-200 text-xs font-bold mb-2"
              htmlFor="grid-city"
            >
              Address Line 1
            </label>
            <input
              className="shadow-sm focus:ring-teal-500 focus:border-teal-500 block w-full sm:text-sm bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-100 rounded-none rounded-tl-md rounded-bl-md placeholder-gray-400"
              placeholder="Tatuin"
              onChange={({ target }) => setAddressLine1(target.value)}
            />
          </div>
          <div className="w-full md:w-1/2 px-0 mb-6">
            <label
              className="block uppercase tracking-wide text-gray-700 dark:text-gray-200 text-xs font-bold mb-2"
              htmlFor="grid-city"
            >
              Address Line 2
            </label>
            <input
              className="shadow-sm focus:ring-teal-500 focus:border-teal-500 block w-full sm:text-sm bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-100 rounded-none rounded-tr-md rounded-br-md placeholder-gray-400"
              placeholder="Desert"
              onChange={({ target }) => setAddressLine2(target.value)}
            />
          </div>
          <div className="w-full md:w-1/2 px-0 mb-6">
            <label
              className="block uppercase tracking-wide text-gray-700 dark:text-gray-200 text-xs font-bold mb-2"
              htmlFor="grid-city"
            >
              City
            </label>
            <input
              className="shadow-sm focus:ring-teal-500 focus:border-teal-500 block w-full sm:text-sm bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-100 rounded-none rounded-tl-md rounded-bl-md placeholder-gray-400"
              placeholder="Middle Of Nowhere"
              onChange={({ target }) => setCity(target.value)}
            />
          </div>
          <div className="w-full md:w-1/2 px-0 mb-6">
            <label
              className="block uppercase tracking-wide text-gray-700 dark:text-gray-200 text-xs font-bold mb-2"
              htmlFor="grid-state"
            >
              State / Province
            </label>
            <div className="relative">
              <input
                className="shadow-sm focus:ring-teal-500 focus:border-teal-500 block w-full sm:text-sm bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-100 rounded-none rounded-tr-md rounded-br-md placeholder-gray-400"
                placeholder="State / Province"
                onChange={({ target }) => setState(target.value)}
              />
            </div>
          </div>
          <div className="w-full px-0 mb-6">
            <label
              className="block uppercase tracking-wide text-gray-700 dark:text-gray-200 text-xs font-bold mb-2"
              htmlFor="grid-zip"
            >
              Country
            </label>
            <select
              id="country"
              name="country"
              autoComplete="country"
              onChange={(e) => {
                setCountry(e.target.value)
              }}
              className="max-w-lg block focus:ring-teal-500 focus:border-teal-500 w-full shadow-sm sm:max-w-xs sm:text-sm bg-white dark:bg-gray-700 border-gray-300 dark:border-gray-600 text-gray-900 dark:text-gray-100 rounded-md"
            >
              {Object.keys(countries).map((key) => {
                return (
                  <option key={key} value={key}>
                    {countries[key as keyof typeof countries].name}
                  </option>
                )
              })}
            </select>
          </div>
        </div>

        <div className="mt-1 md:mt-5 flex content-between w-full flex-col md:flex-row mb-6">
          {!receiptUrl && (
            <div className="flex flex-grow items-center uppercase tracking-wide text-gray-500 dark:text-gray-400 text-xs font-bold mb-0">
              skillcrucial.com does not get/store/transfer your card data to the server. Payment
              data automatically collected and handled with stripe.com
            </div>
          )}
          <button
            onClick={handleSubmit}
            type="submit"
            className="px-6 mt-4 md:mt-0 self-end flex py-4 text-white font-medium bg-teal-600 hover:bg-teal-700 rounded transition-colors duration-200"
          >
            {courseId === 'update-payment-method' ? 'Save' : 'Pay'}
          </button>
        </div>
      </div>
    </div>
  )
}

export default CheckoutForm
