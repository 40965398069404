/* eslint-disable max-len */
import React from 'react'

import { useTranslation } from 'react-i18next'

import Head from '../head'
import Header from './default/sections/header'
import Footer from './default/sections/footer'

/* eslint max-len: ["error", { "code": 150 }] */

const terms = `
Disclaimers
Last modified:  06/07/2020

By accessing the website you hereby understand and agree to the following statements:

No information contained herein is made for offer, marketing, advertisement or solicitation purposes, in any jurisdiction.

These Services and any information contained herein is made available by for general information only and not for any other purpose. The User agrees that these Services shall be used solely as reference, or for informational use and not for any other purposes, commercial or otherwise.

The information in these Services is not intended and should not be construed as investment, tax, legal, or financial advice.

The User understands and agrees that the General Information is not intended to be complete, final or up-to-date.

The User understands and agrees that the General Information may express opinions of Skillcrucial. These opinions may differ or be contrary to opinions expressed by any of the related corporations, subsidiaries or affiliates of Skillcrucial, or by Skillcrucial itself, as a result of using different assumptions and criteria.

All statements of opinion represent Skillcrucial’s own assessment and interpretation of General Information available to Skillcrucial at the time of its assessment. All such General Information is subject to change without notice, and Skillcrucial is under no obligation to update or keep current the General Information.





`
const TermsOfService = () => {
  const { t } = useTranslation()

  return (
    <div className="default-landing">
      <Head
        title={t('faq.seo.title')}
        keywords={t('faq.seo.keywords')}
        description={t('faq.seo.description')}
        subject={t('faq.seo.subject')}
      />
      <div id="page-wrapper ">
        <Header />
        <div className="prose text-lg">
          <section className="full-section min-h-screen mb-0" style={{ minHeight: '900px' }}>
            <div className="px-2 md:px-10 flex flex-col align-center justify-center  p-5">
              <h1 className="mb-5 mp-5">
                Standard Terms for the Purchase of Online and Taught Courses
              </h1>
              {terms.split('\n').map((it) => {
                return (
                  <p className="text-sm" key={it}>
                    {it}
                  </p>
                )
              })}
            </div>
          </section>
        </div>
        <Footer />
      </div>
      <a id="go-top">
        <i className="miu-icon-circle_arrow-up_glyph" />
      </a>
    </div>
  )
}

export default TermsOfService
