import React from 'react'
import { Link } from 'react-router-dom'
import { getLanguage } from '../../config/i18n'

interface Course {
  courseId: string
  name: string
  picture?: string
  startDate: Date
  endDate: Date
  isEnrolled?: boolean
}

const CourseImage = ({ picture, isEnrolled }: {
  picture?: string
  isEnrolled?: boolean
}) => (
  <div className="relative h-40 bg-gray-100 dark:bg-gray-800 overflow-hidden">
    <div className="absolute inset-0 bg-gradient-to-t from-black/40 to-transparent z-[5]" />
    <img
      className={`w-full h-full object-cover transition-transform duration-300 ${
        !isEnrolled && 'group-hover:scale-105'
      }`}
      src={picture || '/placeholder-image.jpg'}
      alt="Course thumbnail"
      loading="lazy"
    />
  </div>
)

const CourseHeader = ({ name, courseId, isAdmin }: {
  name: string
  courseId: string
  isAdmin: boolean
}) => (
  <div className="flex justify-between items-start gap-2 h-12">
    <h3 className="text-sm font-medium text-gray-900 dark:text-gray-100 line-clamp-2">
      {name}
    </h3>
    {isAdmin && (
      <Link
        to={`/${getLanguage()}/admin/course/edit/${courseId}`}
        className="flex items-center gap-1 text-gray-500 hover:text-gray-700 dark:text-gray-400 dark:hover:text-gray-200 text-xs transition-colors shrink-0"
      >
        <svg 
          className="w-3.5 h-3.5" 
          fill="none" 
          stroke="currentColor" 
          viewBox="0 0 24 24"
          role="img"
          aria-labelledby="editIcon"
        >
          <title id="editIcon">Edit course</title>
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
        </svg>
        <span>Edit</span>
      </Link>
    )}
  </div>
)

const CourseDates = ({ startDate, endDate }: {
  startDate: Date
  endDate: Date  
}) => (
  <div className="flex items-center gap-1.5 text-xs text-gray-500 dark:text-gray-400">
    <svg 
      className="w-3.5 h-3.5 shrink-0" 
      fill="none" 
      stroke="currentColor" 
      viewBox="0 0 24 24"
      role="img"
      aria-labelledby="calendarIcon"
    >
      <title id="calendarIcon">Course dates</title>
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
    </svg>
    <div>
      <time dateTime={new Date(startDate).toISOString()}>
        {new Date(startDate).toLocaleDateString('en-US', {
          day: 'numeric',
          month: 'short'
        })}
      </time>
      <span className="mx-1">-</span>
      <time dateTime={new Date(endDate).toISOString()}>
        {new Date(endDate).toLocaleDateString('en-US', {
          day: 'numeric',
          month: 'short'
        })}
      </time>
    </div>
  </div>
)

const CourseStatus = ({ isEnrolled = false }: { isEnrolled?: boolean }) => (
  <div className={
    `absolute top-2 right-2 z-[10] px-2 py-0.5 rounded-full text-xs font-medium ${
      isEnrolled 
        ? 'bg-amber-100 text-amber-700 dark:bg-amber-900/50 dark:text-amber-300' 
        : 'bg-emerald-100 text-emerald-700 dark:bg-emerald-900/50 dark:text-emerald-300'
    }`
  }>
    {isEnrolled ? 'Coming Soon' : 'Active'}
  </div>
)

const CourseCard = ({ course, isAdmin }: {
  course: Course
  isAdmin: boolean
}) => {
  const isEnrolled = course.isEnrolled || false

  return (
    <div className={
      `relative group bg-white dark:bg-gray-800 rounded-lg overflow-hidden border ${
        isEnrolled 
          ? 'border-amber-200 dark:border-amber-800' 
          : 'border-gray-200 dark:border-gray-700'
      }`
    }>
      <CourseImage picture={course.picture} isEnrolled={isEnrolled} />
      <CourseStatus isEnrolled={isEnrolled} />
      
      <div className="p-4 space-y-4">
        <CourseHeader name={course.name} courseId={course.courseId} isAdmin={isAdmin} />
        
        {!isEnrolled && <CourseDates startDate={course.startDate} endDate={course.endDate} />}

        {isEnrolled ? (
          <div className="border-t border-gray-100 dark:border-gray-700 pt-3">
            <div className="flex items-center gap-1.5 text-amber-600 dark:text-amber-400">
              <svg 
                className="w-3.5 h-3.5 shrink-0" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24"
                role="img"
                aria-labelledby="clockIcon"
              >
                <title id="clockIcon">Enrollment status</title>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={1.5} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              <span className="text-xs font-medium">Enrollment Confirmed</span>
            </div>
            <p className="mt-1 text-xs text-gray-500 dark:text-gray-400">
              You'll get access to this course soon
            </p>
          </div>
        ) : (
          <div className="border-t border-gray-100 dark:border-gray-700 pt-3">
            <Link
              to={`/${getLanguage()}/course/${course.courseId}`}
              className="block w-full text-center text-xs font-medium py-1.5 px-3 bg-gray-100 hover:bg-gray-200 dark:bg-gray-700 dark:hover:bg-gray-600 text-gray-900 dark:text-gray-100 rounded-md transition-colors"
            >
              View Course
            </Link>
          </div>
        )}
      </div>
    </div>
  )
}

export default CourseCard
