import { Action } from 'redux'
import { getSocket } from '../sockets'

const ADMIN_JS_TASK_DATA = 'ADMIN_JS_TASK_DATA'
const ADMIN_JS_TASK_CLEAR_DATA = 'ADMIN_JS_TASK_CLEAR_DATA'
const ADMIN_JS_TASK_CLEAR_USER_DATA = 'ADMIN_JS_TASK_CLEAR_USER_DATA'

const SEND_COMMENT = 'SEND_COMMENT'
const REFRESH_TASK = 'REFRESH_TASK'

interface AdminSupportState {
  list: any[]
}

interface AdminSupportAction extends Action {
  type: string
  data: any
}

const initialState: AdminSupportState = {
  list: []
}

const adminSupportReducer = (state = initialState, action: AdminSupportAction): AdminSupportState => {
  switch (action.type) {
    case ADMIN_JS_TASK_DATA:
      return {
        ...state,
        list: [
          ...state.list.filter((zzz) => {
            return `${zzz.taskId}${zzz.userId}` !== `${action.data.taskId}${action.data.userId}`
          }),
          action.data
        ]
      }
    case ADMIN_JS_TASK_CLEAR_DATA:
      return {
        ...state,
        list: [
          ...state.list.filter((zzz) => {
            return `${zzz.taskId}${zzz.userId}` !== `${action.data.taskId}${action.data.userId}`
          })
        ]
      }
    case ADMIN_JS_TASK_CLEAR_USER_DATA:
      return {
        ...state,
        list: [
          ...state.list.filter((zzz) => {
            return zzz.userId !== action.data.userId
          })
        ]
      }
    case SEND_COMMENT:
      return {
        ...state,
        list: state.list.filter((zzz) => {
          return `${zzz.taskId}${zzz.userId}` !== `${action.data.taskId}${action.data.userId}`
        })
      }
    case REFRESH_TASK:
      return {
        ...state,
        list: state.list.filter((zzz) => {
          return `${zzz.taskId}${zzz.userId}` !== `${action.data.taskId}${action.data.userId}`
        })
      }
    default:
      return state
  }
}

export const sendMessage = (data: any, message: string) => {
  return (dispatch: any) => {
    dispatch({ type: SEND_COMMENT, data })
    getSocket()?.emit('send-message', { userId: data.userId, message })
  }
}

export const refreshTask = (task: any) => {
  return (dispatch: any) => {
    dispatch({ type: REFRESH_TASK, data: task })
    getSocket()?.emit('code-update', { userId: task.userId, task: { ...task, type: 'CODE_UPDATE' } })
  }
}

export default adminSupportReducer
