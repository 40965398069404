import React from 'react'
import { useParams } from 'react-router-dom'
import { useAppSelector } from '@data'
import ReactMarkdown from 'react-markdown'
import cx from 'classnames'

const CourseDescription = () => {
  const params = useParams()
  const course = useAppSelector((state) =>
    state.courses.list?.find((it) => it.courseId === params.id)
  )

  if (!course) {
    return (
      <div className="flex justify-center items-center min-h-[200px] text-red-600 dark:text-red-400">
        Course not found. Please check the URL and try again.
      </div>
    )
  }

  const topics = (typeof course?.description === 'string' ? course.description : '')
    .split('\n')
    .filter((item) => item.trim() !== '')

  return (
    <div className={cx('mx-auto p-6 max-w-4xl', 'rounded-lg')}>
      <h1
        className={cx(
          'text-3xl font-bold mb-8',
          'text-gray-900 dark:text-gray-100',
          'border-b border-gray-200 dark:border-gray-700 pb-4'
        )}
      >
        {course.name}
      </h1>

      <div className={cx('space-y-6', 'markdown', 'prose dark:prose-invert max-w-none')}>
        {topics.map((topic, index) => (
          <div key={index} className="flex items-start">
            <div className="flex-grow">
              <div
                className={cx(
                  'prose prose-lg dark:prose-invert',
                  'text-gray-800 dark:text-gray-200',
                  { 'font-semibold': index === 0 }
                )}
              >
                <ReactMarkdown>{topic}</ReactMarkdown>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CourseDescription
