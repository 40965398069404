import React from "react";
import MDEditor from '@uiw/react-markdown-editor';
import { EditorView } from "@codemirror/view";

export const Editor = ({ value, className, onChange }: { value: string, className?: string, onChange: ((str:string) => void) }) => {
  return (
    <MDEditor
      className={className}
      value={value.length === 0 ? "\n\n\n\n" : value}
      extensions={[EditorView.lineWrapping]}
      height={value ? "auto" : "100"} 
      maxWidth="100%"
      onChange={(e) => onChange(e ?? '')}
      enablePreview={false}
    />
  );
}