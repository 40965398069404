import React, { useState, useCallback, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, Navigate } from 'react-router-dom'
import dayjs from 'dayjs'
import axios from 'axios'

import { useAppSelector } from '@data'
import Payment from './payment'
import RegistrationForm from './registration-form'
import Head from '../../head'
import Header from '../default/sections/header'
import Footer from '../default/sections/footer'
import Headline1 from '../default/sections/headline'
import courses from '../../../config/courses'
import LoginForm from '../../login-form'
import { SuccessModal } from './success-modal'

const Enrollment = () => {
  const { key, email, lang } = useParams<{ key: string; email?: string; lang?: string }>()
  const { t, i18n } = useTranslation(['translation', `courses/${key}`])
  const user = useAppSelector((s) => s.authentication.user)
  const isUserRegistered = Boolean(user.name)
  const [isRegistration, setRegistration] = useState(!email)
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false)
  const [enrollmentError, setEnrollmentError] = useState<string | null>(null)
  const [enrollmentStatus, setEnrollmentStatus] = useState<string>('')
  const [isAlreadyEnrolled, setIsAlreadyEnrolled] = useState(false)
  const [courseMongoId, setCourseMongoId] = useState<string | null>(null)
  
  useEffect(() => {
    if (lang && lang !== i18n.language) {
      i18n.changeLanguage(lang)
    }
  }, [lang, i18n])

  if (!key) {
    return <Navigate to="/courses" replace />
  }

  const course = courses.find(({ id }) => key === id)
  if (!course) return <Navigate to="/courses" replace />

  const isFree = Number(course.price?.default) === 0

  const steps = [
    { id: 1, name: t('courses.enrollment.steps.account'), status: isUserRegistered ? 'complete' : 'current' },
    { id: 2, name: t('courses.enrollment.steps.payment'), status: isUserRegistered ? 'current' : 'upcoming' }
  ]

  // Fetch course MongoDB _id when component mounts
  useEffect(() => {
    const fetchCourseId = async () => {
      try {
        const response = await axios.get(`/api/v1/courses/${key}`)
        if (response.data?.data?._id) {
          setCourseMongoId(response.data.data._id)
        }
      } catch (error) {
        console.error('Failed to fetch course details:', error)
      }
    }
    fetchCourseId()
  }, [key])

  useEffect(() => {
    if (isUserRegistered && user.enrolledCourses && courseMongoId) {
      const isEnrolled = user.enrolledCourses.includes(courseMongoId)
      if (isEnrolled) {
        setIsAlreadyEnrolled(true)
        setSuccessModalOpen(true)
      }
    }
  }, [isUserRegistered, user.enrolledCourses, courseMongoId])

  const getErrorMessage = useCallback((error: unknown): string => {
    if (axios.isAxiosError(error)) {
      const errorMessage = error.response?.data?.error
      switch (errorMessage) {
        case 'Invalid course ID':
          return t('courses.enrollment.error.invalidCourseId')
        case 'Course not found':
          return t('courses.enrollment.error.notFound')
        case 'Unauthorized':
          return t('courses.enrollment.error.unauthorized')
        default:
          return errorMessage || t('courses.enrollment.error.generic')
      }
    }
    return t('courses.enrollment.error.generic')
  }, [t])

  const handleEnrollment = useCallback(async () => {
    if (isAlreadyEnrolled) {
      setSuccessModalOpen(true)
      return
    }

    try {
      setEnrollmentError(null)
      setEnrollmentStatus('enrolling')
      const response = await axios.post(`/api/v1/courses/${key}/enroll`)
      setIsAlreadyEnrolled(response.data.alreadyEnrolled || false)
      setSuccessModalOpen(true)
      setEnrollmentStatus('success')
    } catch (error) {
      const errorMessage = getErrorMessage(error)
      setEnrollmentError(errorMessage)
      setEnrollmentStatus('error')
    }
  }, [key, getErrorMessage, isAlreadyEnrolled])

  useEffect(() => {
    if (isUserRegistered && !isAlreadyEnrolled) {
      handleEnrollment()
    }
  }, [isUserRegistered, handleEnrollment, isAlreadyEnrolled])

  if (isAlreadyEnrolled) {
    return (
      <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
        <Head
          title={`${t('courses.enrollment.title')} - ${t('name', { ns: `courses/${key}` })}`}
          keywords={t('seo.keywords', { ns: `courses/${key}` })}
          description={t('seo.description', { ns: `courses/${key}` })}
          subject={t('seo.subject', { ns: `courses/${key}` })}
          ogImage={course.image}
        />
        <div className="landing">
          <Header />
          <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
            <div className="bg-white shadow-lg rounded-xl px-8 py-10 mt-8 text-center border border-gray-100">
              <div className="mx-auto w-16 h-16 bg-teal-100 rounded-full flex items-center justify-center mb-6">
                <svg className="w-8 h-8 text-teal-600" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <title>Success Checkmark</title>
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                </svg>
              </div>
              <h2 className="text-3xl font-bold text-gray-900 mb-4">
                {t('courses.enrollment.alreadyEnrolled')}
              </h2>
              <p className="text-lg text-gray-600 mb-2">
                {t('courses.enrollment.alreadyEnrolledMessage', { courseName: course.name })}
              </p>
              <p className="text-lg text-gray-600">
                {t('courses.enrollment.accessCourse')}
              </p>
            </div>
          </main>
        </div>
        <Footer />
        <SuccessModal
          isOpen={isSuccessModalOpen}
          onClose={() => setSuccessModalOpen(false)}
          courseName={course.name}
          alreadyEnrolled={isAlreadyEnrolled}
        />
      </div>
    )
  }

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-50 to-white">
      <Head
        title={`${t('courses.enrollment.title')} - ${t('name', { ns: `courses/${key}` })}`}
        keywords={t('seo.keywords', { ns: `courses/${key}` })}
        description={t('seo.description', { ns: `courses/${key}` })}
        subject={t('seo.subject', { ns: `courses/${key}` })}
        ogImage={course.image}
      />
      
      <div>
        <Header />
        
        <main className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
          {/* Course Header */}
          <div className="bg-gradient-to-r from-teal-600 to-teal-500 shadow-lg rounded-xl px-8 py-10 mb-8 text-white">
            <div className="max-w-4xl">
              <h1 className="text-3xl font-bold mb-4">{t('name', { ns: `courses/${key}` })}</h1>
              <p className="text-lg text-teal-100 mb-6">{t('shortDescription', { ns: `courses/${key}` })}</p>
              {!isUserRegistered && !course.availableForStudentsBefore && (
                <div className="flex-shrink-0 flex">
                  <button
                    type="button"
                    className="inline-flex items-center px-6 py-3 border-2 border-white rounded-lg text-base font-medium text-white hover:bg-white hover:text-teal-600 transition-colors duration-200"
                    onClick={() => setRegistration((s) => !s)}
                  >
                    {!isRegistration ? t('courses.enrollment.newUser') : t('courses.enrollment.haveAccount')}
                  </button>
                </div>
              )}
            </div>
          </div>

          {/* Progress Steps - Only show for paid courses */}
          {!isFree && !course.availableForStudentsBefore && (
            <nav aria-label="Progress" className="mb-8">
              <ol className="flex items-center">
                {steps.map((step, stepIdx) => (
                  <li key={step.name} className={`relative ${stepIdx !== steps.length - 1 ? 'pr-8 w-full' : ''}`}>
                    <div className="flex items-center">
                      <div
                        className={`relative flex h-12 w-12 items-center justify-center rounded-full ${
                          step.status === 'complete'
                            ? 'bg-teal-600'
                            : step.status === 'current'
                            ? 'border-2 border-teal-600 bg-white'
                            : 'border-2 border-gray-300 bg-white'
                        }`}
                      >
                        {step.status === 'complete' ? (
                          <svg className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <title>Step Complete</title>
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                          </svg>
                        ) : (
                          <span
                            className={`text-lg font-semibold ${
                              step.status === 'current' ? 'text-teal-600' : 'text-gray-500'
                            }`}
                          >
                            {step.id}
                          </span>
                        )}
                      </div>
                      {stepIdx !== steps.length - 1 && (
                        <div
                          className={`absolute top-6 left-12 -ml-px h-0.5 w-full ${
                            step.status === 'complete' ? 'bg-teal-600' : 'bg-gray-300'
                          }`}
                        />
                      )}
                    </div>
                    <div className="mt-3">
                      <span className={`text-sm font-medium ${
                        step.status === 'complete'
                          ? 'text-teal-600'
                          : step.status === 'current'
                          ? 'text-teal-600'
                          : 'text-gray-500'
                      }`}>
                        {step.name}
                      </span>
                    </div>
                  </li>
                ))}
              </ol>
            </nav>
          )}

          {/* Course Details */}
          <div className="bg-white shadow-lg rounded-xl px-8 py-8 mb-8 border border-gray-100">
            <div className="prose max-w-none">
              <h2 className="text-2xl font-bold text-gray-900 mb-6">{t('courses.common.abountTheCourse')}</h2>
              <p className="text-lg text-gray-600 mb-8">{t('description', { ns: `courses/${key}` })}</p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="bg-gray-50 rounded-lg p-6">
                  <div className="flex items-center">
                    <svg className="h-6 w-6 text-teal-600 mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <title>Course Price</title>
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                    <div>
                      <span className="block text-sm text-gray-500">{t('courses.common.price')}</span>
                      <span className="text-xl font-bold text-gray-900">
                        {isFree ? t('courses.common.free') : `$${course.price.default}/${t('courses.common.month')}`}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 rounded-lg p-6">
                  <div className="flex items-center">
                    <svg className="h-6 w-6 text-teal-600 mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <title>Course Start Date</title>
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                    </svg>
                    <div>
                      <span className="block text-sm text-gray-500">{t('courses.common.launchingOn')}</span>
                      <span className="text-xl font-bold text-gray-900">
                        {dayjs(course.date).format('MMMM D, YYYY')}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Registration/Login Form or Enrollment Status */}
          <div className="bg-white shadow-lg rounded-xl px-8 py-8 border border-gray-100">
            {(!enrollmentError || isAlreadyEnrolled) && isUserRegistered && (
              <div className="mb-6 rounded-xl bg-teal-50 p-6 border border-teal-100">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <svg className="h-6 w-6 text-teal-400" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                      <title>Enrollment Success</title>
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                    </svg>
                  </div>
                  <div className="ml-4">
                    <h3 className="text-lg font-medium text-teal-800">
                      {t('courses.enrollment.alreadyEnrolled')}
                    </h3>
                    <div className="mt-2 text-base text-teal-700">
                      <p>{t('courses.enrollment.alreadyEnrolledMessage', { courseName: course.name })}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {enrollmentError && (
              <div className="mb-6 rounded-xl bg-red-50 p-6 border border-red-100">
                <div className="flex items-start">
                  <div className="flex-shrink-0">
                    <svg className="h-6 w-6 text-red-400" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                      <title>Enrollment Error</title>
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                    </svg>
                  </div>
                  <div className="ml-4">
                    <h3 className="text-lg font-medium text-red-800">{t('courses.enrollment.error.title')}</h3>
                    <div className="mt-2 text-base text-red-700">
                      <p>{enrollmentError}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {!isUserRegistered ? (
              course.availableForStudentsBefore ? (
                <div className="text-center p-8">
                  <div className="mx-auto w-16 h-16 bg-yellow-100 rounded-full flex items-center justify-center mb-6">
                    <svg className="w-8 h-8 text-yellow-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <title>Students Only Access</title>
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z" />
                    </svg>
                  </div>
                  <h2 className="text-2xl font-bold text-gray-900 mb-4">
                    {t('courses.enrollment.restrictedAccess')}
                  </h2>
                  <p className="text-lg text-gray-600">
                    {t('courses.enrollment.availableForStudentsOnly')}
                  </p>
                </div>
              ) : (
                <div className="max-w-3xl mx-auto">
                  <h2 className="text-2xl font-bold text-gray-900 mb-6">
                    {isRegistration ? t('courses.enrollment.createAccount') : t('courses.enrollment.signIn')}
                  </h2>
                  {isRegistration ? <RegistrationForm /> : <LoginForm />}
                </div>
              )
            ) : (
              <div className="text-center">
                {enrollmentStatus === 'enrolling' && (
                  <div className="flex justify-center items-center space-x-3">
                    <svg 
                      className="animate-spin h-6 w-6 text-teal-600" 
                      viewBox="0 0 24 24"
                      role="img"
                      aria-label="Loading spinner"
                    >
                      <title>Loading Spinner</title>
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" fill="none" />
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                    </svg>
                    <p className="text-lg text-gray-600">{t('courses.enrollment.processing')}</p>
                  </div>
                )}
              </div>
            )}
          </div>
        </main>
      </div>
      <Footer />
      <SuccessModal
        isOpen={isSuccessModalOpen}
        onClose={() => setSuccessModalOpen(false)}
        courseName={course.name}
        alreadyEnrolled={isAlreadyEnrolled}
      />
    </div>
  )
}

export default Enrollment
