import React, { useState, useEffect, useCallback } from 'react'
import axios from 'axios'

import dayjs from 'dayjs'
import { toastr } from 'react-redux-toastr'
import { useAppSelector } from '@data'

interface ClickArgs {
  amount: number
  date: string
  paymentNumber: number
  courseId: string
  totalPayments: number
}
interface PaymentMethodType{
  _id: string
  details: {
    amount: number
    created: number
    description: string
    charges: {
      data: {
        paid: boolean
        refunded: boolean
        receipt_url: string
      }[]
    }
  }
  courseId: string
}

const PaymentMethod = () => {
  const loginToken = useAppSelector((s) => s.authentication.token)
  const [payments, setPaymentHistory] = useState<PaymentMethodType[]>([])

  const loadData = useCallback(async () => {
    const { data: pm } = await axios.get('/api/v1/billing/stripe/payment-history')
    console.log(pm)
    setPaymentHistory(pm?.charges ?? [])
  }, [loginToken])

  useEffect(() => {
    loadData()
  }, [loadData])
  const [paymentIsProgress, setPaymentProgress] = useState(false)
  const onClickPaymentButton =
    ({ amount, date, paymentNumber, courseId, totalPayments } : ClickArgs) =>
    async () => {
      if (paymentIsProgress) return
      setPaymentProgress(true)
      const {
        data: { charges, message, status }
      } = await axios.post('/api/v1/billing/stripe/make-payment', {
        amount,
        date,
        paymentNumber,
        courseId,
        totalPayments
      })
      setPaymentHistory(charges ?? [])
      setPaymentProgress(false)

      const foo = status === 'error' ? toastr.error : toastr.success
      foo(status, message)
    }

  const paymentsIsDone = payments.filter((it) => {
    const isPaid = it.details.charges.data.some((d) => d.paid)
    const isRefunded = it.details.charges.data.some((d) => d.refunded)
    return isPaid && !isRefunded
  })

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 dark:border-gray-700 sm:rounded-lg">
            <div className="bg-white dark:bg-gray-800 shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                <p className="mt-1 max-w-2xl text-sm text-gray-500 dark:text-gray-400">Your current payment history</p>
              </div>
              <div className="border-t border-gray-200 dark:border-gray-700 px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200 dark:sm:divide-gray-700">
                  <div className="flex flex-col">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                      <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
                        <div className="shadow overflow-hidden border-b border-gray-200 dark:border-gray-700 sm:rounded-lg">
                          <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                            <thead className="bg-gray-50 dark:bg-gray-900">
                              <tr>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider"
                                >
                                  Id
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider"
                                >
                                  Amount
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider"
                                >
                                  Description
                                </th>
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider"
                                >
                                  Role
                                </th>{' '}
                                <th
                                  scope="col"
                                  className="px-6 py-3 text-left text-xs font-medium text-gray-500 dark:text-gray-400 uppercase tracking-wider"
                                >
                                  Status
                                </th>
                                <th scope="col" className="relative px-6 py-3">
                                  <span className="sr-only">Receipt</span>
                                </th>
                              </tr>
                            </thead>
                            <tbody className="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                              {payments.map((it, index) => {
                                const isPaid = it.details.charges.data.some((d) => d.paid)
                                const isRefunded = it.details.charges.data.some((d) => d.refunded)
                                const receiptUrl = it.details.charges.data.find(
                                  (d) => d.paid
                                )?.receipt_url
                                return (
                                  <tr
                                    key={it._id}
                                    className="bg-gray-50 dark:bg-gray-800"
                                  >
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-200">
                                      {index}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-200">
                                      {it.details.amount / 100} USD
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-200">
                                      {it.details.description}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-200">
                                      {isRefunded && 'Refunded'}
                                      {!isRefunded && isPaid && 'Paid'}
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-200">
                                      {dayjs(new Date(it.details.created * 1000)).format(
                                        'DD/MM/YYYY	hh:mma'
                                      )}
                                    </td>

                                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                      {receiptUrl && (
                                        <a
                                          target="_blank"
                                          rel="noreferrer"
                                          href={receiptUrl}
                                          className="text-teal-600 hover:text-teal-500 dark:text-teal-400 dark:hover:text-teal-300"
                                        >
                                          Receipt
                                        </a>
                                      )}
                                    </td>
                                  </tr>
                                )
                              })}
                              {paymentsIsDone.length !== 0 &&
                                new Array(9 - paymentsIsDone.length).fill(null).map((it, index) => {
                                  const lastPayment = paymentsIsDone[paymentsIsDone.length - 1]
                                  const firstPayment = paymentsIsDone[0]
                                  const currPaymentIndex = paymentsIsDone.length + index
                                  return (
                                    <tr
                                      key={paymentsIsDone.length + index}
                                      className="bg-gray-50 dark:bg-gray-800"
                                    >
                                      <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900 dark:text-gray-200">
                                        {paymentsIsDone.length + index}
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-200">
                                        {currPaymentIndex !== 1
                                          ? (firstPayment.details.amount / 100) * 2
                                          : firstPayment.details.amount / 100}{' '}
                                        USD
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-200">
                                        &nbsp;
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-200">
                                        Not Paid
                                      </td>
                                      <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-900 dark:text-gray-200">
                                        {dayjs(new Date(lastPayment.details.created * 1000))
                                          .add(index + 1, 'month')
                                          .format('DD/MM/YYYY	hh:mma')}
                                      </td>

                                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                        {index === 0 && (
                                          <button
                                            onClick={onClickPaymentButton({
                                              amount:
                                                currPaymentIndex !== 1
                                                  ? firstPayment.details.amount * 2
                                                  : firstPayment.details.amount,
                                              date: dayjs(
                                                new Date(lastPayment.details.created * 1000)
                                              )
                                                .add(index + 1, 'month')
                                                .format('DD/MM/YYYY	hh:mma'),
                                              paymentNumber: currPaymentIndex,
                                              courseId: lastPayment.courseId,
                                              totalPayments: 9
                                            })}
                                            type="button"
                                            className="text-teal-600 hover:text-teal-500 dark:text-teal-400 dark:hover:text-teal-300"
                                          >
                                            {paymentIsProgress ? 'In progress' : 'Pay invoice'}
                                          </button>
                                        )}
                                      </td>
                                    </tr>
                                  )
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PaymentMethod
