import { useRef, type FC } from 'react'
import { NativeTypes } from 'react-dnd-html5-backend'
import { useDrop } from 'react-dnd'

interface DropItem {
  files: File[]
}

interface TargetBoxProps {
  onDrop: (item: { files: File[] }) => void
  description?: string
}

const TargetBox: FC<TargetBoxProps> = ({ onDrop, description = 'PDF up to 2MB' }) => {
  const dropRef = useRef<HTMLDivElement>(null)

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop(item: DropItem) {
      onDrop(item)
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop()
    })
  })

  // Combine refs
  const setRefs = (element: HTMLDivElement | null) => {
    dropRef.current = element
    drop(element)
  }

  const isActive = canDrop && isOver

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files?.length) {
      onDrop({ files: Array.from(e.target.files) })
    }
  }

  return (
    <div ref={setRefs} className="mt-4 sm:mt-0 sm:col-span-2">
      <div className="flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 dark:border-gray-600 border-dashed rounded-md bg-white dark:bg-gray-800">
        <div className="space-y-3 text-center">
          <svg
            className="mx-auto h-12 w-12 text-gray-400 dark:text-gray-500"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 48 48"
            aria-hidden="true"
          >
            <path
              d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
              strokeWidth={2}
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <div className="flex items-center justify-center space-x-1 text-sm text-gray-600 dark:text-gray-400">
            <label
              htmlFor="file-upload"
              className="relative cursor-pointer font-medium text-teal-600 dark:text-teal-500 hover:text-teal-500 dark:hover:text-teal-400 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-teal-500 dark:focus-within:ring-offset-gray-800"
            >
              Upload your file
              <input
                id="file-upload"
                name="file-upload"
                type="file"
                className="sr-only"
                onChange={handleFileChange}
              />
            </label>
            {isActive ? (
              <span className="dark:text-gray-300">Release to drop</span>
            ) : (
              <span className="dark:text-gray-300">or drag and drop</span>
            )}
          </div>
          <p className="text-xs text-gray-500 dark:text-gray-400">{description}</p>
        </div>
      </div>
    </div>
  )
}

export default TargetBox
