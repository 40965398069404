import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { useAppSelector } from '@data'
import { CourseCard } from '../landings/course-card'
import Loader from '../common/loader'

interface CoursePrice {
  default: number
  uk: number
  ru: number
  en: number
}

interface CourseData {
  _id: string
  courseId: string
  name: string
  description: string
  startDate?: string
  endDate?: string
  image?: string
  price?: CoursePrice
}

interface CourseCardData {
  id: string
  name: string
  registrationAllowed: boolean
  image: string
  lngNs: string
  date: Date
  price: CoursePrice
}

const defaultPrice: CoursePrice = {
  default: 0,
  uk: 0,
  ru: 0,
  en: 0
}

const EnrolledCourses = () => {
  const { t } = useTranslation()
  const [courses, setCourses] = useState<CourseData[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState<string | null>(null)
  const user = useAppSelector((state) => state.authentication.user)

  useEffect(() => {
    const fetchEnrolledCourses = async () => {
      try {
        setIsLoading(true)
        const response = await axios.get<{data: CourseData[]}>('/api/v1/courses/list')
        // Filter courses that the user is enrolled in

        const enrolledCourses = response.data.data.filter((course) => 
          user.enrolledCourses?.includes(course._id)
        )
        setCourses(enrolledCourses)
      } catch (err) {
        setError('Failed to load enrolled courses')
        console.error('Error fetching enrolled courses:', err)
      } finally {
        setIsLoading(false)
      }
    }

    fetchEnrolledCourses()
  }, [user.enrolledCourses])

  if (isLoading) {
    return <Loader />
  }

  if (error) {
    return (
      <div className="rounded-md bg-red-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg
              className="h-5 w-5 text-red-400"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">Error</h3>
            <div className="mt-2 text-sm text-red-700">
              <p>{error}</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if (courses.length === 0) {
    return (
      <div className="text-center py-12">
        <h3 className="text-lg font-medium text-gray-900 mb-2">
          {t('courses.enrolled.noCourses')}
        </h3>
        <p className="text-gray-500">
          {t('courses.enrolled.browseCourses')}
        </p>
      </div>
    )
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h2 className="text-2xl font-bold text-gray-900 mb-8">
        {t('courses.enrolled.title')}
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {courses.map((course) => (
          <CourseCard
            key={course._id}
            course={{
              id: course.courseId,
              name: course.name,
              registrationAllowed: false, // Course exists but not ready for registration
              image: course.image || '/images/course-placeholder.jpg',
              lngNs: `courses.${course.courseId}`,
              date: course.startDate ? new Date(course.startDate) : new Date(),
              price: course.price || defaultPrice,
              startDate: course.startDate ?? '',
              endDate: course.endDate  ?? '',
            }}
            toggleModal={(courseId: string, registrationAllowed: boolean) => () => {
              // No-op since course is not ready yet
            }}
            userRegistrationDate={user.createdAt}
          />
        ))}
      </div>
      <div className="mt-8 p-4 bg-yellow-50 rounded-lg">
        <p className="text-sm text-yellow-800">
          {t('courses.enrolled.comingSoon')}
        </p>
      </div>
    </div>
  )
}

export default EnrolledCourses
