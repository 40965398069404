import axios from 'axios'

function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}
const defaults = {
  role: 'user',
  enrolledCourses: [],
  computerType: '',
  achievements: []
}

interface IRegistrationSchema {
  [key: string]: {
    validator?: (value: string, obj?: any) => Promise<any>
    options?: string[]
    large?: boolean
    type?: string
  }
}

const registrationSchema: IRegistrationSchema = {
  promoCode: {},
  email: {
    validator: async (email: string) => {
      if (!validateEmail(email)) {
        return {
          status: false,
          message: 'registration.email.error.invalid_email'
        }
      }
      const {
        data: { status }
      } = await axios.post('/api/v1/registration/validate/email', {
        email
      })
      if (status) {
        return {
          status: false,
          message: 'registration.email.error.user_already_registered'
        }
      }
      return {
        status: true
      }
    }
  },
  name: {
    validator: async (name: string) => {
      if (name?.length < 2) {
        return {
          status: false,
          message: 'registration.name.error.name_is_too_short'
        }
      }
      return {
        status: true
      }
    }
  },
  sex: {
    options: ['M', 'F']
  },
  job: {},
  hobby: {
    large: true
  },
  motivation: {
    large: true
  },
  englishLevel: {
    options: ['A1-A2', 'B1', 'B2', 'C1', 'C2']
  },
  ideas: {
    large: true
  },
  os: {
    options: ['MACOS', 'Linux', 'Windows']
  },
  phoneOs: {
    options: ['Android', 'OSX']
  },
  canDesign: {},
  knowledgeLevel: {},
  payRate: {
    validator: async (code: string) => {
      if (Number.isInteger(+code)) {
        return {
          status: true,
          field: 'payRate',
          value: +code
        }
      }
      return {
        status: false,
        message: 'registration.payRate.error.not_a_number'
      }
    }
  },
  location: {},
  age: {
    validator: async (code: string) => {
      if (Number.isInteger(+code)) {
        return {
          status: true,
          field: 'age',
          value: +code
        }
      }
      return {
        status: false,
        message: 'registration.age.error.not_a_number'
      }
    }
  },
  password: {
    type: 'password',
    validator: async (code: string) => {
      if (!code || code?.length < 8) {
        return {
          status: false,
          message: 'registration.password.error.min_length'
        }
      }
      if (!/(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/.test(code)) {
        return {
          status: false,
          message: 'registration.password.error.password_complexity'
        }
      }
      return {
        status: true
      }
    }
  },
  repeatPassword: {
    type: 'password',

    validator: async (code: string, obj: any) => {
      if (obj.password === code) {
        return {
          status: true
        }
      }
      return {
        status: false,
        message: 'registration.repeatPasword.error'
      }
    }
  }
}

export { registrationSchema, defaults }
