import React from 'react'

const AdminControls = ({
  questionsLength,
  adminEditMode,
  isDescriptionDirty,
  onToggleEditMode,
  onSaveDescription
}: {
    questionsLength: number
    adminEditMode: boolean
    isDescriptionDirty: boolean
    onToggleEditMode: () => void
    onSaveDescription: () => void
}) => (
  <div className="flex flex-wrap items-center gap-4 py-3">
    <div className="flex items-center gap-2 text-sm text-gray-500 dark:text-gray-400">
      <svg 
        className="w-4 h-4" 
        fill="none" 
        stroke="currentColor" 
        viewBox="0 0 24 24"
        aria-hidden="true"
      >
        <path 
          strokeLinecap="round" 
          strokeLinejoin="round" 
          strokeWidth={1.5}
          d="M8.228 9c.549-1.165 2.03-2 3.772-2 2.21 0 4 1.343 4 3 0 1.4-1.278 2.575-3.006 2.907-.542.104-.994.54-.994 1.093m0 3h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" 
        />
      </svg>
      <span>Unanswered: {questionsLength}</span>
    </div>

    <button 
      type="button"
      onClick={onToggleEditMode} 
      className="flex items-center gap-2 px-3 py-1.5 text-sm rounded-md bg-gray-100 hover:bg-gray-200 dark:bg-gray-800 dark:hover:bg-gray-700 text-gray-700 dark:text-gray-300 transition-colors"
      aria-label={adminEditMode ? 'Switch to view mode' : 'Switch to edit mode'}
    >
      <svg 
        className="w-4 h-4" 
        fill="none" 
        stroke="currentColor" 
        viewBox="0 0 24 24"
        aria-hidden="true"
      >
        {adminEditMode ? (
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth={1.5}
            d="M15 12a3 3 0 11-6 0 3 3 0 016 0z M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" 
          />
        ) : (
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth={1.5}
            d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" 
          />
        )}
      </svg>
      <span>{adminEditMode ? 'View Mode' : 'Edit Mode'}</span>
    </button>

    {isDescriptionDirty && (
      <button
        type="button"
        onClick={onSaveDescription}
        className="flex items-center gap-2 px-3 py-1.5 text-sm font-medium rounded-md bg-teal-50 hover:bg-teal-100 dark:bg-teal-900/30 dark:hover:bg-teal-900/50 text-teal-600 dark:text-teal-400 transition-colors group"
        aria-label="Save changes"
      >
        <svg 
          className="w-4 h-4 transition-transform group-hover:scale-110" 
          fill="none" 
          stroke="currentColor" 
          viewBox="0 0 24 24"
          aria-hidden="true"
        >
          <path 
            strokeLinecap="round" 
            strokeLinejoin="round" 
            strokeWidth={1.5}
            d="M8 7H5a2 2 0 00-2 2v9a2 2 0 002 2h14a2 2 0 002-2V9a2 2 0 00-2-2h-3m-1 4l-3 3m0 0l-3-3m3 3V4" 
          />
        </svg>
        <span>Save Changes</span>
      </button>
    )}
  </div>
)

export default AdminControls
