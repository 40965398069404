import React from 'react'

import JSTasks from './js-tasks'
import WebTasks from './web-tasks'


interface Props {
  homework: any
  homeworkStatuses: any
  homeworkId: string
}
const Homework = (props: Props) => {
  const testData = props.homework
  const results = props.homeworkStatuses
  const jstasks = [...(testData?.jsTasks ?? [])]
  jstasks.sort((a, b) => a.order - b.order)
  const webtasks = [...(testData?.webTasks ?? [])]
  webtasks.sort((a, b) => a.order - b.order)
  if (!testData) {
    return <div>Loading...</div>
  }
  return (
    <div>
      <div className="pb-5 border-b border-gray-200">
        <h3 className="text-lg leading-6 font-medium text-teal-700">Homework</h3>
      </div>
      {testData?.type === 'javascript' && (
        <JSTasks
          tasks={jstasks}
          results={results}
          testDataId={testData.id}
          homeworkId={props.homeworkId}
        />
      )}
      {testData?.type === 'jest' && (
        <JSTasks
          tasks={jstasks}
          results={results}
          testDataId={testData.id}
          homeworkId={props.homeworkId}
        />
      )}
      {testData?.type === 'web' && (
        <WebTasks
          tasks={webtasks}
          results={results}
          testDataId={testData.id}
          homeworkId={props.homeworkId}
        />
      )}
    </div>
  )
}

export default React.memo(Homework)
