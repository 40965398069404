/* eslint-disable max-len */
import React from 'react'

import { useTranslation } from 'react-i18next'

import Head from '../head'
import Header from './default/sections/header'
import Footer from './default/sections/footer'

/* eslint max-len: ["error", { "code": 150 }] */

const terms = `TERMS OF SERVICE
https://skillcrucial.com/en/
Date of last revision: 06/07/2020
WELCOME TO SKILLCRUCIAL
Skillcrucial’s mission is to improve lives through learning. We provide high quality education for people outside of the IT industry. We believe that everyone can start a career in IT at a high skill level., broad knowledge and very good work ethics. We believe in open-source, that is why our contribution to the dev/open-source community is a part of our education. We teach Javascript, React, React Native, Node, Mongo Database, PWA, Blockchain, Solidity, Go, etc.
CHANGE TO TERMS OF SERVICE
We provide the services (described below) to you through our website located at https://skillcrucial.com/en/ (the "site"). We reserve the right, at our sole discretion, to change or modify portions of these Terms of Service at any time. If we do this, we will post the changes on this page and will indicate at the top of this page the date these terms were last revised.
We will also notify you, either through the Services user interface, in an email notification or through other reasonable means. Any such changes will become effective no earlier than fourteen (14) days after they are posted, except that changes addressing new functions of the Service after the date any such change becomes effective immediately. Your continued use of the Service after the date any such changes become effective constitutes your acceptance of the new Terms of Service.
In addition, when using our services, you will be subject to any additional terms applicable to such as our privacy policy and disclaimers. All such terms hereby incorporated by reference to these Terms of Service.

ACCESS AND USE OF THE SERVICE
SERVICE DESCRIPTION. The service is designed to provide online classes.

USE DESCRIPTION.
All content viewed and provided through the website by Skillcrucial are solely for your personal and non commercial use. With your subscription of the course, we grant you a limited, non-exclusive, non transferable, licence to access the content and view your courses for educational purposes only.
Except for the foregoing limited license, no right, no title or interest shall be transferred to you. You agree not to use the services for commercial purposes.

YOUR REGISTRATION OBLIGATIONS.
You may be required to register with us in order to access and use certain features of the Service. If you choose to register for the Service, you agree to provide and maintain true, accurate, current and complete information about yourself as prompted by the Service's registration form.
Registration data and certain other information about you are governed by our Privacy Policy. If you are under 13 years of age, you are not authorized to use the Service, with or without registering

MEMBER ACCOUNT PASSWORD
You may never use another's account, and you may not provide another person with the username and password to access your account. You are fully responsible for any and all activities that occur under your password or account. You agree to
immediately notify Skillcrucial of any unauthorized use of your password or account or any other breach of security, and
ensure that you exit from your account at the end of each session when accessing the Service.
Skillcrucial will not be liable for any loss or damage arising from your failure to comply with this Section.

MODIFICATIONS TO SERVICE
Skillcrucial reserves the right to modify or discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice.
You agree that Skillcrucial will not be liable to you or to any third party for any modification, suspension or discontinuance of the Service.
We have no obligation to retain any of your Account or Submitted Content for any period of time beyond what may be required by applicable Law.

GENERAL PRACTICES REGARDING USE AND STORAGE
You acknowledge that Skillcrucial may establish general practices and limits concerning use of Service, including without limitation the maximum period of time the data or other content will be retained by the Service and Maximum storage space that will be allotted on Skillcrucial servers on your behalf.
You agree that Skillcrucial  has no responsibility or liability for the deletion or failure to store any data or other content maintained or uploaded by the Service. You acknowledge that Skillcrucial  reserves the right to terminate accounts that are inactive for an extended period of time.
You further acknowledge that Skillcrucial  reserves the right to change these general practices and limits at any time, in its sole discretion, with or without notice.

REFUND PROCESS
Refund is available for  1 payment made within one week.
To request a refund or to ask a question regarding the refunds please contact support at info@skillcrucial.com

CONDITIONS OF USE/ USER CONDUCT:
You are solely responsible for all code, video, images, information, data, text, software, music, sound, photographs, graphics, messages or other materials (content) that you upload, post, publish or display (hereinafter, upload) or email or otherwise use via the Service.
The following are examples of the kind of content and/or use that is illegal or prohibited by Skillcrucial. Skillcrucial  reserves the right to investigate and take appropriate legal action against anyone who, in Skillcrucial’s sole discretion, violates this provision, including without limitation, removing the offending content from the Service, suspending or terminating the account of such violators and reporting you to the law enforcement authorities. You agree to not use the Service to:email or otherwise upload any content that:
infringes any intellectual property or other proprietary rights of any party;
you do not have a right to upload under any law or under contractual or fiduciary relationships;
you do not have a right to upload under any law or under contractual or fiduciary relationships
contains software viruses or any other computer code, les or programs designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications
poses or creates a privacy or security risk to any person
constitutes unsolicited or unauthorized advertising, promotional materials, commercial activities and/or sales, junk mail, spam, chain letters, pyramid schemes, contests, sweepstakes, or any other form of solicitation;
is unlawful, harmful, threatening, abusive, harassing, tortious, excessively violent, defamatory, vulgar, obscene, pornographic, libelous, invasive of another’s privacy, hateful racially, ethnically or otherwise objectionable;
or in the sole judgment of Skillcrucial, is objectionable or which restricts or inhibits any other person from using or enjoying the Service, or which may expose SkillsCrucial for its users to any harm or liability of any type; interfere with or disrupt the Service or servers or networks connected to the Service, or disobey any requirements, procedures, policies or regulations of networks connected to the Service

FEES:
To the extent the Service or any portion thereof is made available for any fee, you will be required to select a payment plan and provide Skillcrucial information regarding your credit card or other payment instrument.
You represent and warrant to Skillcrucial that such information is true and that you are authorized to use the payment instrument. You will promptly update your account information with any changes (for example, a change in your billing address or credit card expiration date) that may occur.
You agree to pay Skillcrucial the amount that is specified in the payment plan in accordance with the terms of such plan and this Terms of Service. You hereby authorize Skillcrucial to bill your payment instrument in advance in accordance with the terms of the applicable payment plan until you terminate your account, and you further agree to pay any charges so incurred.

COMMERCIAL USE:
Unless otherwise expressly authorized herein or in the Service, you agree not to display, distribute, license, exploit, transfer, or upload for any commercial purposes, any portion of the Service, use of the Service, or access to the Service.

INTELLECTUAL PROPERTY RIGHTS
Service Content, Software and Trademarks:You acknowledge and agree that the Service may contain content or features (Service Content) that are protected by copyright, patent, trademark, trade secret or other proprietary rights and laws.
Except as expressly authorized by Skillcrucial  , you agree not to modify, copy, frame, scrape, rent, lease, loan, sell, distribute or create derivative works based on the Service or the Service Content, in whole or in part, except that the foregoing does not apply to your own User Content (as defined below) that you legally upload to the Service. In connection with your use of the Service you will not engage in or use any data mining, robots, scraping or similar data gathering or extraction methods.
Any use of the Service or the Service Content other than as specifically authorized herein is strictly prohibited. The technology and software underlying the Service or distributed in connection therewith are the property of Skillcrucial , our affiliates and our partners (the Software).
You agree not to copy, modify, create a derivative work of, reverse engineer, reverse assemble or otherwise attempt to discover any source code, sell, assign, sublicense, or otherwise transfer any right in the Software. Any rights not expressly granted herein are reserved by Skillcrucial.


COPYRIGHT COMPLAINTS
Respects the intellectual property of others, and we ask our users to do the same. If you believe that your work has been copied in a way that constitutes copyright infringement, or that your intellectual property rights have been otherwise violated, you should notify Skillcrucial  of your infringement claim in accordance with the procedure set forth below. You may also contact us by email at info@skillcrucial.com

INDEMNITY AND RELEASE
You agree to release, indemnify and hold SkillCrucial and its affiliates and their officers, employees, directors and agent harmless from any and all losses, damages, expenses, including reasonable attorney's fees, rights, claims, actions of any kind and injury (including death) arising out of or relating to your use of the Service, any User Content, your connection to the Service, your violation of these Terms of Service or your violation of any rights of another.

LIMITATION OF LIABILITY
YOU EXPRESSLY UNDERSTAND AND AGREE THAT MASTERCLASS WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY DAMAGES, OR DAMAGES FOR LOSS OF PROFITS INCLUDING BUT NOT LIMITED TO, DAMAGES FOR LOSS OF GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF SKILLCRUCIAL HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES), WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, RESULTING FROM:
THE USE OR THE INABILITY TO USE THE SERVICE
THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO THROUGH OR FROM THE SERVICE
UNAUTHORIZED ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA;
STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SERVICE; OR
ANY OTHER MATTER RELATING TO THE SERVICE. IN NO EVENT WILL MASTERCLASS’ TOTAL LIABILITY TO YOU FOR ALL DAMAGES, LOSSES OR CAUSES OF ACTION EXCEED THE AMOUNT YOU HAVE PAID THE COURSE IN THE LAST SIX (6) MONTHS, OR, IF GREATER, ONE HUNDRED DOLLARS ($100).


TERMINATION
You agree that Skillcrucial, in its sole discretion, may suspend or terminate your account (or any part thereof) or use of the Service and remove and discard any content within the Service, for any reason, including, without limitation, for lack of use or if Skillcrucial believes that you have violated or acted inconsistently with the letter or spirit of these Terms of Service.

GENERAL
These Terms of Service constitute the entire agreement between you and Skillcrucial and govern your use of the Service, superseding any prior agreements between you and Skillcrucial with respect to the Service. You also may be subject to additional terms and conditions that may apply when you use affiliate or third party services, third party content or third party software.
These Terms of Service will be governed by the laws of Singapore.



`
const Terms = () => {
  const { t } = useTranslation()

  return (
    <div className="default-landing">
      <Head
        title={t('faq.seo.title')}
        keywords={t('faq.seo.keywords')}
        description={t('faq.seo.description')}
        subject={t('faq.seo.subject')}
      />
      <div id="page-wrapper ">
        <Header />
        <div className="content text-lg">
          <section className="full-section min-h-screen mb-0" style={{ minHeight: '900px' }}>
            <div className="px-2 md:px-10 flex flex-col flex align-center justify-center  p-5">
              <h1 className="mb-5 mp-5">
                Standard Terms for the Purchase of Online and Taught Courses
              </h1>
              {terms.split('\n').map((it) => {
                return (
                  <p className="text-sm" key={it}>
                    {it}
                  </p>
                )
              })}
            </div>
          </section>
        </div>
        <Footer />
      </div>
      <a id="go-top">
        <i className="miu-icon-circle_arrow-up_glyph" />
      </a>
    </div>
  )
}

export default Terms
