import React from 'react'
import WebTask from './web-task'

interface Props {
  tasks: any[]
  results: any
  testDataId: string
  homeworkId: string
}

const WebTasks = ({ tasks, results, testDataId }: Props) => {
  // let lastIndex = 0
  // let maxOpened = 0
  return <>{
    (tasks || [])
      // .filter((it, index) => {
      //   if (results[it.id] && results[it.id].result.status === 'ok') {
      //     lastIndex = index + 2
      //   }
      //   if (!results[it.id]) {
      //     maxOpened += 1
      //   }
      //   return (
      //     (results[it.id] && results[it.id].result.status === 'ok') ||
      //     ((index <= lastIndex || index <= 1) && maxOpened <= 2)
      //   )
      // })
      .map((task, index) => {
        return (
          <WebTask
            task={task}
            testDataId={testDataId}
            key={task.id}
            index={index}
            // homeworkId={homeworkId}
            result={results?.[task.id] || { result: {} }}
          />
        )
      })
    }</>
}

export default WebTasks
