import React from 'react'
import SocialProfile from '../homework/connect-social'

// Import the CSS file for social buttons styling
import '../../assets/css/bootstrap-social.css'

/**
 * Social component
 *
 * This component serves as a wrapper for the SocialProfile component,
 * providing a consistent layout and styling for the social profile section.
 */
const Social = () => {
  return (
    <div className="bg-white dark:bg-gray-800 px-4 py-5 border-b border-gray-200 dark:border-gray-700 sm:px-6 transition-colors duration-200">
      <header className="pb-5">
        <div className="-ml-2 -mt-2 flex flex-wrap items-baseline">
          <h3 className="ml-2 mt-2 text-lg leading-6 font-medium text-gray-900 dark:text-white">
            My social profile
          </h3>
        </div>
      </header>
      <main>
        <SocialProfile />
      </main>
    </div>
  )
}

export default Social
