import React from 'react'
import { Tooltip } from 'components/ui/tooltip'

const achievementDetails = {
  api_warrior_lvl_1: {
    title: 'Бравый API рекрут',
    description: 'Вы выполнили задание второй недели по API',
    image: '/images/achievements/api_recruit.png'
  },
  intro_javascript_1: {
    title: 'Javascript наемник',
    description:
      'Вы выполнили задание второй недели по JS и готовы создавать ловушки дял диких зверей благодаря JS синтаксису',
    image: '/images/achievements/javascript_rookie.png'
  },
  basic_data_manipulations_1: {
    title: 'Начинающий алхимик данных',
    description:
      'Вы умеете управлять потоками информации и манипулировать реальностью... ну почти...',
    image: '/images/achievements/data_alchemist.png'
  },
  basic_data_manipulations_2: {
    title: 'Продвинутый падаван по обработке данных',
    description:
      'Вы умеете управлять потоками информации и решать свои задачи в угоду великому Богу Данных',
    image: '/images/achievements/data_manipulator.png'
  },
  basic_сlojure: {
    title: 'Начинающий алхимик данных',
    description: 'Вы умеете прятать данные в самых укромных уголках джаваскрипта',
    image: '/images/achievements/closure_padawan.png'
  },
  dom_manipulator: {
    title: 'Манипулятор Дом',
    description:
      'Ты владеешь базовой силой манипуляций неживых объектов. Тебя впереди ждет дракон e2e.',
    image: '/images/achievements/dom_master.png'
  },
  interview_js_tasks: {
    title: 'Базовые задачи для Интервью',
    description: 'Ты все сможешь!',
    image: '/images/achievements/jsut_another_interview.png'
  },
  promise_jedi: {
    title: 'Падаван дал обещание стать джедаем',
    description: 'И станет! Promsie.resolve("Jedi")',
    image: '/images/achievements/promise_jedi.png'
  },

  interview_week_0: {
    title: 'Interview week 0',
    description: 'Interview week 0',
    image: '/images/achievements/interview_week_0.png'
  },
  interview_week_1: {
    title: 'Interview week 1',
    description: 'Interview week 1',
    image: '/images/achievements/interview_week_1.png'
  },
  interview_week_2: {
    title: 'Interview week 2',
    description: 'Interview week 2',
    image: '/images/achievements/interview_week_2.png'
  },
  interview_week_3: {
    title: 'Interview week 3',
    description: 'Interview week 3',
    image: '/images/achievements/interview_week_3.png'
  },
  interview_week_4: {
    title: 'Interview week 4',
    description: 'Interview week 4',
    image: '/images/achievements/interview_week_4.png'
  },
  interview_week_5: {
    title: 'Interview week 5',
    description: 'Interview week 5',
    image: '/images/achievements/interview_week_5.png'
  },
  interview_week_6: {
    title: 'Interview week 6',
    description: 'Interview week 6',
    image: '/images/achievements/interview_week_6.png'
  },
  interview_week_7: {
    title: 'Interview week 7',
    description: 'Interview week 7',
    image: '/images/achievements/interview_week_7.png'
  },
  interview_week_8: {
    title: 'Interview week 8',
    description: 'Interview week 8',
    image: '/images/achievements/interview_week_8.png'
  },
  interview_week_9: {
    title: 'Interview week 9',
    description: 'Interview week 9',
    image: '/images/achievements/interview_week_9.png'
  },
  interview_week_10: {
    title: 'Interview week 10',
    description: 'Interview week 10',
    image: '/images/achievements/interview_week_10.png'
  },
  interview_week_11: {
    title: 'Interview week 11',
    description: 'Interview week 11',
    image: '/images/achievements/interview_week_11.png'
  },
  interview_week_12: {
    title: 'Interview week 12',
    description: 'Interview week 12',
    image: '/images/achievements/interview_week_12.png'
  },
  interview_week_13: {
    title: 'Interview week 13',
    description: 'Interview week 13',
    image: '/images/achievements/interview_week_13.png'
  },
  interview_week_14: {
    title: 'Interview week 14',
    description: 'Interview week 14',
    image: '/images/achievements/interview_week_14.png'
  },
  'routing-master': {
    title: 'Routing Master Server',
    description: 'Routing Master Server',
    image: '/images/achievements/route_me_server.png'
  },
  'first-react-task': {
    title: 'Routing Master',
    description: 'Routing Master React',
    image: '/images/achievements/route_me_react.png'
  },
  flexible: {
    title: 'Flexible',
    description: 'Flex master',
    image: '/images/achievements/flexible.png'
  },
  griddy: {
    title: 'Griddy',
    description: 'Grid master',
    image: '/images/achievements/griddy.png'
  },
  performance_guru: {
    title: 'Performance guru',
    description: 'Performance guru',
    image: '/images/achievements/performance_guru.png'
  },
  redux: {
    title: 'Redux',
    description: 'Redux is dead long live the Redux',
    image: '/images/achievements/redux.png'
  },
  websockets: {
    title: 'Websockets Interactive master',
    description: 'Websockets Interactive master',
    image: '/images/achievements/websockets.png'
  },
  webpack_it: {
    title: 'Webpack it!',
    description: 'Webpack it',
    image: '/images/achievements/webpack_it.png'
  },
  refactorer: {
    title: 'Refactorer!',
    description: 'Refactorer. Almost architect',
    image: '/images/achievements/refactorer.png'
  },
  just_another_interview: {
    title: 'Just Another Interview Passed',
    description: 'Just Another Interview Passed',
    image: '/images/achievements/just_another_interview.png'
  },
  github_explorer: {
    title: 'Github Explorer',
    description: 'First Real App is Done',
    image: '/images/achievements/github_explorer.png'
  },
  mock_interview_passed: {
    title: 'Mock Interview Passed',
    description: 'Mock Interview Passed',
    image: '/images/achievements/mock_interview_passed.png'
  },
  mongo_databoom_easy: {
    title: 'Mongo Data Boom Easy',
    description: 'Mongo Data Boom Easy',
    image: '/images/achievements/mongo_databoom_easy.png'
  },
  mongo_databoom_advanced: {
    title: 'Mongo Data Boom Advanced',
    description: 'Mongo Data Boom Advanced',
    image: '/images/achievements/mongo_databoom_advanced.png'
  },
  sql_databoom_easy: {
    title: 'sql Data Boom Easy',
    description: 'sql Data Boom Easy',
    image: '/images/achievements/sql_databoom_easy.png'
  },
  sql_databoom_advanced: {
    title: 'sql Data Boom Advanced',
    description: 'sql Data Boom Advanced',
    image: '/images/achievements/sql_databoom_advanced.png'
  },
  typescript_poet: {
    title: 'Typescript Poet',
    description: 'Typescript Poet',
    image: '/images/achievements/typescript_poet.png'
  }
}

type AchievementDetails = {
  title: string;
  description: string;
  image: string;
};

export type AchievementKey = keyof typeof achievementDetails;

interface AchievementProps {
  achievement: AchievementDetails;
}

const Achievement: React.FC<AchievementProps> = ({ achievement }) => (
  <Tooltip content={
    <div className="p-2 bg-white dark:bg-gray-800 text-gray-900 dark:text-gray-100">
      <h3 className="font-bold">{achievement.title}</h3>
      <p className="text-sm text-gray-700 dark:text-gray-300">{achievement.description}</p>
    </div>
  }>
    <div className="achievement-item bg-white dark:bg-gray-800 rounded-lg shadow-md p-4 transition-all duration-300 hover:shadow-lg hover:scale-105 border border-gray-200 dark:border-gray-700">
      <img
        src={achievement.image}
        alt={achievement.title}
        className="w-20 h-20 mx-auto mb-2 object-contain"
      />
      <h3 className="text-center text-sm font-semibold truncate text-gray-900 dark:text-gray-100">{achievement.title}</h3>
    </div>
  </Tooltip>
);

interface AchievementsProps {
  achievements: AchievementKey[];
}

const Achievements: React.FC<AchievementsProps> = ({ achievements }) => {
  const validAchievements = achievements.filter(key => achievementDetails[key]?.image);
  const invalidAchievements = achievements.filter(key => !achievementDetails[key]?.image);

  return (
    <div className="space-y-8">
      
      {achievements.length === 0 ? (
        <p className="text-gray-500 dark:text-gray-400 italic">You haven't earned any achievements yet. Keep learning!</p>
      ) : (
        <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
          {validAchievements.map(key => (
            <Achievement key={key} achievement={achievementDetails[key]} />
          ))}
        </div>
      )}
      
      {invalidAchievements.length > 0 && (
        <div className="mt-8 bg-gray-100 dark:bg-gray-800 rounded-lg p-4 border border-gray-200 dark:border-gray-700">
          <h3 className="text-lg font-semibold mb-2 text-gray-900 dark:text-gray-100">Unrecognized Achievements</h3>
          <ul className="list-disc list-inside">
            {invalidAchievements.map(key => (
              <li key={key} className="text-gray-600 dark:text-gray-400">
                {achievementDetails[key]?.title || key}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Achievements;
