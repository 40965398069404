import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import api from '../../services/api'

export const LOAD_LEADERBOARD_CODEWARS = 'leaderboard/loadCodewarsLeaderboard'
export const LOAD_LEADERBOARD_STACKOVERFLOW = 'leaderboard/loadStackoverflowLeaderboard'

interface LeaderboardResult {
  user_id: number,
  display_name: string,
  reputation: string,
  profile_image: string| null,
  link: string,
  isYou: boolean,
  posChange: boolean,
  posUp: boolean
}


interface LeaderboardState {
  stackoverflow: LeaderboardResult[]
  codewars: LeaderboardResult[]
  loading: boolean
  error: string | null
}

const initialState: LeaderboardState = {
  codewars: [],
  stackoverflow: [],
  loading: false,
  error: null
}



export const loadStackoverflowLeaderboard = createAsyncThunk<{data: { entries : LeaderboardResult[]}}, void, {state: RootState}>(
  LOAD_LEADERBOARD_STACKOVERFLOW,
  async () => {
    try {
      const response = await api.get<{data: { entries : LeaderboardResult[]}}>('/user/leaderboard/stackoverflow')
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }
)




export const loadCodewarsLeaderboard = createAsyncThunk<{data: { entries : LeaderboardResult[]}}, void, {state: RootState}>(
  LOAD_LEADERBOARD_CODEWARS,
  async () => {
    try {
      const response = await api.get<{data: { entries : LeaderboardResult[]}}>('/user/leaderboard/codewars')
      return response.data
    } catch (error) {
      console.error(error)
      throw error
    }
  }
)

const leaderboardSlice = createSlice({
  name: 'leaderboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loadCodewarsLeaderboard.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(loadCodewarsLeaderboard.fulfilled, (state, action: PayloadAction<{data: { entries : LeaderboardResult[]}} & {data: { entries : LeaderboardResult[]}}>) => {
        state.loading = false
        state.codewars = action.payload.data.entries
      })
      .addCase(loadCodewarsLeaderboard.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message || 'An error occurred'
      })

      .addCase(loadStackoverflowLeaderboard.pending, (state) => {
        state.loading = true
        state.error = null
      })
      .addCase(loadStackoverflowLeaderboard.fulfilled, (state, action: PayloadAction<{data: { entries : LeaderboardResult[]}}>) => {
        state.loading = false
        state.stackoverflow = action.payload.data.entries
      })
      .addCase(loadStackoverflowLeaderboard.rejected, (state, action) => {
        state.loading = false
        state.error = action.error.message || 'An error occurred'
      })
  }
})

export default leaderboardSlice.reducer
