import React from 'react'
import { NavLink, Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { useAppSelector } from '@data'
import { getLanguage } from '../../../../config/i18n'

const Header = () => {
  const name = useAppSelector((s) => s.authentication.user.name)
  const { t } = useTranslation()
  const links = [
    { to: `/${getLanguage()}/`, name: t('header.home') },
    { to: `/${getLanguage()}/courses`, name: t('header.courses') },
    { to: `/${getLanguage()}/login`, name: name || t('header.login') }
  ]

  return (
    <nav className="shadow fixed z-30 bg-gray-100 w-full">
      <div className="flex h-12 justify-between items-center px-4 md:px-8 container mx-auto">
        <Link to="/" className="font-mono text-lg text-teal-700 flex items-center">
          SkillCrucial<span className="pl-1">_</span>
        </Link>

        <ul className="flex">
          {links.map((link) => (
            <li key={link.name} className="mx-2 ">
              <NavLink className="no-underline py-1 text-teal-900 hover:text-teal-700" to={link.to}>
                {link.name}
              </NavLink>
            </li>
          ))}
        </ul>
      </div>
    </nav>
  )
}

export default Header
