import i18next from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import { enGB, ru, uk } from 'date-fns/locale'
import type { InitOptions } from 'i18next'
import { format } from 'date-fns'

const options = {
  // order and from where user language should be detected
  order:
    typeof window !== 'undefined'
      ? ['path', 'cookie', 'localStorage']
      : ['cookie', 'path', 'localStorage'],
  lookupCookie: 'i18next',
  lookupFromPathIndex: 0,
  convertLanguageOnRead: (lng: string) => lng.split('-')[0] // Convert language codes like en-GB to en
}

const locales = { en: enGB, ru, uk }

const version = typeof process.env.APP_VERSION !== 'undefined' ? process.env.APP_VERSION : +new Date()

const defaultParams: InitOptions = {
  parseMissingKeyHandler: (key: string) => key,
  detection: options,
  fallbackLng: {
    'uk-UA': ['uk'],
    'ru-RU': ['ru'],
    default: ['en']
  },
  supportedLngs: ['ru', 'en', 'uk'],
  load: 'languageOnly' as const, // This ensures only base language codes are used (en instead of en-GB)
  defaultNS: 'translation',
  ns: ['translation', 'courses'],
  fallbackNS: 'translation',
  debug: true, // Enable debug temporarily to see what's happening with translations
  interpolation: {
    escapeValue: false // not needed for react!!
  },
  react: {
    bindI18n: 'languageChanged',
    bindI18nStore: '',
    transEmptyNodeValue: '',
    transSupportBasicHtmlNodes: true,
    transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'],
    useSuspense: false
  }
}

const getClientBackend = (): InitOptions => ({
  backend: {
    loadPath: `/locales/{{lng}}/{{ns}}.json?v=${version}`,
    resGetPath: `/locales/{{lng}}/{{ns}}.json?v=${version}`
  },
  ...defaultParams
})

const getServerBackend = (): InitOptions => {
  return {
    ...defaultParams
  }
}

const p = i18next
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(typeof window !== 'undefined' ? getClientBackend() : getServerBackend())

export default i18next
export const promise = p

export function getLanguage() {
  const fromLocal = typeof localStorage !== 'undefined' && localStorage?.getItem('i18nextLng')
  let lang = 'en'

  if (i18next?.language && i18next.language !== '0') {
    lang = i18next.language
  } else if (fromLocal && fromLocal.length > 0 && fromLocal !== '0') {
    lang = fromLocal
  }

  const baseLanguage = lang.split('-')[0]
  return ['ru', 'en', 'uk'].includes(baseLanguage) ? baseLanguage : 'en'
}

export function formatDate(date: Date, formatStr = 'PP') {
  return format(date, formatStr, {
    locale: locales[getLanguage() as keyof typeof locales]
  })
}
