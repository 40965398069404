import React from 'react'

import Head from '../head'
import Header from './default/sections/header'
import Hero from './default/sections/hero'
import Announcement from './default/sections/announcement'
import Footer from './default/sections/footer'

const Landing = () => {
  return (
    <>
      <Head />
      <div className="landing">
        <Header />
        <Hero />
        <Announcement />
        <Footer />
      </div>
    </>
  )
}

export default Landing
