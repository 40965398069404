import React from 'react'
import ReactMarkdown from 'react-markdown'

import { sendJsTask, resetCode } from '@data/reducers/courses'
import { useAppDispatch } from '@data'
import Editor from '../homework/coding/editor'
import { HomeworkJsTasks } from '../../interfaces/mongoose.gen'

interface Props {
  code?: string
  task: HomeworkJsTasks
  messages: {line: string}[]
  result: {
    status: string
    message: string
    expected: string
    actual: string
    messages: {message: string}[]
  }
  index: number
  testDataId: string
  homeworkId: string
}

const SingleJSTask = ({ code, task, messages, result, index, testDataId, homeworkId }: Props) => {
  const dispatch = useAppDispatch()

  return (
    <div className="mt-5 mb-5" key={task.id}>
      <div>
        <h5 className="flex text-sm leading-6 font-medium ">
          {typeof result !== 'undefined' && result.status === 'ok' && (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" width="32" height="32">
              <path
                className="heroicon-ui fill-current text-teal-700"
                d="M11 20v-2.08a6 6 0 0 1-4.24-3A4.02 4.02 0 0 1 2 11V6c0-1.1.9-2 2-2h2c0-1.1.9-2 2-2h8a2 2 0 0 1 2 2h2a2 2 0 0 1 2 2v5a4 4 0 0 1-4.76 3.93A6 6 0 0 1 13 17.92V20h4a1 1 0 0 1 0 2H7a1 1 0 0 1 0-2h4zm6.92-7H18a2 2 0 0 0 2-2V6h-2v6c0 .34-.03.67-.08 1zM6.08 13A6.04 6.04 0 0 1 6 12V6H4v5a2 2 0 0 0 2.08 2zM8 4v8a4 4 0 1 0 8 0V4H8z"
              />
            </svg>
          )}
          <span>Task {index + 1}</span>
        </h5>
        <div className="prose-sm">
          <ReactMarkdown>
            {task.task.replace(/\n/g, '  \n').split('HOMEWORK_ID').join(homeworkId)}
          </ReactMarkdown>
        </div>
        {typeof task.testCases !== 'undefined' && (
          <div>
            {task.testCases.map((it3) => {
              return typeof it3.actions === 'undefined' ? (
                <div key={it3.arguments} className="row ml-0 mr-0 p-2 mb-4 mt-4 row-results">
                  <div className="col-6"> Input: {it3.arguments}</div>
                  <div className="col-6"> Output: {it3.result}</div>
                </div>
              ) : (
                <div className="row ml-0 mr-0 p-2 mb-4 mt-4 row-results">
                  <div className="col-4"> Input: {it3.arguments}</div>
                  <div className="col-4"> Actions: {it3.actions}</div>
                  <div className="col-4"> Output: {it3.result}</div>
                </div>
              )
            })}
          </div>
        )}
        {typeof result !== 'undefined' &&
          result.status === 'error' &&
          result.messages.map(({ message }) => {
            const [lines, ...messageBody] = message.split(' ')
            return (
              <div
                key={message}
                className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
                role="alert"
              >
                <strong className="font-bold">{lines} </strong>
                <span className="block sm:inline">{messageBody.join(' ')}</span>
              </div>
            )
          })}

        {typeof result !== 'undefined' && result.status === 'accepted' && (
          <div className="bg-orange-100 border border-orange-400 text-orange-700 px-4 py-3 rounded relative">
            <span className="block sm:inline">Task was accepted. Waiting for the result</span>
          </div>
        )}

        {typeof result !== 'undefined' &&
          result.status === 'error' &&
          typeof result.message !== 'undefined' &&
          result.message.indexOf('actual') > 0 && (
            <div className="fail-test-case">
              <div className="fail-header">Test Failed</div>
              <div className="error-actual">
                &nbsp;&nbsp;Test Result: &nbsp;
                {typeof result.actual !== 'undefined' ? JSON.stringify(result.actual) : 'undefined'}
              </div>
              <div className="error-expected">
                Expected Code: &nbsp;
                {typeof result.expected !== 'undefined'
                  ? JSON.stringify(result.expected).replace(/ /g, '\u00a0')
                  : 'undefined'}
              </div>
              <div className="error-expected">
                Message: &nbsp;
                {typeof result.message !== 'undefined'
                  ? JSON.stringify(result.message).replace(/ /g, '\u00a0')
                  : 'undefined'}
              </div>
            </div>
          )}
        {typeof result !== 'undefined' &&
          result.status === 'error' &&
          typeof result.message !== 'undefined' &&
          result.message.indexOf('actual') < 0 && (
            <div className="fail-test-case">
              <div className="fail-header">Test Failed</div>
              <div className="error-actual">{result.message.replace(/ /g, '\u00a0')}</div>
            </div>
          )}
      </div>

      <div>
        <Editor
          defaultCode={task?.code ?? ''}
          code={
            (sessionStorage && sessionStorage.getItem(`${testDataId} - ${task.id}`)) ||
            code ||
            task?.code ||
            ''
          }
          homeworkCode={code || ''}
          id={task.id!}
          onReset={() => {
            dispatch(resetCode({homeworkId : testDataId, taskId: task.id!, code: task.code}))
          }}
          onSubmit={(submittedCode) => {
            dispatch(sendJsTask(testDataId, task.id!, submittedCode))
            if (sessionStorage) {
              sessionStorage.setItem(`${testDataId} - ${task.id!}`, submittedCode)
            }
          }}
          // lines={(messages || []).map((obj) => obj.line)}
        />
      </div>
    </div>
  )
}

SingleJSTask.propTypes = {}

export default React.memo(SingleJSTask)
