import React, { useState } from 'react'
import axios from 'axios'
import { Link, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import classnames from 'classnames'

import courses from '../../../config/courses'
import { registrationSchema } from '../../../config/registrationSchema'
import type { User } from '../../../interfaces/mongoose.gen'

type keys = keyof typeof registrationSchema

const CourseEnroll = () => {
  const { key } = useParams()
  const { t } = useTranslation()

  const course = courses.find(({ id }) => key === id)
  const keys = Object.keys(registrationSchema) as keys[]
  const [user, setUser] = useState<Partial<User>>({})
  const [registered, setRegistered] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [validateFields, setValidations] = useState<Record<keys, string>>({})

  const register = async () => {
    try {
      setIsSubmitting(true)
      await axios.post('/api/v1/registration/user', {
        ...user,
        courseSlug: key
      })
      setRegistered(true)
    } catch (error) {
      console.error('Registration failed:', error)
    } finally {
      setIsSubmitting(false)
    }
  }

  const validate = async () => {
    const results = await Promise.all(
      keys.map(async (currentKey) => {
        const validator = registrationSchema[currentKey]?.validator
        if (validator) {
          const result = await validator(
            user[currentKey as keyof User] ?? '',
            user
          )
          if (result.status) {
            return ''
          }
          return result.message
        }
        return ''
      })
    )

    const newValidation = results.reduce<Record<keys, string>>((acc, message, index) => {
      if (message.length === 0) return acc
      return Object.assign(acc, { [keys[index]]: message })
    }, {} as Record<keys, string>)

    setValidations(newValidation)
    return !results.some(Boolean)
  }

  if (registered) {
    return (
      <div className="rounded-md bg-green-50 p-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <svg
              className="h-5 w-5 text-green-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              role="img"
              aria-labelledby="successIcon"
            >
              <title id="successIcon">Registration Success</title>
              <path
                fillRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                clipRule="evenodd"
              />
            </svg>
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-green-800">Registration successful</h3>
            <div className="mt-2 text-sm text-green-700">
              <p>Thank you for registering. Please check your email for further instructions.</p>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="space-y-6">
      {keys.map((it) => {
        const hasError = validateFields[it]
        const options = registrationSchema[it].options
        return (
          <div key={it} className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start">
            <label
              htmlFor={`${it}`}
              className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
            >
              {t(`registration.${it}`)}
              {hasError && (
                <p className="mt-1 text-sm text-red-600">{t(validateFields[it])}</p>
              )}
            </label>
            <div className="mt-1 sm:mt-0 sm:col-span-2">
              {options ? (
                <div className="flex space-x-4">
                  {options.map((option) => (
                    <button
                      key={option}
                      type="button"
                      className={classnames(
                        'inline-flex items-center px-4 py-2 border rounded-md text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500',
                        {
                          'border-teal-500 bg-teal-50 text-teal-700':
                            user[it as keyof User] === option,
                          'border-gray-300 bg-white text-gray-700 hover:bg-gray-50':
                            user[it as keyof User] !== option
                        }
                      )}
                      onClick={() => {
                        setUser((u) => ({ ...u, [it]: option }))
                      }}
                    >
                      {option}
                    </button>
                  ))}
                </div>
              ) : registrationSchema[it].large ? (
                <textarea
                  id={`${it}`}
                  name={`${it}`}
                  rows={3}
                  className={classnames(
                    'max-w-lg shadow-sm block w-full focus:ring-teal-500 focus:border-teal-500 sm:text-sm border rounded-md',
                    {
                      'border-red-300': hasError,
                      'border-gray-300': !hasError
                    }
                  )}
                  value={user[it as keyof User] ?? ''}
                  onChange={({ target: { value } }) => {
                    setUser((u) => ({ ...u, [it]: value }))
                  }}
                />
              ) : (
                <input
                  type={registrationSchema[it].type ?? 'text'}
                  name={`${it}`}
                  id={`${it}`}
                  className={classnames(
                    'max-w-lg block w-full shadow-sm focus:ring-teal-500 focus:border-teal-500 sm:max-w-xs sm:text-sm rounded-md',
                    {
                      'border-red-300': hasError,
                      'border-gray-300': !hasError
                    }
                  )}
                  value={user[it as keyof User] ?? ''}
                  onChange={({ target: { value } }) => {
                    setUser((u) => ({ ...u, [it]: value }))
                  }}
                />
              )}
            </div>
          </div>
        )
      })}

      <div className="pt-5 border-t border-gray-200">
        <div className="flex justify-between items-center">
          <p className="text-sm text-gray-500">
            By registering, you agree to our{' '}
            <Link className="font-medium text-teal-600 hover:text-teal-500" to="/terms-and-conditions">
              terms and conditions
            </Link>
          </p>
          <button
            type="button"
            disabled={isSubmitting}
            className={classnames(
              'inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500',
              {
                'opacity-50 cursor-not-allowed': isSubmitting
              }
            )}
            onClick={() => {
              validate().then((result) => {
                if (result) {
                  register()
                }
              })
            }}
          >
            {isSubmitting ? (
              <>
                <svg
                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  role="img"
                  aria-labelledby="loadingSpinner"
                >
                  <title id="loadingSpinner">Loading</title>
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  />
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  />
                </svg>
                Processing...
              </>
            ) : (
              'Register'
            )}
          </button>
        </div>
        {Object.keys(validateFields).length > 0 && (
          <p className="mt-2 text-sm text-red-600">{t('registration.errors')}</p>
        )}
      </div>
    </div>
  )
}

export default CourseEnroll
