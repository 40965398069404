import React from 'react'
// @ts-ignore
import { Helmet } from 'react-helmet'
import { useTranslation } from 'react-i18next'
import { getLanguage } from '../config/i18n'

// crete props interfacce
interface Props {
  title?: string
  keywords?: string
  description?: string
  subject?: string
  ogImage?: string
}

const Head = function (props: Props) {
  const { t } = useTranslation()

  return (
    <Helmet htmlAttributes={{ lang: getLanguage() }}>
      <title>{props.title || t('seo.title')}</title>
      <meta name="keywords" content={props.keywords || t('seo.keywords')} />
      <meta name="description" content={props.description || t('seo.description')} />
      <meta name="subject" content={props.subject || t('seo.subject')} />
      <meta name="language" content={getLanguage().toUpperCase()} />

      <meta property="og:title" content={props.title || t('seo.title')} />
      <meta property="og:image" content={props.ogImage} />
      <meta property="og:description" content={props.description || t('seo.description')} />
    </Helmet>
  )
}

export default React.memo(Head)
