import React, { useState, useEffect } from 'react'


export interface SelectOptionType { text: string| null ; value: string | null }
interface Props {
  options: SelectOptionType[];
  selected?: SelectOptionType;
  className?: string
  onChange?: (value: SelectOptionType) => void
}

const Dropdown = ({ options, selected: initial, className = '', onChange = () => {} }: Props) => {
  const [selected, select] = useState(initial)
  const [toggled, toggle] = useState(false)
  useEffect(() => {
    const foo = () => toggle(false)
    document.addEventListener('click', foo)
    return () => {
      document.removeEventListener('click', foo)
    }
  }, [toggle])
  return (
    <div className="relative inline-block text-left">
      <div>
        <button
          type="button"
          className={`inline-flex justify-between w-64 text-left border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-teal-700 ${className}`}
          id="options-menu"
          aria-haspopup="true"
          aria-expanded="true"
          onClick={(e) => {
            e.preventDefault()
            e.stopPropagation()
            e.nativeEvent.stopPropagation()
            toggle((t) => !t)
          }}
        >
          <div className="whitespace-nowrap w-50 overflow-hidden ">
            {selected ? selected?.text : ''}
          </div>
          <svg
            className="-mr-1 ml-2 h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
      {toggled && (
        <div className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5">
          <div
            className="py-1"
            role="menu"
            aria-orientation="vertical"
            aria-labelledby="options-menu"
          >
            {options.map(({text, value}) => (
              <button
                key={value}
                type="button"
                className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-900"
                role="menuitem"
                onClick={() => {
                  select({text, value})
                  onChange({text, value})
                  toggle((t) => !t)
                }}
              >
                {text ?? value}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  )
}

export default Dropdown
