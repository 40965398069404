import React from 'react'

import { useTranslation } from 'react-i18next'

import Head from '../head'
import Header from './default/sections/header'
import Footer from './default/sections/footer'

/* eslint max-len: ["error", { "code": 150 }] */

const questions = [
  {
    question: 'faq.question1',
    answer: 'faq.answer1'
  },
  {
    question: 'faq.question2',
    answer: 'faq.answer2'
  }
]

const Landing = () => {
  const { t } = useTranslation()

  return (
    <div className="default-landing">
      <Head
        title={t('faq.seo.title')}
        keywords={t('faq.seo.keywords')}
        description={t('faq.seo.description')}
        subject={t('faq.seo.subject')}
      />
      <div id="page-wrapper ">
        <Header />
        <div className="content ">
          <section className="full-section min-h-screen mb-0" style={{ minHeight: '900px' }}>
            <div className="px-10 flex align-center justify-center flex-col  md:px-2">
              <h1 className="mb-5 mp-5">{t('faq.title')}</h1>
              {questions.map((it) => (
                <div className="mb-5" key={it.question}>
                  <h2 className="text-sm">{t(it.question)}</h2>
                  <div>
                    <ul className="ml-3">
                      <li>
                        <p>{t(it.answer)}</p>
                      </li>
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
        <Footer />
      </div>
      <a id="go-top">
        <i className="miu-icon-circle_arrow-up_glyph" />
      </a>
    </div>
  )
}
Landing.propTypes = {}

export default Landing
