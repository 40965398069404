import React from 'react'
import '../../assets/scss/error-page.scss'

const ErrorPage = () => {
  return (
    <div className="landing">
      <section className="error-page flex items-center justify-center text-center min-h-screen">
        <div className="error-content">
          <div className="glitch-wrapper">
            <h1 className="glitch" data-text="Oops!">Oops!</h1>
          </div>
          <p className="lead text-2xl text-gray-800 mb-6 fade-in">
            Something went wrong
          </p>
          <p className="text-gray-600 mb-8 fade-in-delay">
            We're working on fixing the issue. Please try again later.
          </p>
          <a
            className="home-button bg-teal-600 hover:bg-teal-500 text-white font-bold py-3 px-8 rounded-lg transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg"
            href="/"
            tabIndex={0}
          >
            Back to Home
          </a>
        </div>
      </section>
    </div>
  )
}

export default ErrorPage
