import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'

interface Props {
  courseId: string
  close: () => void
}

const Modal = (props: Props) => {
  const { t } = useTranslation()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [motivation, setMotivation] = useState('')
  const [source, setSource] = useState('')
  const [error, setError] = useState('')
  const [status, setStatus] = useState('')

  const enroll = () => {
    axios
      .post('/api/v1/landing/enroll', {
        courseId: props.courseId,
        name,
        email,
        motivation,
        source
      })
      .then(({ data }) => {
        if (data.status !== 'error') {
          setStatus(data.status)
        }
      })
      .catch(({ response: { data } }) => {
        if (data.status === 'error') {
          setError(data.message)
        }
      })
  }

  const course = {
    id: `${props.courseId}`,
    name: t(`courses.${props.courseId}.name`),
    lang: t(`courses.${props.courseId}.language`),
    text: t(`courses.${props.courseId}.text`, ''),
    subject: t(`courses.${props.courseId}.emailSubject`, ''),
    message: t(`courses.${props.courseId}.message`),
    close: t(`courses.${props.courseId}.close`),
    alt: t(`courses.${props.courseId}.name`),
    details: t(`courses.${props.courseId}.details`)
  }

  const sources = ['twitter', 'facebook', 'instagram', 'google', 'ad', 'friend']
  return (
    <div>
      <div className="fixed z-50 top-1/2 left-1/2 w-4/5 sm:3/5 md:w-2/5 -translate-x-1/2 -translate-y-1/2 transform bg-gray-100 rounded border border-gray-300 shadow-lg p-4">
        <h2 className="whitespace-no-wrap ellipsis">{course.name}</h2>
        <div className="flex flex-col my-4">
          <label htmlFor="name">{t(`registration.name`)}</label>
          <input
            id="name"
            className="form-input"
            
            placeholder="Luke Skywalker"
            value={name}
            onChange={(e) => {
              setName(e.target.value)
            }}
          />
        </div>
        <div className="flex flex-col my-4">
          <label htmlFor="email">{t(`registration.email`)}</label>
          <input
            id="email"
            className="form-input"
            
            placeholder="luke@sky.walker"
            value={email}
            onChange={(e) => {
              setEmail(e.target.value)
            }}
          />
        </div>
        <div className="flex flex-col my-4">
          <label htmlFor="motivation">{t(`registration.motivation`)}</label>
          <textarea
            id="motivation"
            className="form-input"
            
            placeholder={t(`registration.motivation`)}
            value={motivation}
            onChange={(e) => {
              setMotivation(e.target.value)
            }}
          />
        </div>
        <div className="flex flex-col my-4">
          <p >{t(`registration.source-title`)}</p>
          <div className="space-y-1 text-gray-700 my-2">
            {sources.map((it) => {
              return (
                <div key={it} className="flex items-baseline space-x-2">
                  <input
                    type="radio"
                    id={it}
                    name="source"
                    value={it}
                    checked={it === source}
                    onChange={(e) => {
                      setSource(e.target.value)
                    }}
                  />
                  <label htmlFor={it}>{t(`registration.sources.${it}`)}</label>
                </div>
              )
            })}
          </div>
        </div>
        <div className="space-x-4">
          <button className="btn btn-primary" type="submit" onClick={enroll}>
            {t(`registration.button.register`)}
          </button>
          <button className="btn btn-secondary" type="button" onClick={() => props.close()}>
            {t(`registration.button.close`)}
          </button>
        </div>
        {status === 'ok' && (
          <div
            className="bg-green-100 my-2 border border-green-400 text-green-700 px-4 py-3 rounded relative"
            role="status"
          >
            <span className="block sm:inline">{t(`registration.enrolled`)}</span>
          </div>
        )}
        {status !== 'ok' && error.length !== 0 && (
          <div
            className="bg-red-100 my-2 border border-red-400 text-red-700 px-4 py-3 rounded relative"
            role="alert"
          >
            <span className="block sm:inline">{error}</span>
          </div>
        )}
      </div>

      <div className="inset-0 bg-gray-900 bg-opacity-50 fixed z-40" />
    </div>
  )
}


export default Modal
