import React from 'react'
import { useTranslation } from 'react-i18next'

const Announcement: React.FC = () => {
  const { t } = useTranslation()

  return (
    <section className="bg-blue-600 text-white py-12">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold mb-6">{t('statement.header')}</h2>
        <div className="space-y-4">
          <p>{t('statement.p1')}</p>
          <p>{t('statement.p2')}</p>
          <p>{t('statement.p3')}</p>
          <p>{t('statement.p4')}</p>
          <p>{t('statement.p5')}</p>
        </div>
      </div>
    </section>
  )
}

export default Announcement
