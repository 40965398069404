import React from 'react'
import ReactMarkdown from 'react-markdown'
import { Prism } from 'react-syntax-highlighter'
import type { SyntaxHighlighterProps } from 'react-syntax-highlighter'
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs'
import { Clipboard, Check } from 'lucide-react'
import type { ComponentPropsWithoutRef, HTMLAttributes, ReactNode } from 'react'
import { Editor } from '../common/text-editor/editor'
import type { Components } from 'react-markdown'

interface PreProps extends HTMLAttributes<HTMLPreElement> {
  children: ReactNode
}

interface CodeElementProps {
  children?: string
}

const lightStyle = {
  'pre[class*="language-"]': {
    background: 'rgb(250, 250, 250)',
    margin: 0,
    padding: '1rem',
    fontSize: '0.9rem',
    color: '#24292e',
    borderRadius: '0.5rem'
  },
  'code[class*="language-"]': {
    color: '#24292e',
    fontFamily: 'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, monospace'
  },
  'token.comment': {
    color: '#6a737d'
  },
  'token.keyword': {
    color: '#d73a49'
  },
  'token.string': {
    color: '#032f62'
  },
  'token.number': {
    color: '#005cc5'
  },
  'token.function': {
    color: '#6f42c1'
  },
  'token.operator': {
    color: '#d73a49'
  },
  'token.punctuation': {
    color: '#24292e'
  },
  'token.class-name': {
    color: '#6f42c1'
  },
  'token.builtin': {
    color: '#005cc5'
  },
  'token.property': {
    color: '#005cc5'
  },
  'token.parameter': {
    color: '#24292e'
  }
}

const Pre = ({ children, ...props }: PreProps) => {
  const [copied, setCopied] = React.useState(false)
  const textContent = React.Children.toArray(children)
    .map(child => {
      if (typeof child === 'string') return child
      if (React.isValidElement<CodeElementProps>(child)) {
        return child.props.children || ''
      }
      return ''
    })
    .join('')

  const handleCopy = () => {
    navigator.clipboard.writeText(textContent)
    setCopied(true)
    setTimeout(() => setCopied(false), 2000)
  }

  return (
    <div className="group/pre relative">
    <pre {...props} className="!pl-4">
      {children}
    </pre>
    <div className="absolute inset-0 bg-black opacity-0 group-hover/pre:opacity-30 transition-opacity duration-200 z-10 pointer-events-none" />
    <button
      type="button"
      onClick={handleCopy}
      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-4 rounded-lg bg-gray-100 dark:bg-gray-700 opacity-0 group-hover/pre:opacity-90 transition-opacity duration-200 hover:bg-gray-200 dark:hover:bg-gray-600 z-20"
      aria-label="Copy code"
    >
      <Clipboard size={24} className={copied ? "text-green-500" : "dark:text-gray-300 text-gray-600"} />
    </button>
  </div>
  )
}

interface CodeProps extends ComponentPropsWithoutRef<'code'> {
  inline?: boolean
}

const Code: React.FC<CodeProps> = ({ className, children, inline }) => {
  const match = /language-(\w+)/.exec(className || '')

  if (inline) {
    return (
      <code className="px-1.5 py-0.5 rounded bg-gray-100 dark:bg-transparent font-mono text-sm text-gray-800 dark:text-gray-200">
        {children}
      </code>
    )
  }

  if (!match) {
    return (
      <code className="px-1.5 py-0.5 rounded bg-gray-100 dark:bg-transparent font-mono text-sm text-gray-800 dark:text-gray-200">
        {children}
      </code>
    )
  }

  const content = String(children).replace(/\n$/, '')
  const PrismHighlighter = Prism as React.ComponentType<SyntaxHighlighterProps>

  return (
    <div className="rounded-lg overflow-hidden">
      <div className="dark:hidden">
        <div style={lightStyle['pre[class*="language-"]']}>
          <PrismHighlighter
            language={match[1]}
            style={docco}
            PreTag="div"
            className="!bg-transparent"
          >
            {content}
          </PrismHighlighter>
        </div>
      </div>
      <div className="hidden dark:block bg-gray-900 p-4 rounded-lg">
        <PrismHighlighter
          language={match[1]}
          style={{
            ...docco,
            'pre[class*="language-"]': {
              ...docco['pre[class*="language-"]'],
              background: 'transparent',
              color: '#e4e4e7'
            }
          }}
          PreTag="div"
          className="!bg-transparent"
        >
          {content}
        </PrismHighlighter>
      </div>
    </div>
  )
}

interface LessonContentProps {
  adminEditMode: boolean;
  description: string;
  onSetDescription: (description: string) => void;
}

const components: Partial<Components> = {
  code: Code as Components['code'],
  pre: Pre as Components['pre']
}

const LessonContent: React.FC<LessonContentProps> = ({ adminEditMode, description, onSetDescription }) => (
  <div className="mt-8">
    {adminEditMode ? (
      <Editor value={description} onChange={onSetDescription} />
    ) : (
      <div className="prose prose-lg max-w-none dark:prose-invert prose-headings:font-medium prose-a:text-blue-600 dark:prose-a:text-blue-400 prose-pre:p-0 prose-pre:m-0 prose-img:rounded-lg">
        <ReactMarkdown components={components}>
          {description}
        </ReactMarkdown>
      </div>
    )}
  </div>
)

export default React.memo(LessonContent)
