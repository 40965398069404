import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '@data';
import axios from 'axios';
import { toastr } from 'react-redux-toastr';
import { PaperClipIcon } from '@heroicons/react/20/solid';
import dayjs from 'dayjs';
import { countries, languages } from 'countries-list';
import timezones from 'timezones-list';
import { tryLogin } from '@data/reducers/authentication';
import TargetBox from '../common/target-box';
import { UserWorkProfile } from '../../interfaces/mongoose.gen';

type UserProfileField = keyof UserWorkProfile;
type UserProfileFieldArrayField = 'countriesToSearch' | 'countriesHasWorkPermit' | 'languages';

const JobSearch = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.authentication.user);
  const [workProfile, setWorkProfile] = useState<UserWorkProfile>(
    user.workProfile ?? {
      isSearching: false,
      isRemote: false,
      isRelocation: false,
      countriesToSearch: [],
      countriesHasWorkPermit: [],
      languages: []
    }
  );

  const handleProfileUpdate = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    axios
      .put('/api/v1/user/workProfile', workProfile)
      .then(() => {
        dispatch(tryLogin());
      })
      .then(() => {
        toastr.success('Congratulations', 'Profile has been updated');
      });
  };

  const handleFileDrop = (item: any) => {
    if (item) {
      const { files } = item;
      const tokens = files[0].name.split('.');
      if (tokens[tokens.length - 1] === 'pdf') {
        const formData = new FormData();
        formData.append('cv', files[0]);
        axios.post('/api/v1/user/cv', formData).then(() => {
          dispatch(tryLogin());
        });
      } else {
        toastr.error('error', 'Should be PDF!');
      }
    }
  };

  const handleFileRemove = (id: string) => () => {
    axios.delete(`/api/v1/user/cv/${id}`).then(() => {
      dispatch(tryLogin());
    });
  };

  const changeProfile = (field: UserProfileField, value: boolean | string) => {
    setWorkProfile({ ...workProfile, [field]: value });
  };

  const addProfileArrayField = (field: UserProfileFieldArrayField, value: string) => {
    setWorkProfile({ ...workProfile, [field]: [...(workProfile[field] ?? []), value] });
  };

  const removeProfileArrayField = (field: UserProfileFieldArrayField, value: string) => {
    setWorkProfile({ ...workProfile, [field]: workProfile[field].filter((it) => it !== value) });
  };

  return (
    <form className="space-y-8 divide-y divide-gray-200 dark:divide-gray-700 sm:px-6">
      <div className="divide-y divide-gray-200 dark:divide-gray-700 pt-2 space-y-6 sm:space-y-5">
        <div>
          <h3 className="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">Are you in the job search</h3>
          <p className="mt-1 max-w-2xl text-sm text-gray-500 dark:text-gray-400">
            This will allow us to promote you for a work
          </p>
        </div>
        <div className="space-y-6 sm:space-y-5 divide-y divide-gray-200 dark:divide-gray-700">
          <div className="pt-6 sm:pt-5">
            <div role="group" aria-labelledby="label-email">
              <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-baseline">
                <div>
                  <div
                    className="text-base font-medium text-gray-900 dark:text-gray-100 sm:text-sm"
                    id="label-email"
                  >
                    I am
                  </div>
                </div>
                <div className="mt-4 sm:mt-0 sm:col-span-2">
                  <div className="max-w-lg space-y-4">
                    <div className="relative flex items-start">
                      <div className="flex items-center h-5">
                        <input
                          id="job_search"
                          name="job_search"
                          type="checkbox"
                          checked={workProfile.isSearching}
                          onChange={(e) => changeProfile('isSearching', e.target.checked)}
                          className="focus:ring-teal-500 h-4 w-4 text-teal-600 border-gray-300 dark:border-gray-600 rounded dark:bg-gray-700"
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label htmlFor="job_search" className="font-medium text-gray-700 dark:text-gray-300">
                          In Job Search
                        </label>
                      </div>
                    </div>
                    <div>
                      <div className="relative flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id="remote"
                            name="remote"
                            type="checkbox"
                            checked={workProfile.isRemote}
                            onChange={(e) => changeProfile('isRemote', e.target.checked)}
                            className="focus:ring-teal-500 h-4 w-4 text-teal-600 border-gray-300 dark:border-gray-600 rounded dark:bg-gray-700"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label htmlFor="remote" className="font-medium text-gray-700 dark:text-gray-300">
                            Ready for remote
                          </label>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div className="relative flex items-start">
                        <div className="flex items-center h-5">
                          <input
                            id="relocation"
                            name="relocation"
                            type="checkbox"
                            checked={workProfile.isRelocation}
                            onChange={(e) => changeProfile('isRelocation', e.target.checked)}
                            className="focus:ring-teal-500 h-4 w-4 text-teal-600 border-gray-300 dark:border-gray-600 rounded dark:bg-gray-700"
                          />
                        </div>
                        <div className="ml-3 text-sm">
                          <label htmlFor="relocation" className="font-medium text-gray-700 dark:text-gray-300">
                            Ready for Relocation
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="space-y-8 divide-y divide-gray-200 dark:divide-gray-700 sm:space-y-5">
        {/* Countries to search work in */}
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 dark:border-gray-700 sm:pt-5">
          <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-300 sm:mt-px sm:pt-2">
            Country / Region you are searching work in
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <select
              id="country"
              name="country"
              autoComplete="country"
              onChange={(e) => addProfileArrayField('countriesToSearch', e.target.value)}
              className="max-w-lg block focus:ring-teal-500 focus:border-teal-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-300"
            >
              {Object.values(countries)
                .filter(({ name }) => (workProfile.countriesToSearch?.indexOf(name) ?? -1) < 0)
                .map(({ name }) => (
                  <option key={name}>{name}</option>
                ))}
            </select>
            {workProfile.countriesToSearch?.map((it) => (
              <button
                key={it}
                type="button"
                className="inline-flex mr-2 items-center px-2 py-0.5 rounded text-xs font-medium bg-teal-100 text-teal-800 dark:bg-teal-800 dark:text-teal-100"
                onClick={() => removeProfileArrayField('countriesToSearch', it)}
              >
                {it}
              </button>
            ))}
          </div>
        </div>

        {/* Countries with work permit */}
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 dark:border-gray-700 sm:pt-5">
          <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-300 sm:mt-px sm:pt-2">
            Country / Region you have permission to work
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <select
              id="country"
              name="country"
              autoComplete="country"
              onChange={(e) => addProfileArrayField('countriesHasWorkPermit', e.target.value)}
              className="max-w-lg block focus:ring-teal-500 focus:border-teal-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-300"
            >
              {Object.values(countries)
                .filter(({ name }) => (workProfile.countriesHasWorkPermit?.indexOf(name) ?? -1) < 0)
                .map(({ name }) => (
                  <option key={name}>{name}</option>
                ))}
            </select>
            {workProfile.countriesHasWorkPermit?.map((it) => (
              <button
                key={it}
                type="button"
                className="inline-flex mr-2 items-center px-2 py-0.5 rounded text-xs font-medium bg-teal-100 text-teal-800 dark:bg-teal-800 dark:text-teal-100"
                onClick={() => removeProfileArrayField('countriesHasWorkPermit', it)}
              >
                {it}
              </button>
            ))}
          </div>
        </div>

        {/* Current location */}
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 dark:border-gray-700 sm:pt-5">
          <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-300 sm:mt-px sm:pt-2">
            My current location
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <select
              id="country"
              name="country"
              autoComplete="country"
              value={workProfile.location}
              onChange={(e) => changeProfile('location', e.target.value)}
              className="max-w-lg block focus:ring-teal-500 focus:border-teal-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-300"
            >
              {Object.values(countries).map(({ name }) => (
                <option key={name}>{name}</option>
              ))}
            </select>
          </div>
        </div>

        {/* Current timezone */}
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 dark:border-gray-700 sm:pt-5">
          <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-300 sm:mt-px sm:pt-2">
            My current timezone
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <select
              id="country"
              name="country"
              autoComplete="country"
              value={workProfile.timezone}
              onChange={(e) => changeProfile('timezone', e.target.value)}
              className="max-w-lg block focus:ring-teal-500 focus:border-teal-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-300"
            >
              {timezones.map(({ name, utc }) => (
                <option key={name} value={utc}>
                  {name}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Languages */}
        <div className="sm:grid sm:grid-cols-3 sm:gap-4 sm:items-start sm:border-t sm:border-gray-200 dark:border-gray-700 sm:pt-5">
          <label htmlFor="country" className="block text-sm font-medium text-gray-700 dark:text-gray-300 sm:mt-px sm:pt-2">
            Languages
          </label>
          <div className="mt-1 sm:mt-0 sm:col-span-2">
            <select
              id="country"
              name="country"
              autoComplete="country"
              onChange={(e) => addProfileArrayField('languages', e.target.value)}
              className="max-w-lg block focus:ring-teal-500 focus:border-teal-500 w-full shadow-sm sm:max-w-xs sm:text-sm border-gray-300 dark:border-gray-600 rounded-md dark:bg-gray-700 dark:text-gray-300"
            >
              {Object.values(languages)
                .filter(({ name }) => (workProfile.languages?.indexOf(name) ?? -1) < 0)
                .map(({ name }) => (
                  <option key={name}>{name}</option>
                ))}
            </select>
            {workProfile.languages?.map((it) => (
              <button
                key={it}
                type="button"
                className="inline-flex mr-2 items-center px-2 py-0.5 rounded text-xs font-medium bg-teal-100 text-teal-800 dark:bg-teal-800 dark:text-teal-100"
                onClick={() => removeProfileArrayField('languages', it)}
              >
                {it}
              </button>
            ))}
          </div>
        </div>
      </div>
      <div className="bg-white dark:bg-gray-800 pt-5 border-b border-gray-200 dark:border-gray-700">
        <div className="-ml-2 -mt-2 flex flex-wrap items-baseline">
          <h3 className="ml-2 mt-2 text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">Your CV</h3>
        </div>
      </div>
      <div className="sm:col-span-2">
        <div className="mt-1 text-sm text-gray-900 dark:text-gray-100">
          <ul className="border border-gray-200 dark:border-gray-700 rounded-md divide-y divide-gray-200 dark:divide-gray-700">
            {(user?.attachments ?? []).map((attachment) => {
              const fileName = (attachment.link?? '').split('/')
              const file = fileName[fileName.length - 1]
              return (
                <li
                  key={attachment._id}
                  className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                >
                  <div className="w-0 flex-1 flex items-center">
                    <PaperClipIcon
                      className="flex-shrink-0 h-5 w-5 text-gray-400 dark:text-gray-500"
                      aria-hidden="true"
                    />
                    <span className="ml-2 flex-1 w-0 truncate">
                      {file} [{attachment.createdAt && dayjs(new Date(attachment.createdAt)).format('DD/MM/YYYY	hh:mma')}]
                    </span>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    <a
                      href={attachment.link}
                      className="font-medium text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300"
                    >
                      Download
                    </a>
                    <button
                      type="button"
                      onClick={handleFileRemove(attachment._id!)}
                      className="font-medium ml-4 text-blue-600 hover:text-blue-500 dark:text-blue-400 dark:hover:text-blue-300"
                    >
                      Remove
                    </button>
                  </div>
                </li>
              )
            })}
          </ul>

          {(user?.attachments ?? []).length === 0 && (
            <span className="text-sm font-medium text-gray-600 dark:text-gray-400 mt-10">No attachments</span>
          )}
          <TargetBox onDrop={handleFileDrop} />
        </div>
      </div>
      <div className="pt-5">
        <div className="flex justify-end">
          <button
            type="submit"
            onClick={handleProfileUpdate}
            className="ml-3 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-teal-600 hover:bg-teal-700 dark:bg-teal-500 dark:hover:bg-teal-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500 dark:focus:ring-offset-gray-800"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  )
}

export default JobSearch
